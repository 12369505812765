import { Route } from 'react-router-dom';
import RoutePaths from '@root/pages/routes';
import Switch from '@root/components/RouterDom/Switch';
import ProductsHome from './Home';
import ProductComponents from './Components';

export const Products = () => {
  return (
    <Switch>
      <Route path={RoutePaths.products.home.root} component={ProductsHome} />
      <Route path={RoutePaths.products.components.root} component={ProductComponents} />
    </Switch>
  );
};
