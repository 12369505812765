import Switch from '@root/components/RouterDom/Switch';
import RoutePaths from '@root/pages/routes';
import { Route } from 'react-router-dom';
import UpsertProduct from './Upsert';
import ProductsList from './List';
import ProductDetail from './View';

const ProductsHome = () => {
  return (
    <Switch>
      <Route path={RoutePaths.products.home.list} component={ProductsList} exact />
      <Route path={RoutePaths.products.home.create} component={UpsertProduct} />
      <Route path={RoutePaths.products.home[':id'].root} component={ProductDetail} exact />
      <Route path={RoutePaths.products.home[':id'].edit} component={UpsertProduct} />
    </Switch>
  );
};

export default ProductsHome;
