import { CompactCheckbox } from '@root/components/Checkbox/CompactCheckbox';
import DetailMoney from '@root/components/Detail/Money';
import DetailText from '@root/components/Detail/Text';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { Link } from '@root/components/Link';
import { REACT_APP_API_URL } from '@root/constants/env';
import RoutePaths from '@root/pages/routes';
import { BillingCodeNode } from '@root/typings/generated';

interface Props {
  billingCode: BillingCodeNode;
}

export const BasicDetails = ({ billingCode }: Props) => {
  return (
    <>
      <FormFieldGrid label="Compact ID:">
        <DetailText>
          {billingCode.compactId}{' '}
          <a href={`${REACT_APP_API_URL}/admin/pc_trade/billingcode/${billingCode.realId}/change/`}>
            admin
          </a>
        </DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Project:">
        {billingCode.internalProject ? (
          <Link
            label={billingCode.internalProject.name}
            route={{
              id: billingCode.internalProject.id,
              basePath: RoutePaths.projects.home[':id'].root,
            }}
          />
        ) : (
          <DetailText>None</DetailText>
        )}
      </FormFieldGrid>
      <FormFieldGrid label="Product Objective:">
        {billingCode.productObjective?.longName || billingCode.productObjective?.name}
      </FormFieldGrid>
      <FormFieldGrid label="Total Spent:">
        <DetailMoney data={billingCode.totalSpent} />
      </FormFieldGrid>
      <FormFieldGrid label="Inactive:">
        <CompactCheckbox checked={billingCode.inactive} disabled />
      </FormFieldGrid>
    </>
  );
};
