import { useFormikContext } from 'formik';
import DetailText from '@root/components/Detail/Text';
import { FormikCheckbox } from '@root/components/Form/Formik/FormikCheckBox';
import { FormikTextField } from '@root/components/Form/Formik/FormikTextField';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { ComponentNode } from '@root/typings/generated';

const BasicDetails = () => {
  const { values } = useFormikContext<ComponentNode>();
  return (
    <>
      <FormFieldGrid label="Compact ID:">
        <DetailText>{values.compactId ?? '-'}</DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Name:">
        <FormikTextField name="name" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Repository URL:">
        <FormikTextField name="repository.url" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Inactive:">
        <FormikCheckbox name="inactive" />
      </FormFieldGrid>
    </>
  );
};

export default BasicDetails;
