import Switch from '@root/components/RouterDom/Switch';
import RoutePaths from '@root/pages/routes';
import { Route } from 'react-router-dom';

import ComponentsList from './List';
import UpsertComponent from './Upsert';
import ComponentDetail from './View';

const ProductComponents = () => {
  return (
    <Switch>
      <Route path={RoutePaths.products.components.list} component={ComponentsList} exact />
      <Route path={RoutePaths.products.components.create} component={UpsertComponent} />
      <Route path={RoutePaths.products.components[':id'].root} component={ComponentDetail} exact />
      <Route path={RoutePaths.products.components[':id'].edit} component={UpsertComponent} />
    </Switch>
  );
};

export default ProductComponents;
