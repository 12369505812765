import FormSectionAccordion from '@root/components/Accordion/FormSectionAccordion';
import generateUpsertFormWidget from '@root/components/Form/Widget/upsert-form';
import RoutePaths from '@root/pages/routes';
import {
  ProductNode,
  UpsertProductDocument,
  UpsertProductMutation,
  UpsertProductMutationVariables,
  useGetProductQuery,
} from '@root/typings/generated';

import BasicDetails from './BasicDetails';
import ComponentsTable from '../View/ComponentsTable';
import { initialValues, validationSchema } from './_type';

const UpsertProductFormWidget = generateUpsertFormWidget<
  ProductNode,
  UpsertProductMutationVariables,
  UpsertProductMutation
>({
  name: 'Product',
  redirect: RoutePaths.products.home.list,
  viewUrl: RoutePaths.products.home[':id'],
  initialValues,
  validationSchema,
  mutationDoc: UpsertProductDocument,
  getDataQuery: useGetProductQuery,
});

const UpsertProduct = () => {
  return (
    <UpsertProductFormWidget
      mapping={({ id, name, description, owner, inactive }) => ({
        data: {
          instance: id,
          name,
          inactive,
          description,
          client: owner?.id ?? '',
        },
      })}
      basicDetail={<BasicDetails />}
    >
      <FormSectionAccordion title="Components">
        <ComponentsTable />
      </FormSectionAccordion>
    </UpsertProductFormWidget>
  );
};

export default UpsertProduct;
