import { FormikTextField } from '@root/components/Form/Formik/FormikTextField';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';

const BasicDetails = () => {
  return (
    <>
      <FormFieldGrid label="Name:">
        <FormikTextField name="name" size="small" />
      </FormFieldGrid>
    </>
  );
};

export default BasicDetails;
