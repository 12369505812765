import { useState } from 'react';

export const ROWS_PER_PAGE_OPTIONS = [10, 20, 50, 100];

export const usePagination = (defaultOrderBy = '', defaultPageOption = 2) => {
  const [limit, setLimit] = useState(ROWS_PER_PAGE_OPTIONS[defaultPageOption]);
  const [page, setPage] = useState(1);
  const [orderBy, setOrder] = useState(defaultOrderBy);
  const [search, setSearch] = useState<string[]>([]);
  const orderDir: Order = orderBy?.startsWith('-') ? 'desc' : 'asc';
  const orderKey = orderBy?.startsWith('-') ? orderBy?.slice(1) : orderBy;
  const changeOrder = (key: string) => {
    const isDesc = orderKey === key && orderDir === 'asc';
    setOrder(`${isDesc ? '-' : ''}${key}`);
  };

  return {
    limit,
    setLimit,
    page,
    setPage,
    orderBy,
    setOrder,
    search,
    setSearch,
    orderDir,
    orderKey,
    changeOrder,
  };
};
