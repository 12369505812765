import { FormikTextField } from '@root/components/Form/Formik/FormikTextField';
import { ProfitCentreAsyncSelect } from '@root/components/Form/FormikSelect';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { ProfitCentreLayer, UserNode } from '@root/typings/generated';
import { useFormikContext } from 'formik';

export const BasicDetails = () => {
  const { values } = useFormikContext<UserNode>();
  return (
    <>
      <FormFieldGrid label="Name:">
        <FormikTextField name="name" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Username:">
        <FormikTextField name="username" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Email:">
        <FormikTextField name="email" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Salary Hours/Fortnight:">
        <FormikTextField name="salaryHoursPerFortnight" type="number" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Hub:">
        <ProfitCentreAsyncSelect
          name="hub"
          variables={{ types: [ProfitCentreLayer.Hub] }}
          goToId={values.hub?.id}
        />
      </FormFieldGrid>
    </>
  );
};
