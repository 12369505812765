import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Button, Grid, Stack } from '@mui/material';
import { EditIcon, TrashIcon } from '@root/components/Icons';
import RoutePaths from '@root/pages/routes';
import { usePath } from '@root/providers/AuthProvider/modules/path';
import { useBlockStyles } from '@root/styles/block';
import { ContractNode } from '@root/typings/generated';
import { FieldArray, useFormikContext } from 'formik';
import { routesWithValue } from '../../../../../../utils/route';
import { ContractRateCard } from '../../View/ContractRateCard';
import UpsertContractRateCard from './UpsertContractRateCard';
const ContractRateCards = () => {
  const { values, submitForm } = useFormikContext<ContractNode>();
  const classes = useBlockStyles();
  const { pushPath } = usePath();

  return (
    <>
      <FieldArray
        name="contractRateCards"
        render={(helpers) => (
          <>
            {values.contractRateCards.map(
              (contractRateCard, index) =>
                !!contractRateCard.id && (
                  <Grid item key={index} className={classes.block}>
                    <ContractRateCard
                      contractRateCard={contractRateCard}
                      editComponent={<UpsertContractRateCard contractRateCard={contractRateCard} />}
                    />
                    <Stack pb={2} pr={2} justifyContent="flex-end" direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={() =>
                          pushPath(
                            routesWithValue(
                              ':id',
                              contractRateCard.rateCard.id,
                              RoutePaths.contracts.rate_cards[':id'],
                            ).edit,
                          )
                        }
                      >
                        Edit Rate Card
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        startIcon={<TrashIcon />}
                        onClick={() => {
                          helpers.remove(index);
                          submitForm();
                        }}
                      >
                        Remove from Project
                      </Button>
                    </Stack>
                  </Grid>
                ),
            )}

            <Stack mt={2} justifyContent="center" direction="row" spacing={2}>
              <UpsertContractRateCard />
              <Button
                variant="contained"
                startIcon={<InsertDriveFileOutlinedIcon />}
                onClick={() =>
                  pushPath(RoutePaths.contracts.rate_cards.create, null, { contract: values })
                }
              >
                Add New Rate Card
              </Button>
            </Stack>
          </>
        )}
      />
    </>
  );
};

export default ContractRateCards;
