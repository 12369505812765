/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation } from 'react-router-dom';

export const getDomain = (location: string) => {
  const urlParts = location.split('.');

  if (urlParts.length > 2) {
    return [urlParts[urlParts.length - 2], urlParts[urlParts.length - 1]].join('.');
  }

  return location;
};
export function publicAsset(path: string) {
  return `${process.env.PUBLIC_URL}${path}`;
}

export function createRoutes<T extends Routes>(
  routes: T,
  namespace?: string,
): ExtendRecursively<T, { root: string }> {
  const childRoutes = Object.entries(routes).reduce((acc, [key, val]) => {
    if (typeof val === 'string') {
      const prependedVal = namespace ? `/${namespace}${val}` : val;
      acc[key] = prependedVal;
      return acc;
    }

    const prependedKey = namespace ? `${namespace}/${key}` : key;
    acc[key] = createRoutes(val, prependedKey);

    return acc;
  }, {} as any);

  childRoutes.root = namespace ? `/${namespace}` : '/';

  return childRoutes;
}

export function routesWithValue<T>(param: string, value: string, routes: T): T {
  return Object.entries(routes).reduce((acc, [key, route]) => {
    if (typeof route === 'string') {
      const withParam = route.replace(param, value);
      acc[key] = withParam;
      return acc;
    }

    acc[key] = routesWithValue(param, value, route);
    return acc;
  }, {} as any);
}

export function useQueryParams() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const result: Record<string, string | undefined> = {};

  for (const [key, val] of params.entries()) {
    result[key] = val;
  }

  return result;
}
