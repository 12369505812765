import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  Button,
  FormControl,
} from '@mui/material';
import { ClientSelect } from '@root/components/Form/Select/ClientSelect';
import { InnerLoading } from '@root/components/Loading/InnerLoading';
import { usePagination } from '@root/hooks/use-pagination';
import {
  InternalProjectNode,
  ClientNode,
  ProjectStatusEnum,
  useListProjectsQuery,
} from '@root/typings/generated';
import { ChangeEvent, useState } from 'react';
import { ProjectStatus } from '../../../../../components/Status/ProjectStatus';
import { Header } from '@root/components';
import TagsInput from '@root/components/Form/Base/TagsInput';
import RoutePaths from '@root/pages/routes';
import { useInputStyles } from '@root/styles/form/input';
import { useHistory } from 'react-router-dom';
import { Table } from '@root/components/Table/Table';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from '@root/components/Link/Link';

const projectListTableColumns: GridColDef<InternalProjectNode>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 3,
    renderCell: (params: GridRenderCellParams) => (
      <Link
        label={params.row.name ?? `Project - ${params.row.id}`}
        route={{
          id: params.row.id,
          basePath: RoutePaths.projects.home[':id'].root,
        }}
      />
    ),
  },
  {
    field: 'contract',
    headerName: 'Contract',
    flex: 2,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => params.value.name,
  },
  {
    field: 'contractDirector',
    headerName: 'Contract Director',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => params.row.contract.accountManager?.name ?? '-',
  },
  {
    field: 'product',
    headerName: 'Product',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => params.value.name,
  },
  {
    field: 'manager',
    headerName: 'Job Manager',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => params.value?.name ?? '-',
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => <ProjectStatus status={params.value} />,
  },
];

const ProjectsList = () => {
  const classes = useInputStyles();
  const { limit, page, search, setSearch, setPage, orderBy, setLimit, setOrder } =
    usePagination('name');
  const orderByKey = {
    name: 'name',
    '-name': '-name',
    contract: 'contract__name',
    '-contract': '-contract__name',
    contractDirector: 'contract__account_manager__name',
    '-contractDirector': '-contract__account_manager__name',
    product: 'product__name',
    '-product': '-product__name',
    manager: 'manager__name',
    '-manager': '-manager__name',
  }[orderBy];
  const [clients, setClients] = useState<ClientNode[]>();
  const history = useHistory();

  const [statuses, setStatuses] = useState<{ [key in ProjectStatusEnum]?: boolean }>({
    [ProjectStatusEnum.Upcoming]: false,
    [ProjectStatusEnum.InProgress]: true,
    [ProjectStatusEnum.Overdue]: false,
    [ProjectStatusEnum.Completed]: false,
  });
  const [result] = useListProjectsQuery({
    variables: {
      offset: limit * (page - 1),
      first: limit,
      search,
      orderBy: orderByKey,
      statuses: Object.keys(statuses).filter((key) => statuses[key as ProjectStatusEnum]),
      clientIds: clients?.map(({ id }) => id) ?? [],
    },
  });
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatuses({
      ...statuses,
      [event.target.name]: event.target.checked,
    });
  };

  const { fetching, data, error } = result;
  const rows = data?.projects?.edges?.map((data) => data?.node) ?? [];

  return (
    <Box>
      <Grid container alignItems="flex-end" spacing={2} direction="column">
        <Grid item width="100%">
          <Header
            title="Projects"
            left={
              <FormControl variant="standard" fullWidth>
                <TagsInput
                  fullWidth
                  placeholder="Search"
                  className={classes.searchInput}
                  name="search-rate-cards"
                  value={search}
                  onChange={setSearch}
                />
              </FormControl>
            }
            right={
              <Button
                variant="outlined"
                fullWidth
                onClick={() => history.push(RoutePaths.projects.home.create)}
                data-testid="add-project-button"
              >
                Add Project
              </Button>
            }
          />
        </Grid>
        <Grid item width="100%">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h6">Select Client(s): </Typography>
            <Box width="100%" maxWidth="600px">
              <ClientSelect
                multiple
                value={clients}
                onChange={(_, v) => setClients(v)}
                limitTags={3}
                disableCloseOnSelect
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item width="100%">
          <Stack direction="row">
            {Object.keys(statuses).map((status) => {
              return (
                <FormControlLabel
                  key={status}
                  label={<ProjectStatus status={status as ProjectStatusEnum} />}
                  color="red"
                  control={
                    <Checkbox
                      onChange={handleChange}
                      checked={statuses[status as ProjectStatusEnum]}
                      name={status}
                    />
                  }
                />
              );
            })}
          </Stack>
        </Grid>
        {rows.length === 0 ? (
          <Grid item width="100%">
            <Box position="relative" marginTop={1}>
              {fetching ? (
                <InnerLoading loading={true} opacity={0} />
              ) : (
                <div>No projects found</div>
              )}
            </Box>
          </Grid>
        ) : (
          <Grid item width="100%">
            <Box position="relative">
              {error ? (
                <div>{JSON.stringify(error)}</div>
              ) : (
                <Table
                  rows={rows}
                  isLoading={fetching}
                  columns={projectListTableColumns}
                  paginationControls={{
                    count: data?.projects?.pageCursors?.totalPages ?? 1,
                    page: page - 1,
                    onPageChange: (val: number) => setPage(val + 1),
                    onRowsPerPageChange: (val: number) => setLimit(val),
                    rowsPerPage: limit,
                  }}
                  searchControls={{ onSortChange: setOrder }}
                />
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ProjectsList;
