import { Chip, Table, TableBody, TableCell } from '@mui/material';
import { Link } from '@root/components/Link';
import { TableHeader } from '@root/components/Table/TableHeader';
import { TableRow } from '@root/components/Table/TableRow';
import { TableContainer } from '@root/components/Table/TableContainer';
import { usePagination } from '@root/hooks/use-pagination';
import { InternalProjectNode, ContractNode, useListProjectsQuery } from '@root/typings/generated';
import RoutePaths from '@root/pages/routes';

interface ProjectTableProps {
  contract: ContractNode;
}

export const ProjectTable = (props: ProjectTableProps) => {
  const { contract } = props;
  const { limit, page, setPage, orderBy, changeOrder, orderDir, orderKey } = usePagination();

  const [result] = useListProjectsQuery({
    variables: {
      offset: limit * (page - 1),
      first: limit,
      contractId: contract.id,
      orderBy,
    },
  });

  const { data } = result;
  const rows = (data?.projects?.edges?.map((edge) => edge?.node) ?? []) as InternalProjectNode[];

  return (
    <TableContainer
      emptyLabel="projects"
      result={result}
      pageCursors={data?.projects?.pageCursors}
      page={page}
      setPage={setPage}
    >
      <Table size="small">
        <TableHeader
          columns={[
            { id: 'name', label: 'Name' },
            { id: 'product', label: 'Product', sortable: false },
          ]}
          orderKey={orderKey}
          orderDir={orderDir}
          changeOrder={changeOrder}
        />
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell>
                <Link
                  isExternal
                  label={row.name ?? `Project - ${row.id}`}
                  route={{
                    id: row.id,
                    basePath: RoutePaths.projects.home[':id'].root,
                  }}
                />{' '}
                <Chip label={row?.status} color="primary" size="small" />
              </TableCell>
              <TableCell>{row.product?.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
