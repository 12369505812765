import { Box, Grid, Button, FormControl } from '@mui/material';
import { InnerLoading } from '@root/components/Loading/InnerLoading';
import { usePagination } from '@root/hooks/use-pagination';
import { BillingCodeNode, useListBillingCodesQuery } from '@root/typings/generated';
import { Header } from '@root/components';
import TagsInput from '@root/components/Form/Base/TagsInput';
import { useInputStyles } from '@root/styles/form/input';
import { useHistory } from 'react-router-dom';
import RoutePaths from '@root/pages/routes';
import { routesWithValue } from '@root/utils/route';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from '@root/components/Link';
import { Table } from '@root/components/Table/Table';
import Money from '@root/components/Detail/Money';

const billingCodesListTableColumns: GridColDef<BillingCodeNode>[] = [
  {
    field: 'product_objective__long_name',
    headerName: 'Product Objective',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Link
        label={params.row.tagLabel ?? ''}
        route={routesWithValue(':id', params.row.id, RoutePaths.projects.billing_codes[':id']).root}
      />
    ),
  },
  {
    field: 'internalProject',
    headerName: 'Internal Project',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => params.value.name,
  },
  {
    field: 'totalSpent',
    headerName: 'Total Spent',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => <Money data={params?.value} />,
  },
];

const ProjectsBillingCodes = () => {
  const classes = useInputStyles();
  const history = useHistory();
  const { limit, page, search, setSearch, setPage, orderBy, setOrder, setLimit } =
    usePagination('id');
  const [result] = useListBillingCodesQuery({
    variables: {
      offset: limit * (page - 1),
      first: limit,
      search,
      orderBy,
    },
  });

  const { fetching, data, error } = result;
  const rows = data?.billingCodes?.edges?.map((data) => data?.node) ?? [];

  return (
    <Box>
      <Grid container alignItems="flex-end" spacing={2} direction="column">
        <Grid item width="100%">
          <Header
            left={
              <FormControl variant="standard" fullWidth>
                <TagsInput
                  fullWidth
                  placeholder="Search"
                  className={classes.searchInput}
                  name="search-billing-codes"
                  value={search}
                  onChange={setSearch}
                />
              </FormControl>
            }
            title="Billing Codes"
            right={
              <Button
                variant="outlined"
                fullWidth
                onClick={() => history.push(RoutePaths.projects.billing_codes.create)}
                data-testid="add-billing-code-button"
              >
                Add Billing Code
              </Button>
            }
          />
        </Grid>
        <Grid item width="100%">
          {rows.length == 0 && !error ? (
            <Box position="relative" marginTop={1}>
              {fetching ? (
                <InnerLoading loading={true} opacity={0} />
              ) : (
                <div>No billing codes found</div>
              )}
            </Box>
          ) : (
            <Box position="relative">
              {error ? (
                <div>{JSON.stringify(error)}</div>
              ) : (
                <Table
                  rows={rows}
                  isLoading={fetching}
                  columns={billingCodesListTableColumns}
                  paginationControls={{
                    count: data?.billingCodes?.pageCursors?.totalPages ?? 1,
                    page: page - 1,
                    onPageChange: (val: number) => setPage(val + 1),
                    onRowsPerPageChange: (val: number) => setLimit(val),
                    rowsPerPage: limit,
                  }}
                  searchControls={{ onSortChange: setOrder }}
                />
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectsBillingCodes;
