import {
  Box,
  Checkbox,
  Chip,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { ClientSelect } from '@root/components/Form/Select/ClientSelect';
import { InnerLoading } from '@root/components/Loading/InnerLoading';
import { getContractStatusColor } from '@root/constants/contract-status-to-color';
import { usePagination } from '@root/hooks/use-pagination';
import { ClientNode, ContractNode, useListContractsQuery } from '@root/typings/generated';
import { ChangeEvent, useState } from 'react';
import { Header } from '@root/components';
import TagsInput from '@root/components/Form/Base/TagsInput';
import RoutePaths from '@root/pages/routes';
import { useInputStyles } from '@root/styles/form/input';
import { useHistory } from 'react-router-dom';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { routesWithValue } from '@root/utils/route';
import { Link } from '@root/components/Link';
import { Table } from '@root/components/Table/Table';
import { ContractStatus } from '@root/components/Status/ContractStatus';

const contractsListTableColumns: GridColDef<ContractNode>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Link
        label={params.value}
        route={routesWithValue(':id', params.row.id, RoutePaths.contracts.home[':id']).root}
      />
    ),
  },
  {
    field: 'client',
    headerName: 'Client',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => params.value.name,
  },
  {
    field: 'entity',
    headerName: 'Entity',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => params.value.name,
  },
  {
    field: 'accountManager',
    headerName: 'Account Manager',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => params.value.name,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => <ContractStatus status={params.value ?? '-'} />,
  },
];

const ContractsList = () => {
  const classes = useInputStyles();
  const history = useHistory();
  const { limit, page, search, setSearch, setPage, orderBy, setLimit, setOrder } =
    usePagination('name');
  const [statuses, setStatuses] = useState({
    'Not Started': false,
    Active: false,
    Completed: false,
  }) as IgnoredAny;
  const filterKeys = Object.keys(statuses);
  const [clients, setClients] = useState<ClientNode[]>();

  const [result] = useListContractsQuery({
    variables: {
      offset: limit * (page - 1),
      first: limit,
      search,
      orderBy,
      clientIds: clients?.map(({ id }) => id) ?? [],
      statuses: filterKeys.filter((status) => statuses[status]),
    },
  });

  const { fetching, data, error } = result;
  const rows = data?.contracts?.edges?.map((data) => data?.node) ?? [];
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatuses({
      ...statuses,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Box>
      <Grid container alignItems="flex-end" spacing={2} direction="column">
        <Grid item width="100%">
          <Header
            title="Contracts"
            left={
              <FormControl variant="standard" fullWidth>
                <TagsInput
                  fullWidth
                  placeholder="Search"
                  className={classes.searchInput}
                  name="search-contract"
                  value={search}
                  onChange={setSearch}
                />
              </FormControl>
            }
            right={
              <Button
                variant="outlined"
                fullWidth
                onClick={() => history.push(RoutePaths.contracts.home.create)}
                data-testid={'add-contract-button'}
              >
                Add Contract
              </Button>
            }
          />
        </Grid>
        <Grid item width="100%">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h6">Select Client(s): </Typography>
            <Box width="100%" maxWidth="600px">
              <ClientSelect
                multiple
                value={clients}
                onChange={(_, v) => setClients(v)}
                limitTags={3}
                disableCloseOnSelect
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item width="100%">
          <Stack direction="row">
            {filterKeys.map((keyStr) => {
              const color = getContractStatusColor(keyStr);
              return (
                <FormControlLabel
                  key={keyStr}
                  label={
                    <Chip
                      label={keyStr}
                      color="primary"
                      size="small"
                      sx={{ backgroundColor: color }}
                    />
                  }
                  color="red"
                  control={
                    <Checkbox
                      onChange={handleChange}
                      checked={statuses[keyStr]}
                      name={keyStr}
                      sx={{
                        color: color,
                        '&.Mui-checked': {
                          color: color,
                        },
                      }}
                    />
                  }
                />
              );
            })}
          </Stack>
        </Grid>
        {rows.length === 0 ? (
          <Grid item width="100%">
            <Box position="relative" height="calc(100% - 100px)" marginTop={1}>
              {fetching ? (
                <InnerLoading loading={true} opacity={0} />
              ) : (
                <div>No contracts found</div>
              )}
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item width="100%">
              <Box position="relative">
                {error ? (
                  <div>{JSON.stringify(error)}</div>
                ) : (
                  <Table
                    rows={rows}
                    isLoading={fetching}
                    columns={contractsListTableColumns}
                    paginationControls={{
                      count: data?.contracts?.pageCursors?.totalPages ?? 1,
                      page: page - 1,
                      onPageChange: (val: number) => setPage(val + 1),
                      onRowsPerPageChange: (val: number) => setLimit(val),
                      rowsPerPage: limit,
                    }}
                    searchControls={{ onSortChange: setOrder }}
                  />
                )}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ContractsList;
