import { Table, TableBody, TableCell, TableContainer } from '@mui/material';
import DetailText from '@root/components/Detail/Text';
import { Link } from '@root/components/Link';
import { TableHeader } from '@root/components/Table/TableHeader';
import { TableRow } from '@root/components/Table/TableRow';
import RoutePaths from '@root/pages/routes';
import { RoleNode } from '@root/typings/generated';
import { routesWithValue } from '@root/utils/route';

const People = ({ data }: { data: RoleNode }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHeader
          columns={[
            {
              label: 'Person',
              sortable: false,
            },
            {
              label: 'Hub',
              sortable: false,
            },
            {
              label: 'Region',
              sortable: false,
            },
          ]}
        />
        <TableBody>
          {data.people.map((person) => [
            <TableRow key={person.id}>
              <TableCell>
                <Link
                  label={person.name ?? person.firstName}
                  route={routesWithValue(':id', person.id, RoutePaths.people[':id']).root}
                />
              </TableCell>
              <TableCell>
                {person.hub ? (
                  <Link
                    label={person.hub.name}
                    route={
                      routesWithValue(
                        ':id',
                        person.hub.id,
                        RoutePaths.contracts.profit_centres[':id'],
                      ).root
                    }
                  />
                ) : (
                  <DetailText>None</DetailText>
                )}
              </TableCell>
              <TableCell>
                {person?.hub?.region ? (
                  <Link
                    label={person.hub.region.name}
                    route={
                      routesWithValue(
                        ':id',
                        person.hub.region.id,
                        RoutePaths.contracts.profit_centres[':id'],
                      ).root
                    }
                  />
                ) : (
                  <DetailText>None</DetailText>
                )}
              </TableCell>
            </TableRow>,
          ])}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default People;
