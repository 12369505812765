import Switch from '@root/components/RouterDom/Switch';
import RoutePaths from '@root/pages/routes';
import { Route } from 'react-router-dom';
import BillingCodesList from './List';
import UpsertBillingCode from './Upsert';
import BillingCodeView from './View';

const BillingCodes = () => {
  return (
    <Switch>
      <Route path={RoutePaths.projects.billing_codes.list} component={BillingCodesList} exact />
      <Route path={RoutePaths.projects.billing_codes.create} component={UpsertBillingCode} />
      <Route
        path={RoutePaths.projects.billing_codes[':id'].root}
        component={BillingCodeView}
        exact
      />
      <Route path={RoutePaths.projects.billing_codes[':id'].edit} component={UpsertBillingCode} />
    </Switch>
  );
};

export default BillingCodes;
