import TreeItem from '@mui/lab/TreeItem';
import { Grid } from '@mui/material';
import { HubNode, Maybe, ProfitCentreLayer } from '@root/typings/generated';
import ActionItem from './ActionItem';

interface PropsType {
  hub?: Maybe<HubNode>;
}

const HubTree = (props: PropsType) => {
  const { hub } = props;
  return (
    <>
      <TreeItem
        nodeId={hub?.id ?? 'hub'}
        label={
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item>{hub?.name}</Grid>
            <Grid item>
              <ActionItem id={hub?.id ?? ''} layer={ProfitCentreLayer.Hub} />
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default HubTree;
