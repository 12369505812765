import Switch from '@root/components/RouterDom/Switch';
import RoutePaths from '@root/pages/routes';
import { Route } from 'react-router-dom';
import ContractsProfitCentres from './ProfitCentres';
import ContractsHome from './Home';
import ContractClients from './Clients';
import ContractsRoles from './Roles';
import ContractsRateCards from './RateCards';

export const Contracts = () => {
  return (
    <Switch>
      <Route path={RoutePaths.contracts.home.root} component={ContractsHome} />
      <Route path={RoutePaths.contracts.profit_centres.root} component={ContractsProfitCentres} />
      <Route path={RoutePaths.contracts.clients.root} component={ContractClients} />
      <Route path={RoutePaths.contracts.rate_cards.root} component={ContractsRateCards} />
      <Route path={RoutePaths.contracts.roles.root} component={ContractsRoles} />
    </Switch>
  );
};
