import { Grid } from '@mui/material';
import DetailMoney from '@root/components/Detail/Money';
import DetailText from '@root/components/Detail/Text';
import generateDetailViewWidget from '@root/components/Form/Widget/detail-view';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { Link } from '@root/components/Link';
import RoutePaths from '@root/pages/routes';
import { RateCardNode, RateCardRoleNode, useGetRateCardQuery } from '@root/typings/generated';
import { capitalizeFirstLetter } from '@root/utils/capitalize-first-letter';
import { SPANS } from '../Upsert/form';
import { routesWithValue } from '@root/utils/route';

interface DetailProps {
  data: RateCardNode;
}
const Detail: React.FC<DetailProps> = ({ data }: DetailProps) => {
  return (
    <>
      <FormFieldGrid label="Title:">
        <DetailText>{data.title}</DetailText>
      </FormFieldGrid>
      <Grid item>
        <DetailText variant="h6">Rate Card Roles</DetailText>
        <Grid container spacing={2}>
          <Grid item {...SPANS.Role}>
            Role
          </Grid>
          <Grid item {...SPANS.Unit}>
            Unit
          </Grid>
          <Grid item {...SPANS.Price}>
            Price
          </Grid>
        </Grid>
      </Grid>
      {data.rateCardRoles?.map((rateCardRole: RateCardRoleNode, index: number) => (
        <Grid item container key={index} spacing={0.0} mt={0}>
          <Grid item {...SPANS.Role}>
            <Link
              label={rateCardRole.role.name}
              route={routesWithValue(':id', rateCardRole.role.id, RoutePaths.people[':id']).root}
            />
          </Grid>
          <Grid item {...SPANS.Unit}>
            <DetailText>{capitalizeFirstLetter(rateCardRole.unit?.toString())}</DetailText>
          </Grid>
          <Grid item {...SPANS.Price}>
            <DetailMoney data={rateCardRole.price} />
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default generateDetailViewWidget<RateCardNode>({
  name: 'Rate Card',
  redirect: RoutePaths.contracts.rate_cards.list,
  editUrl: RoutePaths.contracts.rate_cards[':id'],
  getDataQuery: useGetRateCardQuery,
  component: Detail,
});
