import { FormControlLabel, ListItem, ListItemText, Stack } from '@mui/material';
import FormikBudgetInput from '@root/components/Form/Formik/FormikBudgetInput';
import { FormikCheckbox } from '@root/components/Form/Formik/FormikCheckBox';
import { FormikDatePicker } from '@root/components/Form/Formik/FormikDatePicker';
import { FormikTextField } from '@root/components/Form/Formik/FormikTextField';
import {
  ContractAsyncSelect,
  ProductAsyncSelect,
  UserAsyncSelect,
} from '@root/components/Form/FormikSelect';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { InternalProjectNode } from '@root/typings/generated';
import { useFormikContext } from 'formik';

const BasicDetails = () => {
  const { values } = useFormikContext<InternalProjectNode>();

  return (
    <>
      <FormFieldGrid label="Name:">
        <FormikTextField name="name" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Description:">
        <FormikTextField name="description" size="small" multiline />
      </FormFieldGrid>
      <FormFieldGrid label="Contract:">
        <ContractAsyncSelect
          name="contract"
          variables={{
            clientId: values.product?.owner?.id ?? '',
          }}
          goToId={values.contract?.id}
        />
      </FormFieldGrid>
      <FormFieldGrid label="Product:">
        <ProductAsyncSelect
          name="product"
          renderOption={(props, option) => (
            <ListItem {...props} key={option.id}>
              <ListItemText primary={option?.name ?? ''} />
            </ListItem>
          )}
          variables={{
            clientId: values.contract?.client?.id ?? '',
          }}
          goToId={values.product?.id}
        />
      </FormFieldGrid>
      <FormFieldGrid label="Manager:">
        <UserAsyncSelect name="manager" hideAddBtn goToId={values.manager?.id} />
      </FormFieldGrid>
      <FormFieldGrid label="Budget (inc gst):">
        <Stack direction="row" spacing={1} alignItems="flex-start">
          <FormikBudgetInput name="budget" size="small" />
          <FormControlLabel
            control={<FormikCheckbox name="fixedPrice" applyCustomStyles={false} />}
            label="Fixed"
          />
        </Stack>
      </FormFieldGrid>
      <FormFieldGrid label="Start Date:">
        <FormikDatePicker name="startDate" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="End Date:">
        <FormikDatePicker name="endDate" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Account Manager Approved:">
        <FormikCheckbox name="accountManagerApproved" />
      </FormFieldGrid>
      <FormFieldGrid label="Work at Risk:">
        <FormikCheckbox name="workAtRisk" />
      </FormFieldGrid>
      <FormFieldGrid label="Overburn:">
        <FormikCheckbox name="overburn" />
      </FormFieldGrid>
      <FormFieldGrid label="Completed:">
        <FormikCheckbox name="completed" />
      </FormFieldGrid>
    </>
  );
};

export default BasicDetails;
