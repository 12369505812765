import { Grid, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { CurrencyCodes } from '@root/constants/currencies';
import { MoneyInput } from '@root/typings/generated';
import _ from 'lodash';
import React from 'react';

export interface BudgetInputProps extends FormBaseProps {
  value?: MoneyInput;
  onChange?: (
    event: SelectChangeEvent<string> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    v: MoneyInput,
  ) => void;
}
export default function BudgetInput(props: BudgetInputProps) {
  const { value, onChange, size, error } = props;

  const debounceOnchange = _.debounce(function (
    event: SelectChangeEvent<string> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    v: MoneyInput,
  ) {
    onChange && onChange(event, v);
  },
  1);
  return (
    <Grid item container width={'100%'}>
      <Grid item xs={8}>
        <TextField
          value={value?.amount}
          type="number"
          error={error}
          size={size}
          fullWidth
          onChange={(v) =>
            debounceOnchange(v, {
              currency: value?.currency ?? CurrencyCodes.AUD,
              amount: v.currentTarget.value ?? value?.amount,
            })
          }
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          value={value?.currency}
          fullWidth
          size={size}
          error={false}
          onChange={(v) =>
            debounceOnchange(v, {
              currency: v.target.value ?? value?.currency,
              amount: value?.amount ?? '',
            })
          }
        >
          {Object.entries(CurrencyCodes).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}
