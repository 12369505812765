import { Box, CircularProgress, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/system';

export interface InnerLoadingProps {
  loading: boolean;
  opacity?: number;
}

const useStyles = makeStyles<Theme, InnerLoadingProps>((theme: Theme) => ({
  background: {
    backgroundColor: (props) =>
      alpha(theme.localComponents.InnerLoading.backgroundColor ?? grey[500], props.opacity ?? 0.2),
  },
}));

export const InnerLoading = (props: InnerLoadingProps) => {
  const classes = useStyles(props);
  if (!props.loading) return <></>;
  return (
    <Box
      className={classes.background}
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        padding: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      data-testid="inner-loading-indicator"
    >
      <CircularProgress />
    </Box>
  );
};
