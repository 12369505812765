/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomFormContext } from '@root/components/Form/Formik/CustomFormProvider';
import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useAuthContext } from '../context';

export interface UsePathState {
  saveBack: boolean;
}

export function usePath() {
  const { paths, setPaths, pathContext, setPathContext } = useAuthContext();
  const location = useLocation<UsePathState>();
  const history = useHistory();
  const { formName } = useContext(CustomFormContext);

  const pushPath = (path: string, context: any = null, nextContext: any = null) => {
    setPaths([{ path: location.pathname, pathName: formName }, ...paths]);
    context && setPathContext({ ...pathContext, [location.pathname]: context });
    nextContext && setPathContext({ ...pathContext, [path]: nextContext });
    history.push({ pathname: path, state: { saveBack: true } });
  };
  useEffect(() => {
    if (!location.state?.saveBack) {
      setPaths([]);
      setPathContext({});
    }
    const samePathIndex = paths.findIndex((info) => info.path == location.pathname);
    const remainPaths = paths.slice().splice(samePathIndex + 1);
    if (samePathIndex >= 0) setPaths(remainPaths);
  }, []);
  const getContext = (context: any = null, need = true) => {
    if (location.state?.saveBack && need)
      return { ...context, ...pathContext?.[location.pathname] };
    return context;
  };
  const popPath = (redirect?: string) => {
    const [path, ...rest] = paths;
    setPaths([...rest]);
    const redirectPath = path?.path || redirect;
    redirectPath && history.push({ pathname: path?.path || redirect, state: { saveBack: !!path } });
    return redirectPath;
  };
  const gotoPath = (pathname: string) => {
    history.replace({ pathname: pathname, state: { saveBack: true } });
  };
  const redirectPath = (path: string) => history.push(path);
  return { paths, setPaths, pushPath, popPath, getContext, redirectPath, gotoPath };
}
