/* eslint-disable @typescript-eslint/no-explicit-any */
import { OpenInNew } from '@mui/icons-material';
import { Chip, IconButton, Stack } from '@mui/material';
import RoutePaths from '@root/pages/routes';
import { ProfitCentreLayer } from '@root/typings/generated';
import { routesWithValue } from '@root/utils/route';
import { NavLink } from 'react-router-dom';
interface PropsType {
  id?: string;
  layer: string;
  showViewBtn?: boolean;
}

const Layer2Color: {
  [key: string]: 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'default';
} = {
  [ProfitCentreLayer.Entity.toUpperCase()]: 'primary',
  [ProfitCentreLayer.Region.toUpperCase()]: 'secondary',
  [ProfitCentreLayer.Hub.toUpperCase()]: 'warning',
};

export const ActionItem = (props: PropsType) => {
  const { id, layer, showViewBtn } = props;
  const upLayer = layer.toUpperCase();
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Chip label={upLayer} size="small" color={Layer2Color?.[upLayer] ?? 'primary'} />
      {showViewBtn !== false && (
        <IconButton
          component={NavLink}
          size="small"
          to={routesWithValue(':id', id ?? '', RoutePaths.contracts.profit_centres[':id']).root}
          onClick={(e: any) => e.stopPropagation()}
        >
          <OpenInNew />
        </IconButton>
      )}
    </Stack>
  );
};

export default ActionItem;
