import { FC } from 'react';
import { useField } from './hooks';
import AsyncAutocomplete, { AsyncAutocompleteProps } from '../Base/AsyncAutocomplete';
import _ from 'lodash';
export type FormikAsyncAutocompleteProps = AsyncAutocompleteProps & FormikBaseProps;

const FormikAsyncAutocomplete: FC<FormikAsyncAutocompleteProps> = (
  props: FormikAsyncAutocompleteProps,
) => {
  const { name, label, helperText, onChange, ...rest } = props;
  const { field, setValue } = useField(name, helperText);
  const setValueWithCheck = (e: React.SyntheticEvent<Element, Event>, v: unknown) => {
    if (!_.isEqual(field.value, v)) {
      setValue(v);
      setTimeout(() => onChange && onChange(e, v), 100);
    }
  };
  return (
    <AsyncAutocomplete id={name} label={label} {...rest} {...field} onChange={setValueWithCheck} />
  );
};

export default FormikAsyncAutocomplete;
