import Switch from '@root/components/RouterDom/Switch';
import RoutePaths from '@root/pages/routes';
import { Route } from 'react-router-dom';
import UpsertRole from './Upsert';
import ContractsRolesList from './List';
import ViewRole from './View';

const ContractsRoles = () => {
  return (
    <Switch>
      <Route path={RoutePaths.contracts.roles.list} component={ContractsRolesList} exact />
      <Route path={RoutePaths.contracts.roles.create} component={UpsertRole} />
      <Route path={RoutePaths.contracts.roles[':id'].root} component={ViewRole} exact />
      <Route path={RoutePaths.contracts.roles[':id'].edit} component={UpsertRole} />
    </Switch>
  );
};

export default ContractsRoles;
