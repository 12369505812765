import * as _ from 'lodash';
import { Grid } from '@mui/material';
import { InnerLoading } from '@root/components/Loading/InnerLoading';
import { EntityNode, useListEntitiesQuery } from '@root/typings/generated';
import EntityItem from './EntityItem';

const EntityList = () => {
  const [result] = useListEntitiesQuery({});
  const { fetching, data } = result;
  const entities = data?.entities ?? [];

  return (
    <Grid item width="100%" px={4}>
      {_.sortBy(entities, ['compactId']).map((entity) => (
        <EntityItem key={entity.id} entity={entity as EntityNode} />
      ))}
      <InnerLoading loading={fetching} />
    </Grid>
  );
};

export default EntityList;
