import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { BoxProps, Box } from '@mui/material';
import { ROWS_PER_PAGE_OPTIONS } from '@root/hooks/use-pagination';

interface TableProps extends Pick<DataGridProps, 'rows' | 'columns'> {
  isLoading?: boolean;
  error?: string;
  noRowsLabel?: string;
  containerProps?: BoxProps;
  paginationControls: {
    count: number;
    page: number;
    onPageChange: (page: number) => void;
    onRowsPerPageChange: (rowsPerPage: number) => void;
    rowsPerPage: number;
  };
  searchControls: {
    onSortChange: (sort: string) => void;
  };
}

/**
 * Table component based on mui DataGrid
 * @param {TableProps} props
 * @property {any[]} rows rows of data being rendered
 * @property {GridColumns<any>[]} columns the columns being rendered
 * @property {boolean} [isLoading] is the data for the table currently loading
 * @property {string} [error] error message when loading the data
 * @property {string} [noRowsLabel] message when there was no data
 * @property {BoxProps} [containerProps] prop pass through for the box table container
 * @typedef {Object} paginationControls object to pass all the pagination controls
 * @property {number} count the total count of rows
 * @property {number} page the current page (using array indexing)
 * @property {(page: number) => void} onPageChange call back triggered when the page is changed
 * @property {(rows: number) => void} onRowsPerPageChange callback when the rows per page is changed
 * @property {number} rowsPerPage the current rows per page
 * @typedef {Object} searchControls
 * @property {(sort: string) => void} onSortChange callback when the sort filter is changed on col
 * @returns
 */
export const Table = ({
  rows,
  columns,
  containerProps,
  paginationControls,
  searchControls,
}: TableProps) => {
  return (
    <Box sx={{ height: 600, width: '100%' }} {...containerProps}>
      <DataGrid
        autoHeight
        columns={columns}
        rows={rows}
        disableColumnFilter
        onSortModelChange={(v) =>
          v[0]
            ? searchControls.onSortChange(`${v[0].sort === 'desc' ? '-' : ''}${v[0].field}`)
            : searchControls.onSortChange('')
        }
        componentsProps={{
          pagination: {
            rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
            onRowsPerPageChange: (
              input: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) =>
              !isNaN(Number(input.target.value)) &&
              paginationControls.onRowsPerPageChange(Number(input.target.value)),
            count: paginationControls.count ?? 1,
            page: paginationControls.page,
            onPageChange: (
              _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
              page: number,
            ) => paginationControls.onPageChange(page),
            rowsPerPage: paginationControls.rowsPerPage,
          },
        }}
      />
    </Box>
  );
};
