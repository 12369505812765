import { Box, Switch, Grid, Stack, Typography, Button, FormControl } from '@mui/material';
import { CompactCheckbox } from '@root/components/Checkbox/CompactCheckbox';
import { ProfitCentreSelect } from '@root/components/Form/Select/ProfitCentreSelect';
import { InnerLoading } from '@root/components/Loading/InnerLoading';
import { usePagination } from '@root/hooks/use-pagination';
import {
  ProfitCentreLayer,
  ProfitCentreNode,
  useListUsersQuery,
  UserNode,
} from '@root/typings/generated';
import { useState } from 'react';
import { Header } from '@root/components';
import TagsInput from '@root/components/Form/Base/TagsInput';
import RoutePaths from '@root/pages/routes';
import { useInputStyles } from '@root/styles/form/input';
import { useHistory } from 'react-router-dom';
import { Table } from '@root/components/Table/Table';
import { Link } from '@root/components/Link';
import { routesWithValue } from '@root/utils/route';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

const peopleListTableColumns: GridColDef<UserNode>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Link
        isExternal
        label={params.value}
        route={routesWithValue(':id', params.row.id, RoutePaths.people[':id']).root}
      />
    ),
  },
  { field: 'username', headerName: 'Username', minWidth: 300, flex: 1, sortable: false },
  {
    field: 'inactive',
    headerName: 'Is Inactive',
    minWidth: 100,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return <CompactCheckbox checked={params.value} disabled />;
    },
  },
  {
    field: 'hub',
    headerName: 'Hub',
    minWidth: 300,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => params.value.name,
  },
];

const PersonList = () => {
  const classes = useInputStyles();
  const history = useHistory();
  const { limit, setLimit, page, search, setSearch, setPage, orderBy, setOrder } =
    usePagination('name');
  const [hubs, setHubs] = useState<ProfitCentreNode[]>();
  const [includeInactive, setIncludeInactive] = useState<boolean>(false);
  const [result] = useListUsersQuery({
    variables: {
      offset: limit * (page - 1),
      first: limit,
      search,
      orderBy,
      hubIds: hubs?.map(({ id }) => id) ?? [],
      includeInactive: includeInactive,
    },
  });

  const { fetching, data, error } = result;
  const rows = data?.users?.edges?.map((data) => data?.node) ?? [];
  const [variables] = useState({ types: [ProfitCentreLayer.Hub] });
  return (
    <Box>
      <Grid container alignItems="flex-end" spacing={2} direction="column">
        <Grid item width="100%">
          <Header
            left={
              <FormControl variant="standard" fullWidth>
                <TagsInput
                  fullWidth
                  placeholder="Search"
                  className={classes.searchInput}
                  name="search-rate-cards"
                  value={search}
                  onChange={setSearch}
                />
              </FormControl>
            }
            title="People"
            right={
              <Button
                variant="outlined"
                fullWidth
                onClick={() => history.push(RoutePaths.people.create)}
                data-testid="add-person"
              >
                Add People
              </Button>
            }
          />
        </Grid>
        <Grid item width="100%">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h6">Select Hub(s): </Typography>
            <Box width="100%" maxWidth="600px">
              <ProfitCentreSelect
                multiple
                value={hubs}
                onChange={(_, v) => setHubs(v)}
                limitTags={3}
                disableCloseOnSelect
                variables={variables}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item width="100%">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h6">Include Inactive: </Typography>
            <Switch
              onChange={(_, v) => setIncludeInactive(v)}
              checked={includeInactive}
              name="includeInactive"
            />
          </Stack>
        </Grid>

        {rows.length === 0 ? (
          <Grid item width="100%">
            <Box position="relative" marginTop={1}>
              {fetching ? <InnerLoading loading={true} opacity={0} /> : <div>No users found</div>}
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item width="100%">
              <Box position="relative">
                {error ? (
                  <div>{JSON.stringify(error)}</div>
                ) : (
                  <Table
                    rows={rows}
                    isLoading={fetching}
                    columns={peopleListTableColumns}
                    paginationControls={{
                      count: data?.users?.pageCursors?.totalRecords ?? 1,
                      page: page - 1,
                      onPageChange: (val: number) => setPage(val + 1),
                      onRowsPerPageChange: (val: number) => setLimit(val),
                      rowsPerPage: limit,
                    }}
                    searchControls={{ onSortChange: setOrder }}
                  />
                )}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default PersonList;
