import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack } from '@mui/material';
import { useFormContext } from '@root/components/Form/Formik/CustomFormProvider';

const BaseFormBtns = () => {
  const { actionName } = useFormContext();
  return (
    <Grid item width="100%" textAlign="center" mt={2}>
      <Stack width="100%" spacing={3} direction="row" justifyContent="flex-end">
        <LoadingButton type="submit" variant="contained" sx={{ px: 4 }} startIcon={<SaveIcon />}>
          {actionName}
        </LoadingButton>
      </Stack>
    </Grid>
  );
};

export default BaseFormBtns;
