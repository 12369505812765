import DetailDate from '@root/components/Detail/Date';
import DetailText from '@root/components/Detail/Text';
import FormikBudgetInput from '@root/components/Form/Formik/FormikBudgetInput';
import { FormikDatePicker } from '@root/components/Form/Formik/FormikDatePicker';
import { FormikTextField } from '@root/components/Form/Formik/FormikTextField';
import {
  ClientAsyncSelect,
  ProfitCentreAsyncSelect,
  UserAsyncSelect,
} from '@root/components/Form/FormikSelect';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { ContractNode, ProfitCentreLayer } from '@root/typings/generated';
import { useFormikContext } from 'formik';

const BasicDetails = () => {
  const { values } = useFormikContext<ContractNode>();
  return (
    <>
      <FormFieldGrid label="Client:">
        <ClientAsyncSelect name="client" goToId={values.client?.id} />
      </FormFieldGrid>
      <FormFieldGrid label="Entity:">
        <ProfitCentreAsyncSelect
          name="entity"
          variables={{ types: [ProfitCentreLayer.Entity] }}
          goToId={values.entity?.id}
        />
      </FormFieldGrid>
      <FormFieldGrid label="Name:">
        <FormikTextField name="name" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Description:">
        <FormikTextField name="description" multiline minRows={3} size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Account Manager:">
        <UserAsyncSelect name="accountManager" hideAddBtn goToId={values.accountManager?.id} />
      </FormFieldGrid>
      <FormFieldGrid label="Document URL:">
        <FormikTextField name="documentUrl" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Budget (inc gst):">
        <FormikBudgetInput name="budget" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Created:">
        <DetailDate date={values.created} />
      </FormFieldGrid>
      <FormFieldGrid label="Submitted:">
        <FormikDatePicker name="submitted" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Signed:">
        <FormikDatePicker name="signed" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Valid Until:">
        <FormikDatePicker
          name="validUntil"
          size="small"
          props={{
            shouldDisableDate: (day) => day <= new Date(values.signed),
          }}
        />
      </FormFieldGrid>
      <FormFieldGrid label="Completed:">
        <FormikDatePicker name="completed" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Status:">
        <DetailText>{values.status ?? '-'}</DetailText>
      </FormFieldGrid>
    </>
  );
};

export default BasicDetails;
