import { PropsWithChild } from '@root/typings/child-type';
import React, { useState } from 'react';
import LayoutContext from './Context';

const LayoutContextProvider = ({ children }: PropsWithChild) => {
  const [isOpenDrawer, setOpenDrawer] = useState(false);

  return (
    <LayoutContext.Provider
      value={{
        isOpenDrawer,
        setOpenDrawer,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContextProvider;
