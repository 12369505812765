import React, { useContext } from 'react';

export interface LayoutContextProps {
  isOpenDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const LayoutContext = React.createContext<LayoutContextProps>({} as LayoutContextProps);

export function useLayoutContext() {
  const { isOpenDrawer, setOpenDrawer } = useContext(LayoutContext);
  const toggleDrawer = () => setOpenDrawer(!isOpenDrawer);
  return { toggleDrawer, isOpenDrawer, setOpenDrawer };
}

export default LayoutContext;
