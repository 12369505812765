import {
  ListProfitCentresQueryVariables,
  useListProfitCentresQuery,
} from '@root/typings/generated';
import { AsyncAutocomplete } from '../Base';
import { AsyncSelectWithVar } from './_type';

export const ProfitCentreSelect = (props: AsyncSelectWithVar<ListProfitCentresQueryVariables>) => {
  return (
    <AsyncAutocomplete
      queryKey="profitCentres"
      getOptionLabel={(option) => option?.name ?? ''}
      useQuery={useListProfitCentresQuery}
      size={'small'}
      orderBy={'name'}
      {...props}
    />
  );
};
