import { Button, FormControl, Box, Grid } from '@mui/material';
import { InnerLoading } from '@root/components/Loading/InnerLoading';
import { usePagination } from '@root/hooks/use-pagination';
import { RoleNode, useListRolesQuery } from '@root/typings/generated';
import { Header } from '@root/components';
import TagsInput from '@root/components/Form/Base/TagsInput';
import RoutePaths from '@root/pages/routes';
import { useInputStyles } from '@root/styles/form/input';
import { useHistory } from 'react-router-dom';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from '@root/components/Link';
import { routesWithValue } from '@root/utils/route';
import { Table } from '@root/components/Table/Table';

const rolesListTableColumns: GridColDef<RoleNode>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Link
        label={params.value}
        route={routesWithValue(':id', params.row.id, RoutePaths.contracts.roles[':id']).root}
      />
    ),
  },
];

const ContractsRolesList = () => {
  const classes = useInputStyles();
  const history = useHistory();
  const { limit, page, search, setSearch, setPage, orderBy, setOrder, setLimit } =
    usePagination('name');
  const [result] = useListRolesQuery({
    variables: {
      offset: limit * (page - 1),
      first: limit,
      search,
      orderBy,
    },
  });
  const { fetching, data, error } = result;
  const rows = data?.roles?.edges?.map((data) => data?.node) ?? [];

  return (
    <Box>
      <Grid container alignItems="flex-end" spacing={2} direction="column">
        <Grid item width="100%">
          <Header
            left={
              <FormControl variant="standard" fullWidth>
                <TagsInput
                  fullWidth
                  placeholder="Search"
                  className={classes.searchInput}
                  name="search-rate-cards"
                  value={search}
                  onChange={setSearch}
                />
              </FormControl>
            }
            right={
              <Button
                variant="outlined"
                fullWidth
                onClick={() => history.push(RoutePaths.contracts.roles.create)}
                data-testid="add-role-button"
              >
                Add Role
              </Button>
            }
            title="Roles"
          />
        </Grid>
        <Grid item width="100%">
          {!error && rows.length == 0 ? (
            <Box position="relative" marginTop={1}>
              {fetching ? <InnerLoading loading={true} opacity={0} /> : <div>No roles found</div>}
            </Box>
          ) : (
            <Box position="relative">
              {error ? (
                <div>{JSON.stringify(error)}</div>
              ) : (
                <Table
                  rows={rows}
                  isLoading={fetching}
                  columns={rolesListTableColumns}
                  paginationControls={{
                    count: data?.roles?.pageCursors?.totalPages ?? 1,
                    page: page - 1,
                    onPageChange: (val: number) => setPage(val + 1),
                    onRowsPerPageChange: (val: number) => setLimit(val),
                    rowsPerPage: limit,
                  }}
                  searchControls={{ onSortChange: setOrder }}
                />
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default ContractsRolesList;
