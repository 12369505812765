import { FormikTextField } from '@root/components/Form/Formik/FormikTextField';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { FormikCheckbox } from '@root/components/Form/Formik/FormikCheckBox';

const ClientForm = () => {
  return (
    <>
      <FormFieldGrid label="Name:">
        <FormikTextField name="name" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Inactive:">
        <FormikCheckbox name="inactive" />
      </FormFieldGrid>
    </>
  );
};

export default ClientForm;
