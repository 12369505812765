/* eslint-disable @typescript-eslint/no-explicit-any */
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Alert, Breadcrumbs, Button, Grid, Link, Stack, Typography } from '@mui/material';
import FormSectionAccordion from '@root/components/Accordion/FormSectionAccordion';
import { CustomFormProvider } from '@root/components/Form/Formik/CustomFormProvider';
import { InnerLoading } from '@root/components/Loading/InnerLoading';
import { usePath } from '@root/providers/AuthProvider/modules/path';
import { ChildType } from '@root/typings/child-type';
import { routesWithValue } from '@root/utils/route';
import SnackbarUtils, { SnackbarCloseButton } from '@root/utils/SnackbarConfigurator';
import { FormikProps } from 'formik';
import { DocumentNode } from 'graphql';
import { PropsWithChildren, useState } from 'react';
import { useParams } from 'react-router';
import * as Urql from 'urql';
import BaseFormBtns from './BaseFormBtns';
import { LoadingObject } from './LoadingObject';
interface ParamTypes {
  id: string;
}

export default function generateUpsertFormWidget<FormSchema, RequestVariable, ResultSchema>(arg: {
  name: string;
  viewUrl?: ViewRouteType;
  redirect: string;
  initialValues: FormSchema;
  validationSchema: any;
  mutationDoc: DocumentNode;
  getDataQuery?: any;
}) {
  interface UpsertFormWidgetProps {
    mapping(v: FormSchema): RequestVariable;
    basicDetail: ChildType;
  }
  const UpsertFormWidget = (props: PropsWithChildren<UpsertFormWidgetProps>) => {
    const { id } = useParams<ParamTypes>();
    const { name, redirect, viewUrl, initialValues, validationSchema, mutationDoc, getDataQuery } =
      arg;
    const isUpdating = id && getDataQuery;
    const { popPath, getContext, redirectPath, paths, gotoPath } = usePath();
    const { children, mapping, basicDetail } = props;
    const [result, upsert] = Urql.useMutation<ResultSchema, RequestVariable>(mutationDoc);
    const { error } = result;
    const [updating, setUpdating] = useState(false);

    const realInitialValue = getContext(initialValues);

    const actionName = isUpdating ? 'Update' : 'Add';
    const gotoViewPage = (idV: string) =>
      idV && viewUrl && redirectPath(routesWithValue(':id', idV, viewUrl).root);
    const onSubmit = async (v: IgnoredAny) => {
      setUpdating(true);
      const body = await mapping(v);
      const { error: err, data } = await upsert(body);
      setUpdating(false);
      if (!err && name) {
        SnackbarUtils.success(`${name} was ${isUpdating ? 'Updated' : 'Created'} successfully`, {
          autoHideDuration: 3000,
          action: (snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />,
        });
        if (!popPath() && !isUpdating) {
          redirectPath(
            data ? (Object.values(data as IgnoredAny) as IgnoredAny)?.[0]?.data?.id : null,
          );
        }
      }
    };
    const formName = `${actionName} ${name}`;

    return (
      <CustomFormProvider
        initialValues={realInitialValue}
        validationSchema={validationSchema}
        formName={formName}
        actionName={actionName}
        onSubmit={onSubmit}
        isUpdating={isUpdating}
        setUpdating={(v: boolean) => setUpdating(v)}
      >
        {({ handleSubmit, status, isSubmitting }: FormikProps<FormSchema>) => (
          <Stack>
            {paths.length > 0 && (
              <Breadcrumbs aria-label="breadcrumb">
                {paths
                  .slice()
                  .reverse()
                  .map((pathInfo) => (
                    <Link
                      key={pathInfo.path}
                      onClick={() => gotoPath(pathInfo.path)}
                      component="button"
                      color="inherit"
                      fontSize="inherit"
                      href="#"
                    >
                      {pathInfo.pathName}
                    </Link>
                  ))}
                <Typography color="text.primary">{formName}</Typography>
              </Breadcrumbs>
            )}

            <Grid
              position="relative"
              component="form"
              onSubmit={handleSubmit}
              container
              justifyContent="center"
              direction="column"
              maxWidth={1000}
              mx="auto"
            >
              {status && (
                <Grid item width="100%" textAlign="center">
                  {status && <Alert severity="error">{error}</Alert>}
                </Grid>
              )}
              <Grid item width="100%" textAlign="center">
                <Typography variant="h5">{formName}</Typography>
              </Grid>
              {isUpdating && viewUrl && (
                <Grid item width="100%" textAlign="right">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => gotoViewPage(id)}
                    startIcon={<RemoveRedEyeIcon />}
                  >
                    View {name}
                  </Button>
                </Grid>
              )}
              <Grid position="relative" padding={2}>
                {isUpdating ? (
                  <>
                    <FormSectionAccordion title="Basic Details">
                      {basicDetail}
                      <BaseFormBtns />
                    </FormSectionAccordion>
                    {children}
                  </>
                ) : (
                  <>
                    {basicDetail}
                    <BaseFormBtns />
                  </>
                )}
                <InnerLoading loading={updating || isSubmitting} />
                <LoadingObject redirect={redirect} getDataQuery={getDataQuery} toggle={updating} />
              </Grid>
            </Grid>
          </Stack>
        )}
      </CustomFormProvider>
    );
  };
  return UpsertFormWidget;
}
