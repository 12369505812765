import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import FormikFormControl from './FormikFormControl';
import { useField } from './hooks';

export type FormikDatePickerProps = CustomFormikProps<DatePickerProps<Date, Date>>;

export const FormikDatePicker = (props: FormikDatePickerProps) => {
  const { name, label, helperText, props: baseProps, ...rest } = props;
  const { field, setValue } = useField(name, helperText);

  return (
    <FormikFormControl fullWidth name={name} label={label} {...rest}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          {...baseProps}
          {...field}
          onChange={(v) => setValue(v)}
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => <TextField {...params} {...rest} />}
        />
      </LocalizationProvider>
    </FormikFormControl>
  );
};
