import { useFormikContext } from 'formik';
import DetailText from '@root/components/Detail/Text';
import { FormikCheckbox } from '@root/components/Form/Formik/FormikCheckBox';
import { FormikTextField } from '@root/components/Form/Formik/FormikTextField';
import { ClientAsyncSelect } from '@root/components/Form/FormikSelect';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { ProductNode } from '@root/typings/generated';

const BasicDetails = () => {
  const { values } = useFormikContext<ProductNode>();
  return (
    <>
      <FormFieldGrid label="Compact ID:">
        <DetailText>{values.compactId ?? '-'}</DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Name:">
        <FormikTextField name="name" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Description:">
        <FormikTextField name="description" size="small" multiline />
      </FormFieldGrid>
      <FormFieldGrid label="Client:">
        <ClientAsyncSelect name="owner" goToId={values?.owner?.id} />
      </FormFieldGrid>
      <FormFieldGrid label="Inactive:">
        <FormikCheckbox name="inactive" />
      </FormFieldGrid>
    </>
  );
};

export default BasicDetails;
