import { Grid } from '@mui/material';
import FormSectionAccordion from '@root/components/Accordion/FormSectionAccordion';
import generateDetailViewWidget from '@root/components/Form/Widget/detail-view';
import RoutePaths from '@root/pages/routes';
import { useBlockStyles } from '@root/styles/block';
import { ContractNode, useGetContractQuery } from '@root/typings/generated';
import { BasicDetails } from './BasicDetails';
import { ContractProjectDetail } from './ContractProject';
import { ContractRateCard } from './ContractRateCard';
import { sortInternalProjectsByStatus } from '@root/utils/sort';

const ContractDetail: React.FC<{ data: ContractNode }> = (props: { data: ContractNode }) => {
  const { data: contract } = props;
  const classes = useBlockStyles();

  return (
    <>
      <FormSectionAccordion title="Basic Details">
        <BasicDetails contract={contract} />
      </FormSectionAccordion>
      <FormSectionAccordion title="Contract Projects">
        {contract.projects.sort(sortInternalProjectsByStatus).map((project, index) => (
          <Grid item key={index} className={classes.block}>
            <ContractProjectDetail project={project} />
          </Grid>
        ))}
      </FormSectionAccordion>
      <FormSectionAccordion title="Contract Rate Cards">
        {contract.contractRateCards.map((contractRateCard, index) => (
          <Grid item key={index} className={classes.block}>
            <ContractRateCard contractRateCard={contractRateCard} />
          </Grid>
        ))}
      </FormSectionAccordion>
    </>
  );
};

export default generateDetailViewWidget<ContractNode>({
  name: 'Contract',
  redirect: RoutePaths.contracts.home.list,
  editUrl: RoutePaths.contracts.home[':id'],
  getDataQuery: useGetContractQuery,
  component: ContractDetail,
  paper: false,
});
