import { createSvgIcon } from '@mui/material';

export const EditIcon = createSvgIcon(
  <svg viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9691 11.4526L15.0802 10.3902C15.2538 10.2242 15.5559 10.3404 15.5559 10.5795V15.4065C15.5559 16.2862 14.8093 17 13.8892 17H1.6667C0.746544 17 0 16.2862 0 15.4065V3.72069C0 2.84094 0.746544 2.12718 1.6667 2.12718H11.1634C11.41 2.12718 11.535 2.41268 11.3614 2.58199L10.2502 3.64434C10.1981 3.69414 10.1287 3.72069 10.0523 3.72069H1.6667V15.4065H13.8892V11.6385C13.8892 11.5688 13.917 11.5024 13.9691 11.4526ZM19.4067 4.75316L10.2884 13.471L7.14946 13.803C6.23972 13.8993 5.4654 13.1656 5.56609 12.2892L5.91332 9.28804L15.0316 0.57018C15.8267 -0.19006 17.1115 -0.19006 17.9032 0.57018L19.4032 2.00434C20.1984 2.76459 20.1984 3.99624 19.4067 4.75316ZM15.976 5.77899L13.9586 3.85017L7.50711 10.0217L7.25363 12.1896L9.52104 11.9472L15.976 5.77899ZM18.2261 3.13309L16.7261 1.69892C16.5837 1.56281 16.351 1.56281 16.2122 1.69892L15.1392 2.72475L17.1566 4.65357L18.2296 3.62774C18.3685 3.48831 18.3685 3.2692 18.2261 3.13309Z"
      fill="white"
    />
  </svg>,
  'EditIcon',
);
