import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Button, Grid } from '@mui/material';
import RoutePaths from '@root/pages/routes';
import { usePath } from '@root/providers/AuthProvider/modules/path';
import { useBlockStyles } from '@root/styles/block';
import { ContractNode } from '@root/typings/generated';
import { useFormikContext } from 'formik';
import { ContractProject } from './ContractProject';
const ContractProjects = () => {
  const { values } = useFormikContext<ContractNode>();
  const classes = useBlockStyles();
  const { pushPath } = usePath();

  return (
    <>
      {values.projects.map((project, index) => (
        <Grid item key={index} className={classes.block}>
          <ContractProject project={project} index={index} />
        </Grid>
      ))}
      <Grid mt={2} textAlign="right">
        <Button
          variant="contained"
          startIcon={<InsertDriveFileOutlinedIcon />}
          onClick={() => pushPath(RoutePaths.projects.home.create, null, { contract: values })}
        >
          New Project
        </Button>
      </Grid>
    </>
  );
};

export default ContractProjects;
