import * as _ from 'lodash';
import { Grid } from '@mui/material';
import { EntityNode, HubNode } from '@root/typings/generated';
import HubItem from './HubItem';

interface HubListProps {
  entity: EntityNode;
}
const HubList = (props: HubListProps) => {
  const { entity } = props;
  const hubs = entity.regions.reduce(
    (accumulator, region) => accumulator.concat(region.hubs),
    [] as HubNode[],
  );

  return (
    <Grid item container width="100%" spacing={2}>
      {_.sortBy(hubs, ['name']).map((hub) => (
        <Grid key={hub?.id} item xs={12} md={6}>
          <HubItem hub={hub as HubNode} />
        </Grid>
      ))}
    </Grid>
  );
};

export default HubList;
