import { List } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SidebarMenuItem } from '@root/typings/sidebar';
import React from 'react';
import NavCollapse from './Navigation/Collapse';
import NavMenuItem from './Navigation/MenuItem';
import NavSection from './Navigation/Section';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  sideNavMenu: {
    position: 'relative',
  },
}));

interface SidebarProps {
  menuItems: SidebarMenuItem[];
}

const Sidebar = (props: SidebarProps) => {
  const { menuItems } = props;
  const classes = useStyles();
  return (
    <List component="nav" className={clsx(classes.sideNavMenu, 'sidebar-content')}>
      {menuItems.map((item, index) => {
        switch (item.type) {
          case 'section':
            return <NavSection {...item} key={index} />;
          case 'collapse':
            return <NavCollapse {...item} key={index} />;
          case 'item':
            return <NavMenuItem {...item} key={index} />;
          default:
            return null;
        }
      })}
    </List>
  );
};

export default Sidebar;
