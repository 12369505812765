import {
  ArrowForward,
  Assignment,
  LibraryBooks,
  ShoppingCart,
  Work,
  BarChart,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Sidebar from '@root/components/Sidebar';
import { DRAWER_WIDTH } from '@root/constants';
import RoutePaths from '@root/pages/routes';
import { SidebarMenuItem } from '@root/typings/sidebar';
import React from 'react';
import { useLayoutContext } from '../Context/Context';

const menuItems: SidebarMenuItem[] = [
  {
    name: 'Contracts',
    type: 'collapse',
    style: 'main',
    icon: <Assignment />,
    link: RoutePaths.contracts.home.list,
    childrens: [
      {
        name: 'Profit Centres',
        type: 'item',
        icon: <ArrowForward />,
        link: RoutePaths.contracts.profit_centres.list,
      },
      {
        name: 'Clients',
        type: 'item',
        icon: <ArrowForward />,
        link: RoutePaths.contracts.clients.list,
      },
      {
        name: 'Rate Cards',
        type: 'item',
        icon: <ArrowForward />,
        link: RoutePaths.contracts.rate_cards.list,
      },
      {
        name: 'Roles',
        type: 'item',
        icon: <ArrowForward />,
        link: RoutePaths.contracts.roles.list,
      },
    ],
  },
  {
    name: 'Products',
    type: 'collapse',
    icon: <ShoppingCart />,
    style: 'main',
    link: RoutePaths.products.home.list,
    childrens: [
      {
        name: 'Components',
        type: 'item',
        icon: <ArrowForward />,
        link: RoutePaths.products.components.list,
      },
    ],
  },
  {
    name: 'Projects',
    type: 'collapse',
    icon: <LibraryBooks />,
    style: 'main',
    link: RoutePaths.projects.home.list,
    childrens: [
      {
        name: 'Chart View',
        icon: <BarChart />,
        type: 'item',
        link: RoutePaths.projects.chart.root,
      },
      {
        name: 'Billing Codes',
        type: 'item',
        icon: <ArrowForward />,
        link: RoutePaths.projects.billing_codes.list,
      },
    ],
  },
  {
    name: 'People',
    icon: <Work />,
    type: 'item',
    style: 'main',
    link: RoutePaths.people.list,
  },
];
const AuthDrawer = () => {
  const { isOpenDrawer, toggleDrawer } = useLayoutContext();

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <Sidebar menuItems={menuItems} />
    </div>
  );

  return (
    <Box component="nav" sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}>
      <Drawer
        variant="temporary"
        open={isOpenDrawer}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: DRAWER_WIDTH,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: DRAWER_WIDTH,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default AuthDrawer;
