import { List, Box, Theme } from '@mui/material';
import { SidebarMenuItem } from '@root/typings/sidebar';
import NavCollapse from './Collapse';
import NavMenuItem from './MenuItem';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  navSection: {
    position: 'relative',
    fontWeight: theme.typography.fontWeightRegular,
    '&:not(:first-child) $navHeader': {
      borderTop: `solid 1px ${theme.sidebarPalette.borderColor}`,
      marginTop: 10,
    },
    '&:not(:last-child)': {
      '.authLayout .sidebar-content:not(:hover) &': {
        borderBottom: `solid 1px ${theme.sidebarPalette.borderColor}`,
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
  },
  navHeader: {
    position: 'relative',
    padding: '24px 16px 20px 16px',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 10,
    letterSpacing: 1.5,
    '.authLayout .sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
}));
const NavSection = (props: SidebarMenuItem) => {
  const { name, childrens = [] } = props;
  const isExpandable = childrens && childrens.length > 0;
  const classes = useStyles();

  const MenuCollapse = (
    <List component="div" className={clsx(classes.navHeader, 'navHeader')}>
      {name}
    </List>
  );

  const MenuItemChildren = isExpandable ? (
    <List component="div" disablePadding>
      {childrens.map((item, index) => {
        switch (item.type) {
          case 'section':
            return <NavSection {...item} key={index} />;
          case 'collapse':
            return <NavCollapse {...item} key={index} />;
          default:
            return <NavMenuItem {...item} key={index} />;
        }
      })}
    </List>
  ) : null;

  return (
    <Box className={classes.navSection}>
      {MenuCollapse}
      {MenuItemChildren}
    </Box>
  );
};

export default NavSection;
