import { Box, Grid } from '@mui/material';
import EntityList from './EntityList';

const HomePage = () => {
  return (
    <Box>
      <Grid
        maxWidth={1300}
        container
        alignItems="center"
        spacing={2}
        direction="column"
        justifyContent="center"
        paddingY={2}
        mx="auto"
      >
        <EntityList />
      </Grid>
    </Box>
  );
};

export default HomePage;
