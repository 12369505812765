/* eslint-disable @typescript-eslint/no-explicit-any */
import { useGetMeQuery, UserNode } from '@root/typings/generated';
import React, { useEffect, useState } from 'react';
import { AuthContext, PathInfo } from './context';
import { getTokens } from '../../utils/tokens';

const AuthProvider = (props: { children: React.ReactElement }) => {
  const [user, setUser] = useState<UserNode | undefined>();
  const [paths, setPaths] = useState<PathInfo[]>([]);
  const [pathContext, setPathContext] = useState<any>({});
  const [tokens, setTokens] = useState<Tokens | undefined>(getTokens());

  const [result, runGetMeQuery] = useGetMeQuery({ requestPolicy: 'network-only' });

  const { data, fetching } = result;

  useEffect(() => {
    runGetMeQuery({ requestPolicy: 'network-only' });
  }, [tokens]);
  useEffect(() => {
    if (!fetching) setUser((data?.me ?? null) as UserNode);
  }, [data]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        paths,
        setPaths,
        pathContext,
        setPathContext,
        logging: fetching,
        tokens,
        setTokens,
      }}
    >
      {!fetching ? props.children : React.Fragment}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
