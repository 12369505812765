/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Chip,
  Grid,
  Pagination,
  Paper,
  TableContainer as MuiTableContainer,
  TableContainerProps,
} from '@mui/material';
import { Maybe, PageCursors } from '@root/typings/generated';
import { UseQueryState } from 'urql';
import { InnerLoading } from '../Loading/InnerLoading';

interface RowProps extends TableContainerProps {
  emptyLabel?: string;
  pageCursors?: Maybe<PageCursors>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  result: UseQueryState<any, any>;
}

export const TableContainer = (props: RowProps) => {
  const { children, emptyLabel, pageCursors, page, setPage, result, ...rest } = props;
  const { fetching, error } = result;
  if (!fetching && !pageCursors?.totalRecords)
    return (
      <Grid item container alignItems="center" spacing={2} direction="column">
        <Grid item>
          <Chip label={`No ${emptyLabel || 'rows'} found`} color="success" variant="outlined" />
        </Grid>
      </Grid>
    );
  return (
    <Grid item container alignItems="flex-end" spacing={2} direction="column">
      <Grid item width="100%">
        <Box position="relative">
          {error ? (
            <div data-testid="table-container-error">{JSON.stringify(error)}</div>
          ) : (
            <Paper sx={{ width: '100%', overflow: 'hidden' }} variant="outlined">
              <MuiTableContainer {...rest}>{children}</MuiTableContainer>
              <InnerLoading loading={fetching ?? false} />
            </Paper>
          )}
        </Box>
      </Grid>
      {(pageCursors?.totalPages ?? 0) > 1 && (
        <Grid item data-testid="table-container-pagination">
          <Pagination
            count={pageCursors?.totalPages ?? 1}
            page={page}
            onChange={(_, v) => setPage && setPage(v)}
          />
        </Grid>
      )}
    </Grid>
  );
};
