import { Typography } from '@mui/material';

export const NotFound = () => {
  return (
    <>
      <Typography variant="h5" component="div" gutterBottom>
        NotFound
      </Typography>
    </>
  );
};
