import BillingCodeComponentsForm from '@root/components/FormSections/BillingCodeComponentsForm';
import { InternalProjectNode } from '@root/typings/generated';
import { useFormikContext } from 'formik';

const BillingCodesAndComponents = () => {
  const { values } = useFormikContext<InternalProjectNode>();
  return (
    <BillingCodeComponentsForm
      billingCodes={values.billingCodes}
      components={values.product?.components ?? []}
      formikName="billingCodes"
    />
  );
};

export default BillingCodesAndComponents;
