import { Stack, Table, TableBody, TableCell, TableContainer } from '@mui/material';
import { Link } from '@root/components/Link';
import { TableHeader } from '@root/components/Table/TableHeader';
import { TableRow } from '@root/components/Table/TableRow';
import { UserNode } from '@root/typings/generated';
import { useFormikContext } from 'formik';
import UpsertProjectRoleAssignment from './UpsertProjectRoleAssignment';
import { routesWithValue } from '@root/utils/route';
import RoutePaths from '@root/pages/routes';
import DetailText from '@root/components/Detail/Text';

const Projects = () => {
  const { values: user } = useFormikContext<UserNode>();

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHeader
            columns={[
              {
                label: 'Contract',
                sortable: false,
              },
              {
                label: 'Project',
                sortable: false,
              },
              {
                label: 'Role',
                sortable: false,
              },
              {
                label: 'Remove from Project',
                sortable: false,
              },
            ]}
          />
          <TableBody>
            {user.projectAssignments.map((projectAssignment) => [
              <TableRow key={projectAssignment.id}>
                <TableCell>
                  {projectAssignment.project ? (
                    <Link
                      label={projectAssignment.project.contract.name}
                      route={
                        routesWithValue(
                          ':id',
                          projectAssignment.project.contract.id,
                          RoutePaths.contracts.home[':id'],
                        ).root
                      }
                    />
                  ) : (
                    <DetailText>None</DetailText>
                  )}
                </TableCell>
                <TableCell>
                  {projectAssignment.project ? (
                    <Link
                      isExternal
                      label={projectAssignment.project.name}
                      route={{
                        id: projectAssignment.project.id,
                        basePath: RoutePaths.projects.home[':id'].root,
                      }}
                    />
                  ) : (
                    <DetailText>None</DetailText>
                  )}
                </TableCell>
                <TableCell>
                  {projectAssignment.role ? (
                    <Link
                      label={projectAssignment.role.name}
                      route={
                        routesWithValue(':id', projectAssignment.role.id, RoutePaths.people[':id'])
                          .root
                      }
                    />
                  ) : (
                    <DetailText>None</DetailText>
                  )}
                </TableCell>
                <TableCell>
                  <UpsertProjectRoleAssignment data={projectAssignment} />
                </TableCell>
              </TableRow>,
            ])}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack mt={2} justifyContent="flex-end" direction="row" spacing={2}>
        <UpsertProjectRoleAssignment />
      </Stack>
    </>
  );
};

export default Projects;
