import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeView } from '@mui/lab';
import { Button, Box, Grid } from '@mui/material';
import { InnerLoading } from '@root/components/Loading/InnerLoading';
import { EntityNode, useListEntitiesQuery } from '@root/typings/generated';
import EntityTree from './EntityTree';
import { Header } from '@root/components/Header';
import { useHistory } from 'react-router-dom';
import RoutePaths from '@root/pages/routes';

const ContractsProfitCentresList = () => {
  const [result] = useListEntitiesQuery({});
  const history = useHistory();

  const { fetching, data, error } = result;
  const rows = (data?.entities ?? []) as EntityNode[];

  const defaultExpanded = rows
    .map((entity) => [
      entity.id,
      entity.regions?.map((region) => [
        region?.id ?? '',
        region?.hubs?.map((hub) => hub?.id ?? '') ?? [],
      ]) ?? [],
    ])
    .flat(4);

  return (
    <Box>
      <Grid container alignItems="flex-end" spacing={2} direction="column">
        <Grid item width="100%">
          <Header
            title="Profit Centres"
            right={
              <Button
                variant="outlined"
                fullWidth
                onClick={() => history.push(RoutePaths.contracts.profit_centres.create)}
                data-testid={'add-profit-centre-button'}
              >
                Add Profit Centre
              </Button>
            }
          />
        </Grid>
        {!error && rows.length == 0 ? (
          <Box position="relative" height="calc(100% - 100px)" marginTop={1}>
            {fetching ? (
              <InnerLoading opacity={0} loading={true} />
            ) : (
              <div>No profit centres found</div>
            )}
          </Box>
        ) : (
          <Grid item width="100%">
            <Box position="relative">
              {error ? (
                <div>{JSON.stringify(error)}</div>
              ) : (
                <>
                  <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    defaultExpanded={defaultExpanded}
                    sx={{ flexGrow: 1, maxWidth: 700, overflowY: 'auto' }}
                  >
                    {rows.map((row) => (
                      <EntityTree key={row?.id} entity={row} />
                    ))}
                  </TreeView>
                  <InnerLoading loading={fetching} />
                </>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default ContractsProfitCentresList;
