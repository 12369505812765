import { createSvgIcon } from '@mui/material';

export const FileWithTable = createSvgIcon(
  <svg viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.91667 8.90625V15.4375C2.91667 15.7655 3.17771 16.0312 3.5 16.0312H10.5C10.8223 16.0312 11.0833 15.7655 11.0833 15.4375V8.90625C11.0833 8.5782 10.8223 8.3125 10.5 8.3125H3.5C3.17771 8.3125 2.91667 8.5782 2.91667 8.90625ZM7.58333 10.0938H9.91667V11.875H7.58333V10.0938ZM7.58333 13.0625H9.91667V14.8438H7.58333V13.0625ZM4.08333 10.0938H6.41667V11.875H4.08333V10.0938ZM4.08333 13.0625H6.41667V14.8438H4.08333V13.0625ZM13.4834 3.63598L10.4249 0.523242C10.0968 0.189258 9.65198 0 9.18932 0H1.74964C0.783854 0.00371094 0 0.801562 0 1.78459V17.2191C0 18.2021 0.783854 19 1.74964 19H12.2474C13.2136 19 14 18.2021 14 17.2191V4.89732C14 4.42641 13.8115 3.96996 13.4834 3.63598ZM9.33151 1.92932L12.1056 4.7526H9.33151V1.92932ZM12.25 17.2191H1.74964V1.78459H7.58187V5.64322C7.58187 6.13678 7.97198 6.53348 8.45687 6.53348H12.25V17.2191Z"
      fill="white"
    />
  </svg>,
  'FileWithDolarIcon',
);
