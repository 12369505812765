/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';
import { ReactNode } from 'react';
const useStyles = makeStyles((theme: Theme) => ({
  header: {
    backgroundColor: theme.palette.grey[400],
    '& th': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

export interface Column extends TableCellProps {
  id?: string;
  label: string | ReactNode;
  sortable?: boolean;
  className?: string;
  visible?: boolean;
}

interface TableHeaderProps {
  changeOrder?: (key: string) => void;
  orderKey?: string;
  orderDir?: Order;
  columns: Column[];
  className?: string;
}

export const TableHeader = (props: TableHeaderProps) => {
  const { columns, orderKey, orderDir, changeOrder } = props;
  const classes = useStyles();

  return (
    <TableHead className={classes.header}>
      <TableRow>
        {columns
          .filter((v) => v.visible !== false)
          .map(({ id, label, sortable, visible, ...rest }, index) => (
            <TableCell
              key={`${id || label || index}-table`}
              sortDirection={orderKey === id ? orderDir : false}
              data-testid={`table-header-${id || label || index}`}
              {...rest}
            >
              {sortable !== false ? (
                <TableSortLabel
                  active={orderKey === id}
                  direction={orderKey === id ? orderDir : 'asc'}
                  onClick={() => id && changeOrder && changeOrder(id)}
                  data-testid={`table-header-sort-${id || label || index}`}
                >
                  {label}
                  {orderKey === id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {orderDir === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                label
              )}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};
