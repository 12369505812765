import FormikAsyncAutocomplete from '@root/components/Form/Formik/FormikAsyncAutocomplete';
import RoutePaths from '@root/pages/routes';
import { useListContractsQuery } from '@root/typings/generated';
import { BaseAsyncSelect } from './Base';
import { FormicAsyncSelect } from './_type';

export const ContractAsyncSelect = (props: FormicAsyncSelect) => {
  const { name, size, orderBy, hideAddBtn, goToId, ...rest } = props;

  return (
    <BaseAsyncSelect
      hideAddBtn={hideAddBtn}
      createUrl={RoutePaths.contracts.home.create}
      goToUrl={goToId && `${RoutePaths.contracts.home.root}/${goToId}`}
    >
      <FormikAsyncAutocomplete
        queryKey="contracts"
        getOptionLabel={(option) => option?.name ?? ''}
        useQuery={useListContractsQuery}
        {...rest}
        name={name}
        size={size || 'small'}
        orderBy={orderBy || 'name'}
      />
    </BaseAsyncSelect>
  );
};
