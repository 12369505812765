import { blue, cyan, green, yellow } from '@mui/material/colors';
import { Maybe, ProjectStatusEnum } from '@root/typings/generated';

export const getProjectStatusColor = (status?: Maybe<string>, defVal = '') => {
  if (!status) return defVal;
  switch (status) {
    case ProjectStatusEnum.Upcoming:
      return cyan[300];
    case ProjectStatusEnum.InProgress:
      return blue[500];
    case ProjectStatusEnum.Overdue:
      return yellow[500];
    case ProjectStatusEnum.Completed:
      return green.A400;

    default:
      return defVal;
  }
};

// UPCOMING = InternalProject.STATUS_UPCOMING
// IN_PROGRESS = InternalProject.STATUS_IN_PROGRESS
// OVERDUE = InternalProject.STATUS_OVERDUE
// COMPLETED = InternalProject.STATUS_COMPLETED
