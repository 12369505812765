import { cyan, grey } from '@mui/material/colors';
import { alpha, ThemeOptions } from '@mui/material/styles';

const defaultTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    info: {
      main: cyan['A700'],
      contrastText: '#fff',
    },
    error: {
      main: '#D65555',
    },
    background: {
      default: '#fff',
    },
  },
  sidebarPalette: {
    bgColor: '#fff',
    textColor: 'rgba(0, 0, 0, 0.6)',
    textDarkColor: 'rgba(0, 0, 0, 0.87)',
    textActiveColor: '#6200EE',
    navHoverBgColor: 'rgb(229, 229, 229)',
    navActiveBgColor: 'rgb(239, 229, 253)',
    borderColor: 'rgba(33, 33, 33, 0.08)',
  },
  typography: {
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 'bold',
    button: {
      textTransform: 'none',
    },
  },
  sidebarWidth: 240,
  localComponents: {
    InnerLoading: {
      backgroundColor: alpha(grey[500], 0.2),
    },
  },
};

export default defaultTheme;
