import FormSectionAccordion from '@root/components/Accordion/FormSectionAccordion';
import generateDetailViewWidget from '@root/components/Form/Widget/detail-view';
import RoutePaths from '@root/pages/routes';
import { ComponentNode, useGetComponentQuery } from '@root/typings/generated';
import { BasicDetails } from './BasicDetails';

interface DetailProps {
  data: ComponentNode;
}
const Detail: React.FC<DetailProps> = ({ data }: DetailProps) => {
  return (
    <>
      <FormSectionAccordion title="Basic Details">
        <BasicDetails component={data} />
      </FormSectionAccordion>
    </>
  );
};

export default generateDetailViewWidget<ComponentNode>({
  name: 'Component',
  redirect: RoutePaths.products.components.list,
  editUrl: RoutePaths.products.components[':id'],
  getDataQuery: useGetComponentQuery,
  component: Detail,
  paper: false,
});
