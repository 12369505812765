import { ProfitCentreNode } from '@root/typings/generated';
import * as Yup from 'yup';
import { NullID } from '../../../../../yup/initial-value';

export const initialValues: ProfitCentreNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  name: '',
  layer: '',
  parent: null,
  manager: null,
  managerRoleName: '',
};

export const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  manager: Yup.object().nullable().required(),
  parent: Yup.object().nullable().notRequired(),
});
