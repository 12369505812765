import {
  LoginMutationVariables,
  Refresh,
  useLoginMutation,
  useRevokeTokenMutation,
} from '@root/typings/generated';
import { setAuthCookies } from '@root/utils/tokens';
import { useAuthContext } from '../context';
import { removeAuthCookies } from '../../../utils/tokens';

export function useAuth() {
  const [, loginAction] = useLoginMutation();
  const [, logoutAction] = useRevokeTokenMutation();
  const { tokens, setTokens, setUser, ...rest } = useAuthContext();

  const login = async (cred: LoginMutationVariables) => {
    const result = await loginAction(cred);
    if (!result || result.error) {
      throw Error('Unable to login with the provided credentials.');
    }
    setAuthCookies(result.data?.tokenAuth as Refresh);
    setTokens(result.data?.tokenAuth as Tokens);
  };
  const logout = async () => {
    await logoutAction({ refreshToken: tokens?.refreshToken });
    await removeAuthCookies();
    setUser(undefined);
    setTokens(undefined);
  };

  return { login, logout, ...rest };
}
