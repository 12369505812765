import React from 'react';
import { useField } from 'formik';
import { Checkbox, CheckboxProps } from '@mui/material';
import { styled } from '@mui/system';

export type FormikBudgetInputProps = CheckboxProps &
  FormikBaseProps & { applyCustomStyles?: boolean };

const CustomCheckbox = styled((props: CheckboxProps) => <Checkbox {...props} />)({
  '&.MuiButtonBase-root.MuiCheckbox-root': {
    padding: 0,
    marginBottom: 16,
  },
});

const BaseCheckbox = styled(Checkbox)({});

export const FormikCheckbox = (props: FormikBudgetInputProps) => {
  const [field] = useField(props.name);

  // Determine which Checkbox component to use based on the applyCustomStyles prop
  const CheckboxComponent = props.applyCustomStyles ? CustomCheckbox : BaseCheckbox;

  return <CheckboxComponent {...field} checked={field.value} />;
};

FormikCheckbox.defaultProps = {
  applyCustomStyles: true,
};
