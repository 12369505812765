import { BaseTextFieldProps, Chip, TextField, Theme } from '@mui/material';
import { CommonProps } from '@mui/material/OverridableComponent';
import { makeStyles } from '@mui/styles';
import { onChangeStringHandler } from '@root/typings/lib';
import Downshift from 'downshift';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    width: '50%',
    maxWidth: '150px',
    minWidth: '50px',
  },

  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}));

export type Tag = string;
interface TagsInputProps extends BaseTextFieldProps {
  value?: Tag[];
  'aria-label'?: string;
  name: string;
  label?: string;
  disabled?: boolean;
  onChange: (tags: Tag[]) => void;
  startAdornment?: React.ReactNode;
}

export default function TagsInput(props: TagsInputProps & CommonProps) {
  const { onChange, value, disabled, label, className, name, startAdornment, ...rest } = props;
  const [input, setInput] = useState('');
  const [tags, setTags] = useState<Tag[]>(value || []);

  useEffect(() => {
    onChange(tags);
  }, [tags, onChange]);

  const classes = useStyles();

  const addItem = () => {
    if (!input) {
      return;
    }

    const existing = tags.find((t) => t === input);
    if (existing) {
      setInput('');
      return;
    }

    const isEmpty = input.replace(/\s/g, '').length === 0;
    if (isEmpty) {
      return;
    }

    const value = input.trim();

    const added = [...tags, value];
    setTags(added);
    setInput('');
  };

  const deleteTag = (tag: Tag) => {
    const removed = tags.filter((t) => t !== tag);
    setTags(removed);
  };

  const removeLastItem = () => {
    const hasInput = input.length > 0;
    if (hasInput) {
      return;
    }

    const lastItem = tags[tags.length - 1];
    if (!lastItem) {
      return;
    }

    deleteTag(lastItem);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        addItem();
        break;
      case 'Backspace':
        removeLastItem();
        break;
    }
  };

  return (
    <Downshift inputValue={input}>
      {({ getInputProps }) => {
        const { onBlur, onFocus, ...inputProps } = getInputProps({
          onKeyDown: handleKeyDown,
        });

        return (
          <div>
            <TextField
              onChange={onChangeStringHandler(setInput)}
              label={label}
              disabled={disabled}
              className={className}
              InputProps={{
                classes: {
                  root: classes.root,
                  input: classes.input,
                },

                startAdornment: [
                  startAdornment,
                  tags.map((item) => (
                    <span aria-label="tag" key={item}>
                      <Chip
                        tabIndex={-1}
                        color="primary"
                        label={item}
                        onDelete={() => deleteTag(item)}
                        className={classes.chip}
                        disabled={disabled}
                      />
                    </span>
                  )),
                ],
                onBlur,
                onFocus,
              }}
              inputProps={{ ...inputProps, 'aria-label': props['aria-label'] }}
              name={name}
              {...rest}
            />
          </div>
        );
      }}
    </Downshift>
  );
}
