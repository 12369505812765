import { Button, FormControl, Box, Grid, Stack, Typography } from '@mui/material';
import { ClientSelect } from '@root/components/Form/Select/ClientSelect';
import { InnerLoading } from '@root/components/Loading/InnerLoading';
import { usePagination } from '@root/hooks/use-pagination';
import { ClientNode, ProductNode, useListProductsQuery } from '@root/typings/generated';
import { useState } from 'react';
import { useInputStyles } from '@root/styles/form/input';
import { useHistory } from 'react-router-dom';
import { Header } from '@root/components';
import TagsInput from '@root/components/Form/Base/TagsInput';
import RoutePaths from '@root/pages/routes';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Table } from '@root/components/Table/Table';
import { routesWithValue } from '@root/utils/route';
import { Link } from '@root/components/Link';

const productListTableColumns: GridColDef<ProductNode>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Link
        label={params.value}
        route={routesWithValue(':id', params.row.id, RoutePaths.products.home[':id']).root}
      />
    ),
  },
  {
    field: 'owner',
    headerName: 'Client',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => params.row.owner?.name,
  },
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => params.row.realId,
  },
];

const ProductsList = () => {
  const classes = useInputStyles();
  const history = useHistory();
  const { limit, page, search, setSearch, setPage, orderBy, setLimit, setOrder } =
    usePagination('name');

  const [clients, setClients] = useState<ClientNode[]>();

  const [result] = useListProductsQuery({
    variables: {
      offset: limit * (page - 1),
      first: limit,
      search,
      orderBy,
      clientIds: clients?.map(({ id }) => id) ?? [],
    },
  });

  const { fetching, data, error } = result;
  const rows = data?.products?.edges?.map((data) => data?.node) ?? [];

  return (
    <Box>
      <Grid container alignItems="flex-end" spacing={2} direction="column">
        <Grid item width="100%">
          <Header
            title="Products"
            left={
              <FormControl variant="standard" fullWidth>
                <TagsInput
                  fullWidth
                  placeholder="Search"
                  className={classes.searchInput}
                  name="search-rate-cards"
                  value={search}
                  onChange={setSearch}
                />
              </FormControl>
            }
            right={
              <Button
                variant="outlined"
                fullWidth
                onClick={() => history.push(RoutePaths.products.home.create)}
                data-testid="add-products-button"
              >
                Add Product
              </Button>
            }
          />
        </Grid>
        <Grid item width="100%">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h6">Select Client(s): </Typography>
            <Box width="100%" maxWidth="600px">
              <ClientSelect
                multiple
                value={clients}
                onChange={(_, v) => setClients(v)}
                limitTags={3}
                disableCloseOnSelect
              />
            </Box>
          </Stack>
        </Grid>

        {rows?.length < 1 ? (
          <Grid item width="100%">
            <Box position="relative" marginTop={1}>
              {fetching ? (
                <InnerLoading loading={true} opacity={0} />
              ) : (
                <div>No products found</div>
              )}
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item width="100%">
              <Box position="relative">
                {error ? (
                  <div>{JSON.stringify(error)}</div>
                ) : (
                  <Table
                    rows={rows}
                    isLoading={fetching}
                    columns={productListTableColumns}
                    paginationControls={{
                      count: data?.products?.pageCursors?.totalPages ?? 1,
                      page: page - 1,
                      onPageChange: (val: number) => setPage(val + 1),
                      onRowsPerPageChange: (val: number) => setLimit(val),
                      rowsPerPage: limit,
                    }}
                    searchControls={{ onSortChange: setOrder }}
                  />
                )}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ProductsList;
