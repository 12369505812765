import generateUpsertFormWidget from '@root/components/Form/Widget/upsert-form';
import { CurrencyCodes } from '@root/constants/currencies';
import RoutePaths from '@root/pages/routes';
import {
  UpsertRateCardDocument,
  UpsertRateCardMutation,
  UpsertRateCardMutationVariables,
  useGetRateCardQuery,
} from '@root/typings/generated';
import Form from './form';
import { initialValues, RateCardInputType, validationSchema } from './_type';
import RateCardRoleSelection from './RateCardRoleSelection';

const UpsertRateCardFormWidget = generateUpsertFormWidget<
  RateCardInputType,
  UpsertRateCardMutationVariables,
  UpsertRateCardMutation
>({
  name: 'Rate Card',
  redirect: RoutePaths.contracts.rate_cards.list,
  viewUrl: RoutePaths.contracts.rate_cards[':id'],
  initialValues,
  validationSchema,
  mutationDoc: UpsertRateCardDocument,
  getDataQuery: useGetRateCardQuery,
});

const UpsertRateCard = () => {
  return (
    <UpsertRateCardFormWidget
      mapping={({ id, title, rateCardRoles, contract }) => ({
        data: {
          instance: id,
          title,
          contract: contract?.id,
          rateCardRoles: rateCardRoles?.map(({ id, role, price, unit }) => ({
            instance: id,
            role: role?.id ?? '',
            price: {
              amount: price?.amount ?? 0,
              currency: price?.currency ?? CurrencyCodes.AUD,
            },
            unit,
          })),
        },
      })}
      basicDetail={<Form />}
    >
      <RateCardRoleSelection />
    </UpsertRateCardFormWidget>
  );
};

export default UpsertRateCard;
