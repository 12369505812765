import generateUpsertFormWidget from '@root/components/Form/Widget/upsert-form';
import transferMoney from '@root/graphql/input-transfer/transfer-money';
import RoutePaths from '@root/pages/routes';
import {
  BillingCodeNode,
  UpsertBillingCodeDocument,
  UpsertBillingCodeMutation,
  UpsertBillingCodeMutationVariables,
  useGetBillingCodeQuery,
} from '@root/typings/generated';
import { BasicDetails } from './BasicDetails';
import { initialValues, validationSchema } from './_type';

const UpsertBillingCodeFormWidget = generateUpsertFormWidget<
  BillingCodeNode,
  UpsertBillingCodeMutationVariables,
  UpsertBillingCodeMutation
>({
  name: 'Billing Code',
  redirect: RoutePaths.projects.billing_codes.list,
  viewUrl: RoutePaths.projects.billing_codes[':id'],
  initialValues,
  validationSchema,
  mutationDoc: UpsertBillingCodeDocument,
  getDataQuery: useGetBillingCodeQuery,
});

const UpsertBillingCode = () => {
  return (
    <UpsertBillingCodeFormWidget
      mapping={({ id, internalProject, productObjective, components, totalSpent, inactive }) => ({
        data: {
          instance: id,
          inactive,
          internalProject: internalProject?.id ?? '',
          productObjective: productObjective?.id ?? '',
          totalSpent: transferMoney(totalSpent),
          components: components.map((component) => component.id),
        },
      })}
      basicDetail={<BasicDetails />}
    />
  );
};

export default UpsertBillingCode;
