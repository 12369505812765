import { useState } from 'react';
import { ExpandMore, OpenInBrowser } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Stack,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { Link } from '@root/components/Link';
import OutlinedComponent from '@root/components/Paper/OutlinedComponent';
import { EntityNode } from '@root/typings/generated';
import HubList from './HubList';
import ContractList from './ContractList';
import { routesWithValue } from '@root/utils/route';
import RoutePaths from '@root/pages/routes';

const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: grey[500],
    boxShadow: 'none',
    marginBottom: '20px',
    '&:before': {
      display: 'none',
    },
  },
  accordionDetail: {
    position: 'relative',
    paddingTop: '20px',
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: grey[600],
  },
  workBox: {
    position: 'relative',
    paddingTop: '20px',
  },
  hubsBox: {
    position: 'relative',
    paddingTop: '20px',
  },
  action: {
    position: 'absolute',
    top: '40px',
    right: '60px',
    height: '10px',
    borderRadius: '100px',
  },
}));

interface EntityItemProps {
  entity: EntityNode;
}

const EntityItem = (props: EntityItemProps) => {
  const { entity } = props;
  const classes = useStyles();
  // const [active, setActive] = useState(false);
  const [expanded, setExpanded] = useState(true);

  return (
    <Accordion className={classes.accordion} disableGutters defaultExpanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: 'white' }} />}
        onClick={() => setExpanded(!expanded)}
      >
        <Stack
          textAlign="center"
          direction="row"
          spacing={2}
          alignItems="center"
          color="white"
          justifyContent="center"
        >
          <Link
            labelProps={{
              variant: 'h5',
              color: 'white',
            }}
            label={entity.name}
            isExternal
            leftIcon={<OpenInBrowser />}
            route={
              routesWithValue(':id', entity.id, RoutePaths.contracts.profit_centres[':id']).root
            }
          />
          {entity.manager && (
            <Box>
              <Link
                labelProps={{
                  color: 'white',
                }}
                isExternal
                label={entity.manager.name ?? entity.manager.firstName}
                route={routesWithValue(':id', entity.manager.id, RoutePaths.people[':id']).root}
              />{' '}
              <Chip label={entity.managerRoleName} color="info" size="small" />
            </Box>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetail}>
        {expanded && (
          <Stack spacing="2">
            <Box className={classes.workBox}>
              <OutlinedComponent
                label={
                  <Typography variant="h6" left="50%" color="white">
                    Contracts
                  </Typography>
                }
              >
                <ContractList entity={entity} />
              </OutlinedComponent>
            </Box>

            <Box className={classes.hubsBox}>
              <OutlinedComponent
                label={
                  <Typography variant="h6" left="50%" color="white">
                    Hubs
                  </Typography>
                }
              >
                <HubList entity={entity} />
              </OutlinedComponent>
            </Box>
          </Stack>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default EntityItem;
