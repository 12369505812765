/* eslint-disable @typescript-eslint/no-explicit-any */
import SnackbarUtils, { SnackbarCloseButton } from '@root/utils/SnackbarConfigurator';
import { cacheExchange } from '@urql/exchange-graphcache';
import { createClient, dedupExchange, errorExchange, fetchExchange, Operation } from 'urql';
import { authExchange } from '@urql/exchange-auth';
import { RefreshTokenDocument } from '@root/typings/generated';
import { makeOperation, AnyVariables } from '@urql/core';
import { getTokens, removeAuthCookies, setAuthCookies } from '@root/utils/tokens';
import { REACT_APP_API_URL } from '@root/constants/env';
import { devtoolsExchange } from '@urql/devtools';

const getAPIUrl = (): string => {
  return `${REACT_APP_API_URL}/graphql-frontend/`;
};

const getAuth = async (dt: any) => {
  const { mutate } = dt;

  const authState = getTokens();
  if (!authState) return null;

  if (!authState.expireTime || authState.expireTime <= new Date()) {
    const result = await mutate(RefreshTokenDocument, {
      refreshToken: authState.refreshToken,
    });
    if (result.data?.refreshToken) {
      return setAuthCookies(result.data?.refreshToken);
    }
    if (!result.error?.networkError) await removeAuthCookies();
    return null;
  }
  return authState;
};

const addAuthToOperation = (dt: {
  authState: Tokens | null | undefined;
  operation: Operation<unknown, AnyVariables>;
}) => {
  const { authState, operation } = dt;

  if (!authState || !authState.token) {
    return operation;
  }
  const fetchOptions =
    typeof operation.context.fetchOptions === 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {};

  return makeOperation(operation.kind, operation, {
    ...operation.context,
    fetchOptions: {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        Authorization: `JWT ${authState.token}`,
      },
    },
  });
};

const willAuthError = (dt: any) => {
  const { authState } = dt;

  if (!authState || getTokens()?.token !== authState?.token) return true;
  if (!authState.expireTime || authState.expireTime <= new Date()) return true;
  return false;
};
export const client = createClient({
  exchanges: [
    devtoolsExchange,
    errorExchange({
      onError: (error) => {
        if (
          !error?.graphQLErrors?.some((errors) =>
            errors?.path?.some((path) => ['me', 'revokeToken'].includes(path as string)),
          )
        )
          SnackbarUtils.error(error.message.replace('[GraphQL]', ''), {
            autoHideDuration: 3000,
            action: (snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />,
          });
      },
    }),
    dedupExchange,
    cacheExchange(),
    authExchange({
      getAuth,
      addAuthToOperation,
      willAuthError,
    }),
    fetchExchange,
  ],
  fetchOptions: {
    credentials: 'omit',
  },
  requestPolicy: `cache-and-network`,
  url: getAPIUrl(),
});
