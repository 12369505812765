import { Table, TableBody, TableCell, TableContainer } from '@mui/material';
import DetailText from '@root/components/Detail/Text';
import { Link } from '@root/components/Link';
import { TableHeader } from '@root/components/Table/TableHeader';
import { TableRow } from '@root/components/Table/TableRow';
import RoutePaths from '@root/pages/routes';
import { BillingCodeNode } from '@root/typings/generated';
import { routesWithValue } from '@root/utils/route';

interface Props {
  data: BillingCodeNode;
}
export const Components = ({ data }: Props) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHeader
          columns={[
            {
              label: 'Component Name',
              sortable: false,
            },
            {
              label: 'Repository URL',
              sortable: false,
            },
          ]}
        />
        <TableBody>
          {data.components.map((component) => [
            <TableRow key={component.id}>
              <TableCell>
                <Link
                  label={component.name}
                  route={
                    routesWithValue(':id', component.id, RoutePaths.products.components[':id']).root
                  }
                />
              </TableCell>
              <TableCell>
                {component.repository?.url ? (
                  <Link
                    isExternal
                    label={component.repository.url}
                    route={component.repository.url}
                  />
                ) : (
                  <DetailText>No URL</DetailText>
                )}
              </TableCell>
            </TableRow>,
          ])}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
