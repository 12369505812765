import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Link } from '@root/components/Link';
import { TableHeader } from '@root/components/Table/TableHeader';
import { InternalProjectNode } from '@root/typings/generated';
import DetailText from '@root/components/Detail/Text';
import { routesWithValue } from '@root/utils/route';
import RoutePaths from '@root/pages/routes';

interface Props {
  data: InternalProjectNode;
}
export const People: React.FC<Props> = ({ data }: Props) => {
  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHeader
            columns={[
              { id: 'Person', label: 'Person', sortable: false },
              { id: 'Role', label: 'Role', sortable: false },
            ]}
          />
          <TableBody>
            {data.peopleAssignments.map((pa) => [
              <TableRow key={pa.id}>
                <TableCell>
                  {pa.person ? (
                    <Link
                      isExternal
                      label={pa.person.name ?? pa.person.firstName}
                      route={routesWithValue(':id', pa.person.id, RoutePaths.people[':id']).root}
                    />
                  ) : (
                    <DetailText>None</DetailText>
                  )}
                </TableCell>
                <TableCell>
                  {pa.role ? (
                    <Link
                      label={pa.role.name}
                      route={routesWithValue(':id', pa.role.id, RoutePaths.people[':id']).root}
                    />
                  ) : (
                    <DetailText>None</DetailText>
                  )}
                </TableCell>
              </TableRow>,
            ])}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
