import { RemoveCircleOutline } from '@mui/icons-material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Box, Button, Grid, IconButton, Stack } from '@mui/material';
import { RoleAsyncSelect, UserAsyncSelect } from '@root/components/Form/FormikSelect';
import {
  InternalProjectNode,
  ProjectRoleAssignmentNode,
  useDeleteProjectRoleAssignmentMutation,
  useUpsertProjectRoleAssignmentMutation,
} from '@root/typings/generated';
import { FieldArray, useFormikContext } from 'formik';
import { peopleAssignmentInitialValue, peopleAssignmentYup } from './_type';
import {
  CustomFormProvider,
  useFormContext,
} from '@root/components/Form/Formik/CustomFormProvider';

const PeopleForm = () => {
  const { setUpdating } = useFormContext();
  const { values } = useFormikContext<InternalProjectNode>();

  const [, upsertProjectRoleAssignment] = useUpsertProjectRoleAssignmentMutation();
  const [, deleteProjectRoleAssignment] = useDeleteProjectRoleAssignmentMutation();

  const handleAddOrUpdateProjectRole = async (
    { id, role, person }: ProjectRoleAssignmentNode,
    resetForm?: () => void,
  ) => {
    setUpdating(true);

    await upsertProjectRoleAssignment({
      data: {
        instance: id,
        project: values.id,
        role: role?.id,
        person: person?.id,
      },
    });

    if (resetForm) resetForm();
    setUpdating(false);
  };
  const handleRemoveProjectRole = async (id: string) => {
    setUpdating(true);
    await deleteProjectRoleAssignment({
      instance: id,
    });
    setUpdating(false);
  };

  return (
    <div>
      <CustomFormProvider
        validationSchema={peopleAssignmentYup}
        onSubmit={(val, actions) => handleAddOrUpdateProjectRole({ ...val }, actions.resetForm)}
        initialValues={{ ...peopleAssignmentInitialValue, project: values }}
      >
        {({ handleSubmit }) => (
          <Box>
            <Stack spacing={2} direction={'row'}>
              <Box width={'100%'}>
                <UserAsyncSelect hideAddBtn label="User" name="person" size="small" />
              </Box>
              <Box width={'100%'}>
                <RoleAsyncSelect hideAddBtn label="Role" name="role" size="small" />
              </Box>
            </Stack>
            <Stack p={2} direction={'row'} justifyContent={'flex-end'}>
              <Button
                variant="contained"
                size="small"
                startIcon={<InsertDriveFileOutlinedIcon />}
                onClick={() => handleSubmit()}
              >
                Add Person
              </Button>
            </Stack>
          </Box>
        )}
      </CustomFormProvider>
      <FieldArray
        name="peopleAssignments"
        render={(helpers) => (
          <>
            {helpers.form.values.peopleAssignments?.map(
              (value: ProjectRoleAssignmentNode, index: number) => (
                <Grid item container key={index} spacing={1} my={1}>
                  <Grid item xs={5}>
                    <UserAsyncSelect
                      hideAddBtn
                      name={`peopleAssignments.${index}.person`}
                      label="Select Person"
                      getOptionDisabled={(option) => {
                        return values?.peopleAssignments?.some((v) => v?.person?.id === option.id);
                      }}
                      onChange={(_, newPerson) =>
                        handleAddOrUpdateProjectRole({ ...value, person: newPerson })
                      }
                      goToId={values?.peopleAssignments[index]?.person?.id}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RoleAsyncSelect
                      hideAddBtn
                      name={`peopleAssignments.${index}.role`}
                      label="Select Role"
                      onChange={(_, newRole) =>
                        handleAddOrUpdateProjectRole({ ...value, role: newRole })
                      }
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => {
                        handleRemoveProjectRole(value.id);
                      }}
                    >
                      <RemoveCircleOutline fontSize="medium" />
                    </IconButton>
                  </Grid>
                </Grid>
              ),
            )}
          </>
        )}
      />
    </div>
  );
};

export default PeopleForm;
