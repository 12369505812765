import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { CompactCheckbox } from '@root/components/Checkbox/CompactCheckbox';
import DetailText from '@root/components/Detail/Text';
import { TableHeader } from '@root/components/Table/TableHeader';
import { REACT_APP_API_URL } from '@root/constants/env';
import { ProductObjectiveNode } from '@root/typings/generated';

interface Props {
  objectives: ProductObjectiveNode[];
}

const ListObjectives: React.FC<Props> = ({ objectives }: Props) => {
  const columnHeaders = [
    { id: 'id', label: 'ID' },
    { id: 'shortName', label: 'Short Name' },
    { id: 'longName', label: 'Long Name' },
    { id: 'inactive', label: 'Is Inactive' },
  ];

  return (
    <TableContainer>
      <Table size="small">
        <TableHeader columns={columnHeaders} />
        <TableBody>
          {objectives.map((objective) => [
            <TableRow key={objective.id}>
              <TableCell>
                <DetailText>
                  {objective.compactId}{' '}
                  <a
                    href={`${REACT_APP_API_URL}/admin/architecture/productobjective/${objective.realId}/change/`}
                  >
                    admin
                  </a>
                </DetailText>
              </TableCell>
              <TableCell>
                <DetailText>{objective.shortName}</DetailText>
              </TableCell>
              <TableCell>
                <DetailText>{objective.longName ?? '-'}</DetailText>
              </TableCell>
              <TableCell>
                <DetailText>
                  <CompactCheckbox checked={objective.inactive} disabled />
                </DetailText>
              </TableCell>
            </TableRow>,
          ])}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListObjectives;
