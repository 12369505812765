import { Formik, FormikConfig } from 'formik';
import React, { useContext, useMemo } from 'react';

type CustomFormContextType = {
  isDisabled?: boolean;
  isUpdating?: boolean;
  setUpdating: (v: boolean) => void;
  formName?: string;
  actionName?: string;
};

export const CustomFormContext = React.createContext<CustomFormContextType>({
  formName: 'Add Form',
  setUpdating: () => ({}),
});

type CustomFormProviderProps<T> = FormikConfig<T> & PartialBy<CustomFormContextType, 'setUpdating'>;

// eslint-disable-next-line @typescript-eslint/ban-types
export const CustomFormProvider = <T extends {}>({
  children,
  isDisabled,
  formName,
  actionName,
  isUpdating,
  setUpdating,
  ...props
}: React.PropsWithChildren<CustomFormProviderProps<T>>) => {
  const value = useMemo(
    () => ({
      isDisabled,
      formName,
      actionName,
      isUpdating,
      setUpdating: setUpdating ? setUpdating : () => ({}),
    }),
    [isDisabled, formName, actionName, isUpdating, setUpdating],
  );

  return (
    <CustomFormContext.Provider value={value}>
      <Formik validateOnChange={false} validateOnBlur={true} {...props}>
        {children}
      </Formik>
    </CustomFormContext.Provider>
  );
};
export function useFormContext() {
  const contexts = useContext(CustomFormContext);
  return contexts;
}
