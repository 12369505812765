import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Stack,
} from '@mui/material';
import {
  CustomFormProvider,
  useFormContext,
} from '@root/components/Form/Formik/CustomFormProvider';
import { FormikDatePicker } from '@root/components/Form/Formik/FormikDatePicker';
import { RateCardAsyncSelect } from '@root/components/Form/FormikSelect';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { FileWithDolarIcon } from '@root/components/Icons';
import { Link as SupLink } from '@root/components/Link';
import {
  ContractNode,
  ContractRateCardNode,
  useUpsertContractRateCardMutation,
} from '@root/typings/generated';
import { formatDate } from '@root/utils/format-date';
import { FormikProps, useFormikContext } from 'formik';
import { useState } from 'react';
import { contractRateCardInitValue, rateCardCodeYup } from '../_type';
import { routesWithValue } from '@root/utils/route';
import RoutePaths from '@root/pages/routes';
interface Props {
  contractRateCard?: ContractRateCardNode;
}
const UpsertContractRateCard = ({ contractRateCard }: Props) => {
  const { setUpdating } = useFormContext();
  const { values } = useFormikContext<ContractNode>();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
  };
  const [, upsertFn] = useUpsertContractRateCardMutation();
  const onSubmit = async ({ id, rateCard, dateFrom }: ContractRateCardNode) => {
    setUpdating(true);
    await upsertFn({
      data: {
        instance: id,
        rateCard: rateCard.id,
        dateFrom: formatDate(dateFrom),
        contract: values.id,
      },
    });
    setUpdating(false);
    setOpen(false);
  };

  return (
    <>
      {contractRateCard ? (
        <Link sx={{ cursor: 'pointer' }} underline="always" onClick={handleOpen}>
          ( Edit )
        </Link>
      ) : (
        <Button variant="contained" startIcon={<FileWithDolarIcon />} onClick={handleOpen}>
          Add Existing Rate Card
        </Button>
      )}

      {open && (
        <CustomFormProvider
          initialValues={{ ...contractRateCardInitValue, ...contractRateCard }}
          validationSchema={rateCardCodeYup}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, resetForm, isSubmitting }: FormikProps<ContractRateCardNode>) => (
            <Dialog open={open} fullWidth maxWidth="md">
              <Box p={2}>
                <DialogTitle>
                  {contractRateCard ? 'Update Rate Card Date' : 'Add Existing Rate Card'}
                </DialogTitle>
                <DialogContent sx={{ pb: 0 }}>
                  <Divider />
                  <Grid pb={2}>
                    <FormFieldGrid label="Rate Card:">
                      {contractRateCard ? (
                        <SupLink
                          label={contractRateCard.rateCard.title}
                          route={
                            routesWithValue(
                              ':id',
                              contractRateCard.rateCard.id,
                              RoutePaths.contracts.rate_cards[':id'],
                            ).root
                          }
                        />
                      ) : (
                        <RateCardAsyncSelect hideAddBtn name="rateCard" />
                      )}
                    </FormFieldGrid>
                    <FormFieldGrid label="Valid From:">
                      <FormikDatePicker name="dateFrom" size="small" />
                    </FormFieldGrid>
                  </Grid>
                  <Divider />
                </DialogContent>
                <DialogActions>
                  <Grid item width="100%" textAlign="center" mt={2}>
                    <Stack width="100%" spacing={3} direction="row" justifyContent="flex-end">
                      <Button
                        color="error"
                        variant="contained"
                        disabled={isSubmitting}
                        onClick={() => {
                          resetForm();
                          handleClose();
                        }}
                        startIcon={<DeleteIcon />}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        sx={{ px: 4 }}
                        startIcon={<SaveIcon />}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Save
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </DialogActions>
              </Box>
            </Dialog>
          )}
        </CustomFormProvider>
      )}
    </>
  );
};

export default UpsertContractRateCard;
