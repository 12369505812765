import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const simpleAccordionStyles = makeStyles((theme: Theme) => ({
  accordion: {
    border: `1px solid ${theme.palette.divider}`,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    background: 'none',
  },
  accordinDetail: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingLeft: 0,
    paddingRight: 0,
  },
}));
