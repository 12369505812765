import { AddCircleOutline } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { usePath } from '@root/providers/AuthProvider/modules/path';
import { useFormikContext } from 'formik';
import { PropsWithChildren } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import React from 'react';

interface Props {
  hideAddBtn?: boolean;
  createUrl?: string;
  goToUrl?: string;
}
export const BaseAsyncSelect = (props: PropsWithChildren<Props>) => {
  const { pushPath } = usePath();
  const { values } = useFormikContext();
  const { hideAddBtn, createUrl, goToUrl, children } = props;
  return (
    <Stack direction="row" justifyContent="flex-start">
      {children}
      {!hideAddBtn && !!createUrl && (
        <IconButton
          sx={{ alignItems: 'normal' }}
          aria-label="add"
          color="primary"
          onClick={() => pushPath(createUrl, values)}
        >
          <AddCircleOutline fontSize="medium" />
        </IconButton>
      )}
      {goToUrl && (
        <IconButton
          sx={{ alignItems: 'normal' }}
          data-testid="goto-button"
          aria-label="got to"
          color="primary"
          onClick={() => pushPath(goToUrl)}
        >
          <LaunchIcon fontSize="medium" />
        </IconButton>
      )}
    </Stack>
  );
};
