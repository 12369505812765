import { ListItem, ListItemText } from '@mui/material';
import FormikAsyncAutocomplete from '@root/components/Form/Formik/FormikAsyncAutocomplete';
import RoutePaths from '@root/pages/routes';
import { useListUsersQuery } from '@root/typings/generated';
import { BaseAsyncSelect } from './Base';
import { FormicAsyncSelect } from './_type';

export const UserAsyncSelect = (props: FormicAsyncSelect) => {
  const { name, size, orderBy, hideAddBtn, goToId, ...rest } = props;

  return (
    <BaseAsyncSelect
      hideAddBtn={hideAddBtn}
      createUrl={RoutePaths.people.create}
      goToUrl={goToId && `${RoutePaths.people.root}/${goToId}`}
    >
      <FormikAsyncAutocomplete
        renderOption={(props, option) => (
          <ListItem {...props} key={option.id}>
            <ListItemText primary={option?.name || option?.username || ''} />
          </ListItem>
        )}
        getOptionLabel={(option) => option?.name ?? ''}
        useQuery={useListUsersQuery}
        queryKey="users"
        {...rest}
        name={name}
        size={size || 'small'}
        orderBy={orderBy || 'name'}
      />
    </BaseAsyncSelect>
  );
};
