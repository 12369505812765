import DetailText from '@root/components/Detail/Text';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { RoleNode } from '@root/typings/generated';

interface DetailProps {
  data: RoleNode;
}
export const BasicDetails = ({ data }: DetailProps) => {
  return (
    <>
      <FormFieldGrid label="Name:">
        <DetailText>{data.name}</DetailText>
      </FormFieldGrid>
    </>
  );
};
