import { Link, LinkProps } from '@mui/material';
import DetailText from './Text';

interface DetailLinkProps extends LinkProps {
  label?: string;
}
const DetailLink = (props: DetailLinkProps) => {
  const { label, href } = props;
  return (
    <Link {...props} href={href || '#'} zIndex={100}>
      <DetailText>{label || href || 'No label'}</DetailText>
    </Link>
  );
};

export default DetailLink;
