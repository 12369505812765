import { Maybe } from '@root/typings/generated';

export interface ProjectSearchFilter {
  startDate?: Maybe<Date>;
  endDate?: Maybe<Date>;
}
export const initValue: ProjectSearchFilter = {
  startDate: null,
  endDate: null,
};
