import { Maybe } from '@root/typings/generated';
import DetailText, { DetailTextProps } from './Text';
import { format } from 'date-fns';

interface DetailDateProps extends DetailTextProps {
  date?: Maybe<Date>;
}
const DetailDate = (props: DetailDateProps) => {
  const { date, ...rest } = props;
  return <DetailText {...rest}>{date && format(new Date(date), 'dd/MM/yyyy')}</DetailText>;
};

export default DetailDate;
