import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Avatar, Box, Chip, Paper, Stack } from '@mui/material';
import { deepOrange, green } from '@mui/material/colors';
import { InnerLoading } from '@root/components/Loading/InnerLoading';
import { getProjectStatusColor } from '@root/constants/project-status-to-color';
import transferDate from '@root/graphql/input-transfer/transfer-date';
import {
  InternalProjectNode,
  ProjectStatusEnum,
  useSearchProjectsQuery,
} from '@root/typings/generated';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryBrushContainer,
  VictoryChart,
  VictoryLabel,
  VictoryTheme,
  VictoryZoomContainer,
} from 'victory';
import { DomainTuple } from 'victory-core';
import { VictoryStyleInterface } from 'victory-core/lib';
import { ProjectSearchFilter } from '../_type';
const ChartBody = () => {
  const { values } = useFormikContext<ProjectSearchFilter>();

  const [result] = useSearchProjectsQuery({
    variables: {
      offset: 0,
      first: 100,
      needDate: true,
      startDate: transferDate(values?.startDate ?? null),
      endDate: transferDate(values?.endDate ?? null),
    },
  });

  const [zoomDomain, setZoomDomain] = useState<{
    x: DomainTuple | undefined;
    y: DomainTuple | undefined;
  }>();
  const [statuses, setStatuses] = useState<{ [key in ProjectStatusEnum]: boolean }>({
    OVERDUE: false,
    IN_PROGRESS: false,
    UPCOMING: false,
    COMPLETED: false,
  });
  const counts: { [key in ProjectStatusEnum]: number } = {
    OVERDUE: 0,
    IN_PROGRESS: 0,
    UPCOMING: 0,
    COMPLETED: 0,
  };
  const [selectedDomain, setSelectedDomain] = useState<{
    x: DomainTuple | undefined;
    y: DomainTuple;
  }>();
  const handleBrush = (domain: { x: DomainTuple; y: DomainTuple }) =>
    setZoomDomain({ ...domain, x: zoomDomain?.x });
  const handleZoom = (domain: { x: DomainTuple; y: DomainTuple }) =>
    setSelectedDomain({ ...domain, x: selectedDomain?.x });
  const { fetching, data, error } = result;
  const rows = (data?.projects?.edges?.map((data) => data?.node) ?? []).filter(
    (data) => data?.startDate && data?.endDate,
  );
  const filteredRows = rows.filter((row) => {
    if (!row?.status) return false;
    counts[row?.status] += 1;
    if (Object.values(statuses).every((v) => !v)) return true;
    return statuses[row.status];
  });

  const chartData = filteredRows.map((project, index) => ({
    x: (index + 1) * 40,
    y: new Date(project?.startDate),
    y0: new Date(project?.endDate),
    project,
  }));
  useEffect(() => {
    setZoomDomain({ x: undefined, y: undefined });
  }, [values]);
  if (fetching || error) {
    return (
      <Box position="relative" marginTop={1}>
        {error ? 'Something went wrong' : <InnerLoading loading={true} opacity={0.2} />}
      </Box>
    );
  }
  const barStyle: VictoryStyleInterface = {
    data: {
      fill: ({ datum }) => {
        const project: InternalProjectNode = datum.project;
        return getProjectStatusColor(project.status);
      },
    },
  };
  const handleClick = (value: ProjectStatusEnum) => {
    setZoomDomain({ x: undefined, y: undefined });
    setStatuses({ ...statuses, [value.toString()]: !statuses[value] });
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        {Object.entries(ProjectStatusEnum)
          .filter((v) => counts[v[1]])
          .map(([key, value]) => {
            return (
              <Chip
                key={value}
                label={
                  <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Box>{key}</Box>
                    <Avatar
                      sx={{
                        backgroundColor: deepOrange[500],
                        width: 24,
                        height: 24,
                        fontSize: '15px !important',
                        color: 'white !important',
                      }}
                    >
                      {counts[value]}
                    </Avatar>
                  </Stack>
                }
                sx={{
                  backgroundColor: getProjectStatusColor(value),
                  color: 'white',
                  fontWeight: 'bold',
                }}
                onClick={() => handleClick(value)}
                avatar={statuses[value] ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                clickable
              />
            );
          })}
      </Stack>
      <Paper elevation={3}>
        <Stack spacing={0} marginTop={2}>
          <VictoryChart
            theme={VictoryTheme.material}
            width={1000}
            height={100}
            scale={{ y: 'time', x: 'linear' }}
            padding={{ top: 0, left: 50, right: 50, bottom: 35 }}
            domainPadding={{ x: [20, 20] }}
            containerComponent={
              <VictoryBrushContainer
                responsive={true}
                brushDimension="y"
                brushDomain={selectedDomain}
                onBrushDomainChange={handleBrush}
              />
            }
          >
            <VictoryAxis horizontal dependentAxis scale={{ y: 'time', x: 'linear' }} />
            <VictoryBar horizontal data={chartData} />
          </VictoryChart>

          <VictoryChart
            theme={VictoryTheme.material}
            width={1000}
            height={Math.max(500, 30 * filteredRows.length)}
            scale={{ y: 'time', x: 'linear' }}
            padding={{ top: 0, left: 50, right: 50, bottom: 35 }}
            domainPadding={{ x: [20, 20] }}
            containerComponent={
              <VictoryZoomContainer
                responsive={true}
                zoomDimension="y"
                zoomDomain={zoomDomain}
                onZoomDomainChange={handleZoom}
                allowPan
                allowZoom={false}
              />
            }
          >
            <VictoryAxis horizontal dependentAxis scale={{ y: 'time', x: 'linear' }} />
            <VictoryAxis
              crossAxis
              label="Today"
              scale={{ y: 'time', x: 'linear' }}
              style={{
                tickLabels: { fill: 'none' },
                axisLabel: { fontSize: 10, fill: green[500] },
              }}
              axisValue={new Date()}
            />
            <VictoryBar
              horizontal
              barWidth={25}
              data={chartData}
              style={barStyle}
              labels={({ datum }) =>
                `${datum.project.name} - $${datum?.project?.budget?.amountStr ?? '0'}`
              }
              labelComponent={
                <VictoryLabel
                  backgroundStyle={{ fill: 'pink' }}
                  backgroundPadding={[{ left: 5 }]}
                  dx={10}
                  style={{ color: 'white', fill: 'black', fontSize: '18px', padding: 2 }}
                />
              }
            />
          </VictoryChart>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default ChartBody;
