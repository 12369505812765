import { TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { useField } from './hooks';

export interface BaseProps {
  name: string;
}
export type FormikTextFieldProps = TextFieldProps & BaseProps;

export const FormikTextField: FC<FormikTextFieldProps> = (props: FormikTextFieldProps) => {
  const { name, label, helperText, ...rest } = props;
  const { field } = useField(name, helperText);

  const onBlur = (e: React.FocusEvent<IgnoredAny, Element>) => {
    field.onBlur(e);
    rest.onBlur && rest.onBlur(e);
  };
  return <TextField fullWidth id={name} label={label} {...rest} {...field} onBlur={onBlur} />;
};
