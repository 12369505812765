import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { PropsWithChild } from '@root/typings/child-type';
import { CSSProperties, ReactNode } from 'react';

interface OutlinedComponentProps {
  label: ReactNode;
  style?: CSSProperties | undefined;
  align?: 'left' | 'right' | 'top' | 'bottom' | 'center';
}
const useStyles = makeStyles<Theme, OutlinedComponentProps>(() => ({
  fieldset: {
    borderStyle: 'solid',
    borderRadius: '6px',
    borderColor: (props) => props.style?.borderColor ?? 'white',
    borderWidth: '1.9px',
  },
}));

const OutlinedComponent = (props: PropsWithChild & OutlinedComponentProps) => {
  const { children, label, align } = props;
  const classes = useStyles(props);
  return (
    <fieldset className={classes.fieldset}>
      <legend {...{ align: align ?? 'left' }}>
        <Box>{label}</Box>
      </legend>
      <Box>{children}</Box>
    </fieldset>
  );
};
export default OutlinedComponent;
