import FormSectionAccordion from '@root/components/Accordion/FormSectionAccordion';
import generateDetailViewWidget from '@root/components/Form/Widget/detail-view';
import RoutePaths from '@root/pages/routes';
import { useGetUserQuery, UserNode } from '@root/typings/generated';
import { BasicDetails } from './BasicDetails';
import { Projects } from './Projects';
import { ProviderIdentities } from './ProviderIdentities';

interface DetailProps {
  data: UserNode;
}
const Detail: React.FC<DetailProps> = ({ data }: DetailProps) => {
  return (
    <>
      <FormSectionAccordion title="Basic Details" expanded>
        <BasicDetails person={data} />
      </FormSectionAccordion>
      <FormSectionAccordion title="Provider Identities" expanded>
        <ProviderIdentities data={data} />
      </FormSectionAccordion>
      <FormSectionAccordion title="Projects" expanded>
        <Projects projectAssignments={data.projectAssignments} />
      </FormSectionAccordion>
    </>
  );
};

export default generateDetailViewWidget<UserNode>({
  name: 'Person',
  redirect: RoutePaths.people.list,
  editUrl: RoutePaths.people[':id'],
  getDataQuery: useGetUserQuery,
  component: Detail,
  paper: false,
});
