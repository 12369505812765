import DoneIcon from '@mui/icons-material/Done';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import DetailLink from '@root/components/Detail/Link';
import { Link } from '@root/components/Link';
import { TableHeader } from '@root/components/Table/TableHeader';
import RoutePaths from '@root/pages/routes';
import {
  BillingCodeNode,
  ComponentNode,
  Maybe,
  Node,
  RepositoryNode,
} from '@root/typings/generated';
import { routesWithValue } from '@root/utils/route';
import _ from 'lodash';

interface Props {
  billingCodes: BillingCodeNode[];
  components: ComponentNode[];
}

const BillingCodesAndComponents = (props: Props) => {
  const { billingCodes, components } = props;
  const getKey = (node: ComponentNode) => node.repository?.id ?? 'None';
  const repos: Array<RepositoryNode> = components.reduce(
    (pre, cur) => _.unionBy([...pre, ...(cur.repository ? [cur.repository] : [])], 'id'),
    [] as Array<RepositoryNode>,
  );
  const componentsByRepo: { [key: string]: Array<ComponentNode> } = components.reduce(
    (pre, cur) => {
      const curKey = getKey(cur);
      const preSm: Array<ComponentNode> = pre?.[curKey] ?? [];
      return {
        ...pre,
        [curKey]: _.uniqBy([...preSm, cur], 'id'),
      };
    },
    {} as { [key: string]: Array<ComponentNode> },
  );
  const hasItem = (codes: Maybe<Maybe<Node>[]> | undefined, code: Node) =>
    codes && code && codes.find((e) => e?.id === code.id);

  return (
    <TableContainer>
      <Table size="small">
        <TableHeader
          columns={[
            { id: 'Repo/Component', label: 'Repo/Component', sortable: false },
            ...(billingCodes?.map((code) => ({
              id: code.id,
              label: (
                <Link
                  label={code.tagLabel ?? ''}
                  route={
                    routesWithValue(':id', code.id, RoutePaths.projects.billing_codes[':id']).root
                  }
                />
              ),
              sortable: false,
            })) ?? []),
          ]}
        />
        <TableBody>
          {repos.map((repo) => {
            let hasCheckedByRepo: { [key: string]: boolean } = {};
            const componentsRows = componentsByRepo[repo.id].map((component) => (
              <TableRow key={`${repo.id}-${component.id}`}>
                <TableCell>
                  <Link
                    labelProps={{ pl: 3 }}
                    label={component.name}
                    route={
                      routesWithValue(':id', component.id, RoutePaths.products.components[':id'])
                        .root
                    }
                  />
                </TableCell>
                {billingCodes.map((billingCode) => {
                  const checked = !!hasItem(billingCode.components, component);
                  hasCheckedByRepo = {
                    ...hasCheckedByRepo,
                    [billingCode.id]: hasCheckedByRepo[billingCode.id] || checked,
                  };
                  return <TableCell key={billingCode.id}>{checked && <DoneIcon />}</TableCell>;
                })}
              </TableRow>
            ));
            return [
              <TableRow key={repo.id}>
                <TableCell>
                  <DetailLink target="_blank" href={repo.url} />
                </TableCell>
                {billingCodes.map((billingCode) => (
                  <TableCell key={billingCode.id}>
                    {hasCheckedByRepo[billingCode.id] && (
                      <DoneIcon color="primary" fontSize="large" />
                    )}
                  </TableCell>
                ))}
              </TableRow>,
              ...componentsRows,
            ];
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BillingCodesAndComponents;
