import { NavLink } from 'react-router-dom';
import { List, Box, Theme } from '@mui/material';
import { SidebarMenuItem } from '@root/typings/sidebar';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { cloneElement } from 'react';

export const useStyles = makeStyles<Theme, SidebarMenuItem>((theme: Theme) => ({
  navMenuItem: {
    padding: '0 16px 0 0',
    position: 'relative',
    '.authLayout .sidebar-content:not(:hover) &': {
      paddingLeft: 16,
    },
  },
  navMenuLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '9px 16px 9px 32px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    color: ({ style }) =>
      style === 'main' ? theme.sidebarPalette.textDarkColor : theme.sidebarPalette.textColor,
    textDecorationLine: 'none',
    '&:hover, &:focus': {
      color: theme.sidebarPalette.textDarkColor,
      backgroundColor: theme.sidebarPalette.navHoverBgColor,
      '& .icon-root, & .nav-text': {
        color: theme.sidebarPalette.textDarkColor,
      },
    },
    '&.active': {
      color: `${theme.sidebarPalette.textActiveColor} !important`,
      backgroundColor: `${theme.sidebarPalette.navActiveBgColor} !important`,
      '& .icon-root, & .nav-text': {
        color: theme.sidebarPalette.textActiveColor,
      },
      '&:hover, &:focus': {
        '& .nav-text, & .icon-root': {
          color: theme.sidebarPalette.textActiveColor,
        },
      },
    },
    '.authLayout .sidebar-content:not(:hover) &': {
      justifyContent: 'center',
      padding: 0,
      height: 40,
      width: 40,
      borderRadius: '50%',
      marginLeft: 4,
      marginRight: 4,
    },
  },
  navText: {
    flex: 1,
    fontSize: ({ style }) => (style === 'main' ? 'inherit' : 14),
    '.authLayout .sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
  iconRoot: {
    marginRight: 16,
    fontSize: 20,
    '.authLayout .sidebar-content:not(:hover) &': {
      marginRight: 0,
    },
  },
}));
const NavMenuItem = (props: SidebarMenuItem) => {
  const { name, icon, link } = props;
  const classes = useStyles(props);

  return (
    <List component="div" className={clsx(classes.navMenuItem, 'nav-menu-item')}>
      <NavLink to={link ?? ''} className={clsx(classes.navMenuLink, 'nav-menu-link')} exact>
        {icon
          ? cloneElement(icon, {
              className: clsx(classes.iconRoot, 'icon-root'),
            })
          : ''}
        <Box component="span" className={clsx(classes.navText, 'nav-text')}>
          {name}
        </Box>
      </NavLink>
    </List>
  );
};

export default NavMenuItem;
