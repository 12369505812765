import { Box, Grid } from '@mui/material';
import { CustomFormProvider } from '@root/components/Form/Formik/CustomFormProvider';
import ChartBody from './ChartBody';
import ChartFilter from './ChartFilter';
import { initValue } from './_type';

const ChartPage = () => {
  return (
    <Box>
      <CustomFormProvider
        initialValues={initValue}
        onSubmit={() => {
          //
        }}
      >
        <Grid
          width="100%"
          container
          alignItems="center"
          spacing={2}
          direction="column"
          justifyContent="center"
          mx="auto"
        >
          <Grid item width="100%">
            <ChartFilter />
          </Grid>
          <Grid item width="100%" height="100%">
            <ChartBody />
          </Grid>
        </Grid>
      </CustomFormProvider>
    </Box>
  );
};

export default ChartPage;
