import generateUpsertFormWidget from '@root/components/Form/Widget/upsert-form';
import RoutePaths from '@root/pages/routes';
import {
  UpsertProfitCentreDocument,
  UpsertProfitCentreMutation,
  UpsertProfitCentreMutationVariables,
  ProfitCentreNode,
  useGetProfitCentreQuery,
} from '@root/typings/generated';
import Form from './form';
import { initialValues, validationSchema } from './_type';

const UpsertProfitCentreFormWidget = generateUpsertFormWidget<
  ProfitCentreNode,
  UpsertProfitCentreMutationVariables,
  UpsertProfitCentreMutation
>({
  name: 'Profit Centre',
  redirect: RoutePaths.contracts.profit_centres.list,
  viewUrl: RoutePaths.contracts.profit_centres[':id'],
  initialValues,
  validationSchema,
  mutationDoc: UpsertProfitCentreDocument,
  getDataQuery: useGetProfitCentreQuery,
});

const UpsertProfitCentre = () => {
  return (
    <UpsertProfitCentreFormWidget
      mapping={({ id, name, parent, manager }) => ({
        data: {
          instance: id,
          name,
          parent: parent?.id ?? null,
          manager: manager?.id ?? null,
        },
      })}
      basicDetail={<Form />}
    >
      <></>
    </UpsertProfitCentreFormWidget>
  );
};

export default UpsertProfitCentre;
