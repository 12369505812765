import { Grid } from '@mui/material';
import { IdentityNode, UserNode } from '@root/typings/generated';

interface Props {
  data: UserNode;
}
export const ProviderIdentities: React.FC<Props> = ({ data }: Props) => {
  return (
    <>
      {data?.identities?.map((identity: IdentityNode) => (
        <Grid item container key={identity.id} spacing={1} py={1}>
          <Grid item xs={5}>
            {identity.context?.name}
          </Grid>
          <Grid item xs={6}>
            {identity.primaryIdentifier}
          </Grid>
        </Grid>
      ))}
    </>
  );
};
