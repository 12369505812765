import { FormikTextField } from '@root/components/Form/Formik/FormikTextField';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { GridSpanObject } from '@root/typings/grid';
export const SPANS: GridSpanObject = {
  Role: {
    xs: 4,
  },
  Unit: {
    xs: 3,
  },
  Price: {
    xs: 5,
  },
};
const Form = () => {
  return (
    <>
      <FormFieldGrid label="Title:">
        <FormikTextField name="title" size="small" />
      </FormFieldGrid>
    </>
  );
};

export default Form;
