import TreeItem from '@mui/lab/TreeItem';
import { Grid } from '@mui/material';
import { EntityNode, Maybe, ProfitCentreLayer } from '@root/typings/generated';
import ActionItem from './ActionItem';
import RegionTree from './RegionTree';

interface PropsType {
  entity: Maybe<EntityNode>;
}

const EntityTree = (props: PropsType) => {
  const { entity } = props;
  return (
    <>
      <TreeItem
        nodeId={entity?.id ?? 'entity'}
        label={
          <Grid container spacing={1} justifyContent="space-between" alignItems="center">
            <Grid item>{entity?.name}</Grid>
            <Grid item>
              <ActionItem id={entity?.id ?? ''} layer={ProfitCentreLayer.Entity} />
            </Grid>
          </Grid>
        }
        className="expanded"
      >
        {entity?.regions?.map((region) => (
          <RegionTree key={region?.id} region={region} />
        ))}
      </TreeItem>
    </>
  );
};

export default EntityTree;
