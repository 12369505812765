import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { BrowserRouter as Router } from 'react-router-dom';
import LayoutContextProvider from './layouts/Context';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from './utils/SnackbarConfigurator';
import { Main } from './pages/Main';
import AuthProvider from './providers/AuthProvider';
import { Provider } from 'urql';
import { client } from './graphql/client';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
        <SnackbarUtilsConfigurator />
        <Provider value={client}>
          <AuthProvider>
            <LayoutContextProvider>
              <CssBaseline />
              <Router>
                <Main />
              </Router>
            </LayoutContextProvider>
          </AuthProvider>
        </Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
