import FormSectionAccordion from '@root/components/Accordion/FormSectionAccordion';
import generateDetailViewWidget from '@root/components/Form/Widget/detail-view';
import RoutePaths from '@root/pages/routes';
import { RoleNode, useGetRoleQuery } from '@root/typings/generated';
import { BasicDetails } from './BasicDetails';
import People from './People';
import Projects from './Projects';

interface DetailProps {
  data: RoleNode;
}
const Detail: React.FC<DetailProps> = ({ data }: DetailProps) => {
  return (
    <>
      <FormSectionAccordion title="Basic Details">
        <BasicDetails data={data} />
      </FormSectionAccordion>
      <FormSectionAccordion title="People">
        <People data={data} />
      </FormSectionAccordion>
      <FormSectionAccordion title="Projects">
        <Projects data={data} />
      </FormSectionAccordion>
    </>
  );
};

export default generateDetailViewWidget<RoleNode>({
  name: 'Role',
  redirect: RoutePaths.contracts.roles.list,
  editUrl: RoutePaths.contracts.roles[':id'],
  getDataQuery: useGetRoleQuery,
  component: Detail,
  paper: false,
});
