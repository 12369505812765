import { IdentityNode, ProjectRoleAssignmentNode, UserNode } from '@root/typings/generated';
import * as Yup from 'yup';
import { NullID, NullInstance } from '../../../../yup/initial-value';

export const identityInitValue: IdentityNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  context: NullInstance,
  primaryIdentifier: '',
  entity: NullInstance,
};
export const peopleAssignmentInitialValue: ProjectRoleAssignmentNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  person: null,
  project: null,
  role: null,
};
export const peopleAssignmentYup = Yup.object({
  role: Yup.object().nullable().required('Person is required'),
  project: Yup.object().nullable().required('Project is required'),
});

export const identitiesYup = Yup.object({
  context: Yup.object().nullable().required('Provider is required'),
  primaryIdentifier: Yup.string().defined('Identifier is required'),
});
export const initialValues: UserNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  name: '',
  username: '',
  hub: null,
  identities: [],
  email: '',
  firstName: '',
  inactive: false,
  isRobot: false,
  lastName: '',
  projectAssignments: [],
};

export const validationSchema = Yup.object({
  name: Yup.string().defined('Name is required'),
  hub: Yup.object().nullable().required('Hub is required'),
});
