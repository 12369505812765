import { ListItem, ListItemText } from '@mui/material';
import FormikAsyncAutocomplete from '@root/components/Form/Formik/FormikAsyncAutocomplete';
import { useListContextsQuery } from '@root/typings/generated';
import { BaseAsyncSelect } from './Base';
import { FormicAsyncSelect } from './_type';

export const ProviderAsyncSelect = (props: FormicAsyncSelect) => {
  const { name, size, orderBy, ...rest } = props;

  return (
    <BaseAsyncSelect>
      <FormikAsyncAutocomplete
        renderOption={(props, option) => (
          <ListItem {...props} key={option.id}>
            <ListItemText primary={option?.name ?? ''} />
          </ListItem>
        )}
        getOptionLabel={(option) => option?.name ?? ''}
        useQuery={useListContextsQuery}
        queryKey="contexts"
        {...rest}
        name={name}
        size={size || 'small'}
        orderBy={orderBy || 'name'}
      />
    </BaseAsyncSelect>
  );
};
