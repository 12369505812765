import { FC } from 'react';
import BudgetInput, { BudgetInputProps } from '../Base/BudgetInput';
import FormikFormControl from './FormikFormControl';
import { useField } from './hooks';
export type FormikBudgetInputProps = BudgetInputProps & FormikBaseProps;

const FormikBudgetInput: FC<FormikBudgetInputProps> = (props: FormikBudgetInputProps) => {
  const { name, label, helperText, ...rest } = props;
  const { field, setValue } = useField(name, helperText);

  return (
    <FormikFormControl fullWidth name={`${name}.amount`} label={label} helperText={helperText}>
      <BudgetInput id={name} label={label} {...rest} {...field} onChange={(_, v) => setValue(v)} />
    </FormikFormControl>
  );
};

export default FormikBudgetInput;
