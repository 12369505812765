import { createSvgIcon } from '@mui/material';

export const FileWithDolarIcon = createSvgIcon(
  <svg viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4834 3.63598L10.4249 0.523242C10.0968 0.189258 9.65198 0 9.18932 0H1.74964C0.783854 0.00371094 0 0.801563 0 1.78459V17.2191C0 18.2021 0.783854 19 1.74964 19H12.2474C13.2136 19 14 18.2021 14 17.2191V4.89732C14 4.42641 13.8115 3.96996 13.4834 3.63598V3.63598ZM9.33151 1.92932L12.1056 4.7526H9.33151V1.92932V1.92932ZM12.25 17.2191H1.74964V1.78459H7.58187V5.64322C7.58187 6.13678 7.97198 6.53348 8.45687 6.53348H12.25V17.2191ZM7.58333 8.01562C7.58333 7.8516 7.45281 7.71875 7.29167 7.71875H6.70833C6.54719 7.71875 6.41667 7.8516 6.41667 8.01562V8.9107C5.55552 8.93408 4.86099 9.6733 4.86099 10.5832C4.86099 11.3243 5.33422 11.9863 6.01234 12.1934L7.65297 12.6944C7.84109 12.7519 7.97271 12.946 7.97271 13.1668C7.97271 13.4366 7.77948 13.6562 7.5425 13.6562H6.51766C6.35141 13.6562 6.19099 13.6084 6.05026 13.5182C5.93214 13.4429 5.78193 13.4473 5.68094 13.5453L5.25255 13.9613C5.12385 14.0863 5.13115 14.3031 5.27333 14.4118C5.6051 14.6653 6.00542 14.8115 6.41703 14.833V15.7344C6.41703 15.8984 6.54755 16.0312 6.7087 16.0312H7.29203C7.45318 16.0312 7.5837 15.8984 7.5837 15.7344V14.8393C8.44484 14.8159 9.13937 14.0771 9.13937 13.1668C9.13937 12.4257 8.66615 11.7637 7.98802 11.5566L6.3474 11.0556C6.15927 10.9981 6.02766 10.804 6.02766 10.5832C6.02766 10.3134 6.22089 10.0938 6.45786 10.0938H7.48271C7.64896 10.0938 7.80937 10.1416 7.9501 10.2318C8.06823 10.3071 8.21844 10.3027 8.31943 10.2047L8.74781 9.78871C8.87651 9.66365 8.86922 9.44693 8.72703 9.3382C8.39526 9.08475 7.99495 8.93853 7.58333 8.91701V8.01562ZM3.20833 4.15625H6.125C6.28615 4.15625 6.41667 4.0234 6.41667 3.85938V3.26562C6.41667 3.1016 6.28615 2.96875 6.125 2.96875H3.20833C3.04719 2.96875 2.91667 3.1016 2.91667 3.26562V3.85938C2.91667 4.0234 3.04719 4.15625 3.20833 4.15625ZM6.41667 6.23438V5.64062C6.41667 5.4766 6.28615 5.34375 6.125 5.34375H3.20833C3.04719 5.34375 2.91667 5.4766 2.91667 5.64062V6.23438C2.91667 6.3984 3.04719 6.53125 3.20833 6.53125H6.125C6.28615 6.53125 6.41667 6.3984 6.41667 6.23438Z"
      fill="white"
    />
  </svg>,
  'FileWithDolarIcon',
);
