import { CompactCheckbox } from '@root/components/Checkbox/CompactCheckbox';
import DetailLink from '@root/components/Detail/Link';
import DetailText from '@root/components/Detail/Text';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { REACT_APP_API_URL } from '@root/constants/env';
import { ComponentNode } from '@root/typings/generated';

interface DetailProps {
  component: ComponentNode;
}

export const BasicDetails: React.FC<DetailProps> = ({ component }: DetailProps) => {
  return (
    <>
      <FormFieldGrid label="Compact ID:">
        <DetailText>
          {component.compactId}{' '}
          <a href={`${REACT_APP_API_URL}/admin/architecture/component/${component.realId}/change/`}>
            admin
          </a>
        </DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Name:">
        <DetailText>{component.name}</DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Repository URL:">
        <DetailLink href={component.repository?.url} target="_blank" />
      </FormFieldGrid>
      <FormFieldGrid label="Inactive:">
        <CompactCheckbox checked={component.inactive} disabled />
      </FormFieldGrid>
    </>
  );
};
