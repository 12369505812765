import { ListItem, ListItemText } from '@mui/material';
import FormikAsyncAutocomplete from '@root/components/Form/Formik/FormikAsyncAutocomplete';
import {
  ListProductObjectivesQueryVariables,
  ProductObjectiveNode,
  useListProductObjectivesQuery,
} from '@root/typings/generated';
import { BaseAsyncSelect } from './Base';
import { FormicAsyncSelectWithVar } from './_type';

export const ProductObjectiveAsyncSelect = (
  props: FormicAsyncSelectWithVar<ListProductObjectivesQueryVariables>,
) => {
  const { name, size, orderBy, ...rest } = props;
  const getLabel = (option: ProductObjectiveNode) =>
    option?.shortName || option?.longName || option?.name || '';
  return (
    <BaseAsyncSelect>
      <FormikAsyncAutocomplete
        queryKey="productObjectives"
        getOptionLabel={getLabel}
        useQuery={useListProductObjectivesQuery}
        renderOption={(props, option) => (
          <ListItem {...props} key={option.id}>
            <ListItemText primary={getLabel(option)} />
          </ListItem>
        )}
        {...rest}
        name={name}
        size={size || 'small'}
        orderBy={orderBy || 'short_name'}
      />
    </BaseAsyncSelect>
  );
};
