import React from 'react';
import { useState } from 'react';
import {
  /*Button, Grid,*/ IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useFormikContext } from 'formik';

import DetailText from '@root/components/Detail/Text';
// import { ComponentAsyncSelect } from '@root/components/Form/FormikSelect';
import { ComponentSelect } from '@root/components/Form/Select/ComponentSelect';
import { TableHeader } from '@root/components/Table/TableHeader';
import {
  ProductNode,
  ComponentNode,
  useAddComponentToProductMutation,
  useRemoveComponentFromProductMutation,
} from '@root/typings/generated';

// interface ComponentTableProps {
//   product: ProductNode;
// }
const ComponentsTable = () => {
  const columnHeaders = [
    { id: 'name', label: 'Name' },
    { id: 'repo', label: 'Repository' },
  ];
  columnHeaders.push({ id: 'remove', label: 'Remove' });

  const { values: product } = useFormikContext<ProductNode>();

  console.log('1'); // eslint-disable-line no-console
  console.log(product.components); // eslint-disable-line no-console
  const [components, setComponents] = useState(product.components); //[] as ComponentNode[]);
  if (product.components !== components) {
    setComponents(product.components);
  }

  // handle adding a new component to the product
  const [componentToAdd, setComponentToAdd] = useState<ComponentNode>();
  const [, addComponentFn] = useAddComponentToProductMutation();
  const onAdd = async () => {
    console.log('add'); // eslint-disable-line no-console
    console.log(componentToAdd); // eslint-disable-line no-console
    if (componentToAdd) {
      const { error: err, data } = await addComponentFn({
        data: {
          instance: product.id,
          component: componentToAdd.id,
        },
      });
      console.log('get result'); // eslint-disable-line no-console
      console.log(data); // eslint-disable-line no-console
      if (!err && data?.addComponentToProduct?.components) {
        product.components = data.addComponentToProduct.components; // todo: hack to avoid conditional setComponents call above
        setComponents(data.addComponentToProduct.components);
      } // else the graphql client will display an error message for us
    }
  };

  const [, removeComponentFn] = useRemoveComponentFromProductMutation();
  const onRemove = (component: ComponentNode) => async () => {
    console.log('remove'); // eslint-disable-line no-console
    console.log(component.name); // eslint-disable-line no-console
    if (component) {
      const { error: err, data } = await removeComponentFn({
        data: {
          instance: product.id,
          component: component.id,
        },
      });
      console.log('get result'); // eslint-disable-line no-console
      console.log(data); // eslint-disable-line no-console
      if (!err && data?.removeComponentFromProduct?.components) {
        product.components = data.removeComponentFromProduct.components; // todo: hack to avoid conditional setComponents call above
        setComponents(data.removeComponentFromProduct.components);
      } // else the graphql client will display an error message for us
    }
    // if(componentToAdd){
    //   setComponents(components => [...components, componentToAdd]);
    // }
    // setUpdating(true);
    // const body = await mapping(v);
    // const { error: err, data } = await upsert(body);
    // setUpdating(false);
    // if (!err && name) {
    //   SnackbarUtils.success(`${name} was ${isUpdating ? 'Updated' : 'Created'} successfully`, {
    //     autoHideDuration: 3000,
    //     action: (snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />,
    //   });
    //   if (!popPath() && !isUpdating) {
    //     redirectPath(data ? (Object.values(data as IgnoredAny) as IgnoredAny)?.[0]?.data?.id : null);
    //   }
    // }
  };

  return (
    <Stack>
      <ComponentSelect
        getOptionDisabled={(option) => {
          return components.some((v) => v?.id === option?.id);
        }}
        onChange={(_, v) => setComponentToAdd(v)}
      />
      {/*      <ComponentAsyncSelect
        name="addComponent"
        hideAddBtn
        multiple={false}
        getOptionDisabled={(option) => {
          return components.some((v) => v?.id === option?.id);
        }}
        defaultValue=""
        onChange={(_, v) => setComponentToAdd(v)}
      />
*/}{' '}
      <Stack width="100%" spacing={3} direction="row" justifyContent="flex-end">
        <IconButton aria-label="add" color="primary" onClick={onAdd}>
          <AddCircleOutlineIcon />
        </IconButton>
      </Stack>
      <TableContainer>
        <Table size="small">
          <TableHeader columns={columnHeaders} />
          <TableBody>
            {components.map((component) => [
              <TableRow key={component.id}>
                <TableCell>
                  <DetailText>{component.name}</DetailText>
                </TableCell>
                <TableCell>
                  <DetailText>{component.repository?.url ?? '-'}</DetailText>
                </TableCell>
                {true && (
                  <TableCell>
                    <IconButton aria-label="remove" color="primary" onClick={onRemove(component)}>
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>,
            ])}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default ComponentsTable;
