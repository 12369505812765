import { Table, TableBody, TableCell, TableContainer, Stack } from '@mui/material';
import { Link } from '@root/components/Link';
import { TableHeader } from '@root/components/Table/TableHeader';
import { TableRow } from '@root/components/Table/TableRow';
import { RoleNode } from '@root/typings/generated';
import { useFormikContext } from 'formik';
import UpsertProjectRoleAssignment from './UpsertProjectRoleAssignment';
import { routesWithValue } from '@root/utils/route';
import RoutePaths from '@root/pages/routes';
import DetailText from '@root/components/Detail/Text';

const People = () => {
  const { values: data } = useFormikContext<RoleNode>();

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHeader
            columns={[
              {
                label: 'Person',
                sortable: false,
              },
              {
                label: 'Hub',
                sortable: false,
              },
              {
                label: 'Region',
                sortable: false,
              },
            ]}
          />
          <TableBody>
            {data?.people?.map((person) => [
              <TableRow key={person.id}>
                <TableCell>
                  <Link
                    label={person.name ?? person.firstName}
                    route={routesWithValue(':id', person.id, RoutePaths.people[':id']).root}
                  />
                </TableCell>
                <TableCell>
                  {person.hub ? (
                    <Link
                      label={person.hub.name}
                      route={
                        routesWithValue(
                          ':id',
                          person.hub.id,
                          RoutePaths.contracts.profit_centres[':id'],
                        ).root
                      }
                    />
                  ) : (
                    <DetailText>None</DetailText>
                  )}
                </TableCell>
                <TableCell>
                  {person.hub?.region ? (
                    <Link
                      label={person.hub.region.name}
                      route={
                        routesWithValue(
                          ':id',
                          person.hub.region.id,
                          RoutePaths.contracts.profit_centres[':id'],
                        ).root
                      }
                    />
                  ) : (
                    <DetailText>None</DetailText>
                  )}
                </TableCell>
              </TableRow>,
            ])}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack mt={2} justifyContent="flex-end" direction="row" spacing={2}>
        <UpsertProjectRoleAssignment />
      </Stack>
    </>
  );
};

export default People;
