import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { CompactCheckbox } from '@root/components/Checkbox/CompactCheckbox';
import DetailText from '@root/components/Detail/Text';
import { Link } from '@root/components/Link';
import { TableHeader } from '@root/components/Table/TableHeader';
import RoutePaths from '@root/pages/routes';
import { ComponentNode } from '@root/typings/generated';
import { routesWithValue } from '@root/utils/route';

interface Props {
  components: ComponentNode[];
}

const ListComponents: React.FC<Props> = ({ components }: Props) => {
  const columnHeaders = [
    { id: 'name', label: 'Name' },
    { id: 'repo', label: 'Repository' },
    { id: 'inactive', label: 'Is Inactive' },
  ];

  return (
    <TableContainer>
      <Table size="small">
        <TableHeader columns={columnHeaders} />
        <TableBody>
          {components.map((component) => [
            <TableRow key={component.id}>
              <TableCell>
                <DetailText>
                  <Link
                    label={component.name}
                    route={
                      routesWithValue(':id', component.id, RoutePaths.products.components[':id'])
                        .root
                    }
                  />
                </DetailText>
              </TableCell>
              <TableCell>
                <DetailText>{component.repository?.url ?? '-'}</DetailText>
              </TableCell>
              <TableCell>
                <DetailText>
                  <CompactCheckbox checked={component.inactive} disabled />
                </DetailText>
              </TableCell>
            </TableRow>,
          ])}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListComponents;
