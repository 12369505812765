import React from 'react';
import * as yup from 'yup';
import { Alert, Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormikTextField } from '@root/components/Form/Formik/FormikTextField';
import { CustomFormProvider } from '@root/components/Form/Formik/CustomFormProvider';
import { FormikHelpers, FormikProps } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAuth } from '../../../providers/AuthProvider/modules/auth';

const validationSchema = yup.object({
  username: yup.string().required('Username is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

interface LoginFormSchema {
  username: string;
  password: string;
}
const initialValues: LoginFormSchema = {
  username: '',
  password: '',
};
const LoginPage = () => {
  const { login, logging } = useAuth();
  const onSubmitHandler = async (
    data: LoginFormSchema,
    { setSubmitting, setStatus }: FormikHelpers<LoginFormSchema>,
  ) => {
    try {
      await login(data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setStatus(error?.message ?? 'Something went wrong');
    }
    setSubmitting(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <CustomFormProvider
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandler}
        >
          {({ handleSubmit, isSubmitting, status }: FormikProps<LoginFormSchema>) => (
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              {status && <Alert severity="error">{status}</Alert>}
              <FormikTextField name="username" label="Username" margin="normal" />
              <FormikTextField name="password" label="Password" margin="normal" type="password" />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={isSubmitting || logging}
              >
                Sign In
              </LoadingButton>
            </Box>
          )}
        </CustomFormProvider>
      </Box>
    </Container>
  );
};

export default LoginPage;
