import { CurrencyCodes } from '@root/constants/currencies';
import { MoneyInput } from '@root/typings/generated';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const NullInstance = null as any;
export const NullID = null as any;

export const EmptyMoney: MoneyInput = {
  amount: 0,
  currency: CurrencyCodes.AUD,
};
