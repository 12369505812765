import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useBlockStyles = makeStyles((theme: Theme) => ({
  block: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));
