import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useInputStyles = makeStyles<Theme>({
  searchInput: {
    borderRadius: 30,
    '& input': {
      padding: '8px 14px',
    },
  },
});
