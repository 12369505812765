import { ReactNode } from 'react';
import { Switch as BaseSwitch, SwitchProps as BaseSwitchProps, Route } from 'react-router-dom';
import { NotFound } from './NotFound';

interface SwitchProps extends BaseSwitchProps {
  notfound?: ReactNode;
}
const Switch = (props: SwitchProps) => {
  const { children, notfound, ...rest } = props;
  return (
    <BaseSwitch {...rest}>
      {!!children && children}
      {notfound ? notfound : <Route component={NotFound} />}
    </BaseSwitch>
  );
};

export default Switch;
