import { Grid } from '@mui/material';
import DetailMoney from '@root/components/Detail/Money';
import DetailText from '@root/components/Detail/Text';
import generateDetailViewWidget from '@root/components/Form/Widget/detail-view';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import RoutePaths from '@root/pages/routes';
import { ClientNode, useGetClientQuery } from '@root/typings/generated';
import ClientContractsTable from './ClientContractsTable';
import { CompactCheckbox } from '@root/components/Checkbox/CompactCheckbox';

const Detail: React.FC<{ data: ClientNode }> = ({ data }: { data: ClientNode }) => {
  return (
    <>
      <FormFieldGrid label="Name:">
        <DetailText>{data.name}</DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Inactive:">
        <CompactCheckbox checked={data.inactive} disabled />
      </FormFieldGrid>
      <Grid item mt={2}>
        <DetailText variant="h5">Client Contracts</DetailText>
      </Grid>
      <Grid item>
        <ClientContractsTable contracts={data.contracts} />
      </Grid>
      <Grid item>
        <DetailText>
          Total client budget: <DetailMoney data={data.totalBudget} />
        </DetailText>
      </Grid>
    </>
  );
};

export default generateDetailViewWidget<ClientNode>({
  name: 'Client',
  redirect: RoutePaths.contracts.clients.list,
  editUrl: RoutePaths.contracts.clients[':id'],
  getDataQuery: useGetClientQuery,
  component: Detail,
});
