import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
} from '@mui/material';
import {
  CustomFormProvider,
  useFormContext,
} from '@root/components/Form/Formik/CustomFormProvider';
import { ProjectAsyncSelect, UserAsyncSelect } from '@root/components/Form/FormikSelect';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { Link } from '@root/components/Link';
import {
  ContractRateCardNode,
  ProjectRoleAssignmentNode,
  RoleNode,
  useUpsertProjectRoleAssignmentMutation,
} from '@root/typings/generated';
import { FormikProps, useFormikContext } from 'formik';
import { useState } from 'react';
import { peopleAssignmentInitialValue, peopleAssignmentYup } from './_type';
import { routesWithValue } from '@root/utils/route';
import RoutePaths from '@root/pages/routes';

interface Props {
  data?: ProjectRoleAssignmentNode;
}
const UpsertProjectRoleAssignment = ({ data }: Props) => {
  const { setUpdating } = useFormContext();
  const { values } = useFormikContext<RoleNode>();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
  };
  const [, upsertFn] = useUpsertProjectRoleAssignmentMutation();
  const onSubmit = async ({ id, project, person }: ProjectRoleAssignmentNode) => {
    setUpdating(true);
    await upsertFn({
      data: {
        instance: id,
        role: values?.id,
        project: project?.id,
        person: person?.id,
      },
    });
    setUpdating(false);
    setOpen(false);
  };

  return (
    <>
      {data ? (
        <Button variant="text" onClick={handleOpen} color="error">
          Edit
        </Button>
      ) : (
        <Button variant="contained" startIcon={<PersonOutlineOutlinedIcon />} onClick={handleOpen}>
          Add Existing Person to Role
        </Button>
      )}

      {open && (
        <CustomFormProvider
          initialValues={{ ...peopleAssignmentInitialValue, ...data }}
          validationSchema={peopleAssignmentYup}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, resetForm, isSubmitting }: FormikProps<ContractRateCardNode>) => (
            <Dialog open={open} fullWidth maxWidth="md">
              <Box p={2}>
                <DialogTitle>
                  {data ? 'Update Project Role Assignment' : 'Add Existing Person to Role'}
                </DialogTitle>
                <DialogContent sx={{ pb: 0 }}>
                  <Divider />
                  <Grid pb={2}>
                    <FormFieldGrid label="Role:">
                      <Link
                        label={values.name}
                        route={routesWithValue(':id', values.id, RoutePaths.people[':id']).root}
                      />
                    </FormFieldGrid>
                    <FormFieldGrid label="Project:">
                      <ProjectAsyncSelect name="project" size="small" />
                    </FormFieldGrid>
                    <FormFieldGrid label="User:">
                      <UserAsyncSelect name="person" size="small" />
                    </FormFieldGrid>
                  </Grid>
                  <Divider />
                </DialogContent>
                <DialogActions>
                  <Grid item width="100%" textAlign="center" mt={2}>
                    <Stack width="100%" spacing={3} direction="row" justifyContent="flex-end">
                      <Button
                        color="error"
                        variant="contained"
                        disabled={isSubmitting}
                        onClick={() => {
                          resetForm();
                          handleClose();
                        }}
                        startIcon={<DeleteIcon />}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        sx={{ px: 4 }}
                        startIcon={<SaveIcon />}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Save
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </DialogActions>
              </Box>
            </Dialog>
          )}
        </CustomFormProvider>
      )}
    </>
  );
};

export default UpsertProjectRoleAssignment;
