import { Route } from 'react-router-dom';
import { AuthPages } from './Auth';
import { NoAuthPages } from './NoAuth';
import RoutePaths from './routes';
import Switch from '@root/components/RouterDom/Switch';
import { useAuthContext } from '../providers/AuthProvider/context';
import { CircularProgress, Grid } from '@mui/material';

export const Main = () => {
  const { user } = useAuthContext();
  if (user === undefined) {
    return (
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={3}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }
  return (
    <Switch>
      {user ? (
        <Route path={RoutePaths.root}>
          <AuthPages />
        </Route>
      ) : (
        <Route path={RoutePaths.root}>
          <NoAuthPages />
        </Route>
      )}
    </Switch>
  );
};
