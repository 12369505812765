import FormSectionAccordion from '@root/components/Accordion/FormSectionAccordion';
import generateDetailViewWidget from '@root/components/Form/Widget/detail-view';
import RoutePaths from '@root/pages/routes';
import { BillingCodeNode, useGetBillingCodeQuery } from '@root/typings/generated';
import { BasicDetails } from './BasicDetails';
import { Components } from './Components';

interface DetailProps {
  data: BillingCodeNode;
}
const Detail: React.FC<DetailProps> = ({ data }: DetailProps) => {
  return (
    <>
      <FormSectionAccordion title="Basic Details">
        <BasicDetails billingCode={data} />
      </FormSectionAccordion>
      <FormSectionAccordion title="Components">
        <Components data={data} />
      </FormSectionAccordion>
    </>
  );
};

export default generateDetailViewWidget<BillingCodeNode>({
  name: 'Billing Code',
  redirect: RoutePaths.projects.billing_codes.list,
  editUrl: RoutePaths.projects.billing_codes[':id'],
  getDataQuery: useGetBillingCodeQuery,
  component: Detail,
  paper: false,
});
