import FormikAsyncAutocomplete from '@root/components/Form/Formik/FormikAsyncAutocomplete';
import RoutePaths from '@root/pages/routes';
import { useListClientsQuery } from '@root/typings/generated';
import { BaseAsyncSelect } from './Base';
import { FormicAsyncSelect } from './_type';

export const ClientAsyncSelect = (props: FormicAsyncSelect) => {
  const { name, size, orderBy, hideAddBtn, goToId, ...rest } = props;

  return (
    <BaseAsyncSelect
      hideAddBtn={hideAddBtn}
      createUrl={RoutePaths.contracts.clients.create}
      goToUrl={goToId && `${RoutePaths.contracts.clients.root}/${goToId}`}
    >
      <FormikAsyncAutocomplete
        name={name}
        getOptionLabel={(option) => option?.name ?? ''}
        useQuery={useListClientsQuery}
        queryKey="clients"
        {...rest}
        size={size || 'small'}
        orderBy={orderBy || 'name'}
      />
    </BaseAsyncSelect>
  );
};
