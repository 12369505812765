import generateUpsertFormWidget from '@root/components/Form/Widget/upsert-form';
import RoutePaths from '@root/pages/routes';
import {
  UpsertComponentDocument,
  UpsertComponentMutation,
  UpsertComponentMutationVariables,
  useGetComponentQuery,
} from '@root/typings/generated';
import BasicDetails from './BasicDetails';
import { ExtendComponentNode, initialValues, validationSchema } from './_type';

const UpsertComponentFormWidget = generateUpsertFormWidget<
  ExtendComponentNode,
  UpsertComponentMutationVariables,
  UpsertComponentMutation
>({
  name: 'Component',
  redirect: RoutePaths.products.components.list,
  viewUrl: RoutePaths.products.components[':id'],
  initialValues,
  validationSchema,
  mutationDoc: UpsertComponentDocument,
  getDataQuery: useGetComponentQuery,
});

const UpsertComponent = () => {
  return (
    <UpsertComponentFormWidget
      mapping={({ name, repository, id, product, inactive }) => ({
        data: {
          instance: id,
          name,
          inactive,
          repository: { instance: repository?.id, url: repository?.url ?? '' },
          product: product?.id,
        },
      })}
      basicDetail={<BasicDetails />}
    ></UpsertComponentFormWidget>
  );
};

export default UpsertComponent;
