import { Redirect, Route } from 'react-router-dom';
import RoutePaths from '@root/pages/routes';
import DefaultLayout from '@root/layouts/Default';
import { SignupPage } from './Signup';
import LoginPage from './Login';
import Switch from '@root/components/RouterDom/Switch';

export const NoAuthPages = () => {
  return (
    <DefaultLayout>
      <Switch notfound={<Redirect to={RoutePaths.login} />}>
        <Route path={RoutePaths.login}>
          <LoginPage />
        </Route>
        <Route path={RoutePaths.signup}>
          <SignupPage />
        </Route>
      </Switch>
    </DefaultLayout>
  );
};
