import FormikAsyncAutocomplete from '@root/components/Form/Formik/FormikAsyncAutocomplete';
import RoutePaths from '@root/pages/routes';
import { useListProfitCentresQuery } from '@root/typings/generated';
import { BaseAsyncSelect } from './Base';
import { FormicAsyncSelect } from './_type';

export const ProfitCentreAsyncSelect = (props: Omit<FormicAsyncSelect, 'hideAddBtn'>) => {
  const { name, size, orderBy, goToId, ...rest } = props;

  return (
    <BaseAsyncSelect
      hideAddBtn={true}
      createUrl={RoutePaths.contracts.profit_centres.create}
      goToUrl={goToId && `${RoutePaths.contracts.profit_centres.root}/${goToId}`}
    >
      <FormikAsyncAutocomplete
        queryKey="profitCentres"
        getOptionLabel={(option) => option?.name ?? ''}
        useQuery={useListProfitCentresQuery}
        {...rest}
        name={name}
        size={size || 'small'}
        orderBy={orderBy || 'name'}
      />
    </BaseAsyncSelect>
  );
};
