import { Chip } from '@mui/material';
import { getProjectStatusColor } from '@root/constants/project-status-to-color';
import { Maybe, ProjectStatusEnum } from '@root/typings/generated';
import { enumV2K } from '../../utils/enum-v2k';

export const ProjectStatus = ({ status }: { status?: Maybe<ProjectStatusEnum> }) => {
  const color = getProjectStatusColor(status, 'cyan');
  return (
    <Chip
      label={enumV2K(ProjectStatusEnum, status)}
      color="primary"
      size="small"
      sx={{ backgroundColor: color }}
    />
  );
};
