/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Checkbox,
  CircularProgress,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface AsyncAutocompleteProps {
  getOptionLabel: (option?: any) => string;
  getOptionDisabled?: (option: any) => boolean;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  queryKey: string;
  useQuery: any;
  variables?: any;
  orderBy: string;
  disabled?: boolean;
  id?: string;
  label?: string;
  error?: boolean;
  multiple?: boolean;
  checkbox?: boolean;
  disableCloseOnSelect?: boolean;
  limitTags?: number;
  helperText?: string | React.ReactNode;
  value?: any;
  onChange?: (e: React.SyntheticEvent<Element, Event>, v: any) => void;
  size?: 'small' | 'medium';
}
const LIMIT = 50;

export default function AsyncAutocomplete(props: AsyncAutocompleteProps) {
  const {
    getOptionLabel,
    getOptionDisabled,
    useQuery,
    orderBy,
    queryKey,
    label,
    error,
    helperText,
    onChange,
    variables,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly any[]>([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [result] = useQuery({
    variables: {
      offset: LIMIT * (page - 1),
      first: LIMIT,
      search,
      orderBy,
      ...variables,
    },
    pause: !open || options?.length > LIMIT * (page - 1),
    requestPolicy: 'network-only',
  });

  const { fetching, data } = result;

  useEffect(() => {
    setOptions([]);
    setPage(1);
  }, [search]);
  useEffect(() => {
    if (fetching || !data?.[queryKey]?.edges?.length) return;
    setOptions(
      uniqBy([...options, ...(data?.[queryKey]?.edges?.map((dt: any) => dt?.node) ?? [])], 'id'),
    );
  }, [fetching]);
  useEffect(() => {
    setSearch('');
  }, [open]);
  useEffect(() => {
    setOptions([]);
    setPage(1);
  }, [variables]);

  const loadMoreOptions = () => {
    if (fetching || data?.[queryKey]?.pageCursors?.totalPages <= page) return;
    setPage(page + 1);
  };

  const onSearch = debounce((str: string) => {
    setSearch(str);
  }, 300);

  return (
    <Autocomplete
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          {rest.multiple && rest.checkbox !== false && (
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          )}
          {getOptionLabel(option)}
        </li>
      )}
      {...rest}
      onChange={(_, v) => onChange && onChange(_, v)}
      open={open}
      fullWidth
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      options={options}
      loading={fetching}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            loadMoreOptions();
          }
        },
      }}
      filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => onSearch(e.currentTarget.value)}
          label={label}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {fetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

AsyncAutocomplete.defaultProps = {
  multiple: false,
};
