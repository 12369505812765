import { Route } from 'react-router-dom';
import RoutePaths from '@root/pages/routes';
import PeopleList from './List';
import Switch from '@root/components/RouterDom/Switch';
import UpsertPerson from './Upsert';
import PersonView from './View';

const People = () => {
  return (
    <Switch>
      <Route path={RoutePaths.people.list} component={PeopleList} exact />
      <Route path={RoutePaths.people.create} component={UpsertPerson} />
      <Route path={RoutePaths.people[':id'].root} component={PersonView} exact />
      <Route path={RoutePaths.people[':id'].edit} component={UpsertPerson} />
    </Switch>
  );
};
export default People;
