import * as _ from 'lodash';
import { Grid } from '@mui/material';
import { ContractNode, EntityNode } from '@root/typings/generated';
import ContractItem from './ContractItem';

interface ContractListProps {
  entity: EntityNode;
}

const ContractList = (props: ContractListProps) => {
  const { entity } = props;

  const contracts = entity.contractSet?.edges?.map((edge) => edge?.node) ?? ([] as ContractNode[]);

  return (
    <Grid item container spacing={2} width="100%">
      {_.chain(contracts)
        .filter((c) => c?.status != 'Completed')
        .sortBy(['status'])
        .reverse()
        .map(
          (contract) =>
            contract && (
              <Grid item xs={12} md={6} key={contract.id}>
                <ContractItem contract={contract} />
              </Grid>
            ),
        )
        .value()}
    </Grid>
  );
};

export default ContractList;
