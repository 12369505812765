import { CompactCheckbox } from '@root/components/Checkbox/CompactCheckbox';
import DetailDate from '@root/components/Detail/Date';
import DetailMoney from '@root/components/Detail/Money';
import DetailText from '@root/components/Detail/Text';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { Link } from '@root/components/Link';
import { REACT_APP_API_URL } from '@root/constants/env';
import RoutePaths from '@root/pages/routes';
import { InternalProjectNode } from '@root/typings/generated';
import { routesWithValue } from '@root/utils/route';

interface DetailProps {
  project: InternalProjectNode;
}

export const BasicDetail: React.FC<DetailProps> = ({ project }: DetailProps) => {
  return (
    <>
      <FormFieldGrid label="Compact ID:">
        <DetailText>
          {project.compactId}{' '}
          <a href={`${REACT_APP_API_URL}/admin/pc_trade/internalproject/${project.realId}/change/`}>
            admin
          </a>
        </DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Name:">
        <DetailText>{project.name}</DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Description:">
        <DetailText>{project.description}</DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Contract:">
        <Link
          label={project.contract.name}
          route={routesWithValue(':id', project.contract.id, RoutePaths.contracts.home[':id']).root}
        />
      </FormFieldGrid>
      <FormFieldGrid label="Product:">
        <Link
          label={project.product.name}
          route={routesWithValue(':id', project.product.id, RoutePaths.products.home[':id']).root}
        />
      </FormFieldGrid>
      <FormFieldGrid label="Manager:">
        {project.manager ? (
          <Link
            isExternal
            label={project.manager.name ?? project.manager.firstName}
            route={routesWithValue(':id', project.manager.id, RoutePaths.people[':id']).root}
          />
        ) : (
          <DetailText>None</DetailText>
        )}
      </FormFieldGrid>
      <FormFieldGrid label="Budget (inc gst):">
        <DetailMoney data={project.budget} />
      </FormFieldGrid>
      <FormFieldGrid label="Start Date:">
        <DetailDate date={project.startDate} />
      </FormFieldGrid>
      <FormFieldGrid label="End Date:">
        <DetailDate date={project.endDate} />
      </FormFieldGrid>
      <FormFieldGrid label="Status:">
        <DetailText>{project.status}</DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Account Manager Approved:">
        <CompactCheckbox checked={project.accountManagerApproved} disabled />
      </FormFieldGrid>
      <FormFieldGrid label="Work at Risk:">
        <CompactCheckbox checked={project.workAtRisk} disabled />
      </FormFieldGrid>
      <FormFieldGrid label="Overburn:">
        <CompactCheckbox checked={project.overburn} disabled />
      </FormFieldGrid>
      <FormFieldGrid label="Completed:">
        <CompactCheckbox checked={project.completed} disabled />
      </FormFieldGrid>
    </>
  );
};
