import { Redirect, Route } from 'react-router-dom';
import { Contracts } from './Contracts';
import { Products } from './Products';
import { Projects } from './Projects';
import People from './People';
import AuthLayout from '@root/layouts/Auth';
import RoutePaths from '@root/pages/routes';
import Switch from '@root/components/RouterDom/Switch';
import HomePage from './Home';

export const AuthPages = () => {
  return (
    <Switch notfound={<Redirect to={RoutePaths.home} />}>
      <Route path={RoutePaths.home} exact>
        <HomePage />
      </Route>
      <AuthLayout>
        <Switch notfound={<Redirect to={RoutePaths.home} />}>
          <Route path={RoutePaths.contracts.root}>
            <Contracts />
          </Route>
          <Route path={RoutePaths.products.root}>
            <Products />
          </Route>
          <Route path={RoutePaths.projects.root}>
            <Projects />
          </Route>
          <Route path={RoutePaths.people.root}>
            <People />
          </Route>
        </Switch>
      </AuthLayout>
    </Switch>
  );
};
