/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserNode } from '@root/typings/generated';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';

export interface PathInfo {
  pathName?: string;
  path: string;
}

export interface AuthContextProps {
  logging?: boolean;
  user?: UserNode;
  setUser: Dispatch<SetStateAction<UserNode | undefined>>;
  paths: PathInfo[];
  setPaths: Dispatch<SetStateAction<PathInfo[]>>;
  pathContext: any;
  setPathContext: Dispatch<SetStateAction<any>>;
  tokens?: Tokens;
  setTokens: Dispatch<SetStateAction<Tokens | undefined>>;
}

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export function useAuthContext() {
  const context = useContext(AuthContext);
  return context;
}
