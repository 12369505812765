import { CompactCheckbox } from '@root/components/Checkbox/CompactCheckbox';
import DetailText from '@root/components/Detail/Text';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { Link } from '@root/components/Link';
import { REACT_APP_API_URL } from '@root/constants/env';
import RoutePaths from '@root/pages/routes';
import { UserNode } from '@root/typings/generated';
import { routesWithValue } from '@root/utils/route';

interface DetailProps {
  person: UserNode;
}
export const BasicDetails: React.FC<DetailProps> = ({ person }: DetailProps) => {
  return (
    <>
      <FormFieldGrid label="Name:">
        <DetailText>{person.name}</DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Username:">
        <DetailText>
          {person.username}{' '}
          <a href={`${REACT_APP_API_URL}/admin/users/user/${person.realId}/change/`}>admin</a>
        </DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Email:">
        <DetailText>{person.email}</DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Salary Hours/Fortnight:">
        <DetailText>
          {person.salaryHoursPerFortnight ? Number(person.salaryHoursPerFortnight).toFixed(2) : '-'}
        </DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Hub:">
        {person.hub ? (
          <Link
            label={person.hub.name || ''}
            route={
              routesWithValue(':id', person.hub.id, RoutePaths.contracts.profit_centres[':id']).root
            }
          />
        ) : (
          <DetailText>None</DetailText>
        )}
      </FormFieldGrid>
      <FormFieldGrid label="Inactive:">
        <CompactCheckbox checked={person.inactive} disabled />
      </FormFieldGrid>
    </>
  );
};
