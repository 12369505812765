import { Chip, Table, TableBody, TableCell } from '@mui/material';
import { Link } from '@root/components/Link';
import { TableHeader } from '@root/components/Table/TableHeader';
import { TableRow } from '@root/components/Table/TableRow';
import { TableContainer } from '@root/components/Table/TableContainer';
import { usePagination } from '@root/hooks/use-pagination';
import { ProfitCentreNode, useListUsersQuery, UserNode } from '@root/typings/generated';
import RoutePaths from '@root/pages/routes';
import { routesWithValue } from '@root/utils/route';

interface PeopleTableProps {
  hub: ProfitCentreNode;
}
export const PeopleTable = (props: PeopleTableProps) => {
  const { hub } = props;
  const { limit, page, search, setPage, orderBy, changeOrder, orderDir, orderKey } =
    usePagination('name');
  const [result] = useListUsersQuery({
    variables: {
      offset: limit * (page - 1),
      first: limit,
      hubId: hub.id,
      search,
      orderBy,
      includeInactive: false,
    },
  });
  const { data } = result;
  const rows = (data?.users?.edges?.map((data) => data?.node) ?? []) as UserNode[];

  return (
    <TableContainer
      emptyLabel="people"
      result={result}
      pageCursors={data?.users?.pageCursors}
      page={page}
      setPage={setPage}
    >
      <Table size="small">
        <TableHeader
          columns={[
            { id: 'name', label: 'Name' },
            { id: 'roles', label: 'Roles', sortable: false },
            { id: 'num_project_count', label: 'Project Count' },
          ]}
          orderKey={orderKey}
          orderDir={orderDir}
          changeOrder={changeOrder}
        />
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell>
                <Link
                  isExternal
                  label={row.name ?? row.firstName}
                  route={routesWithValue(':id', row.id, RoutePaths.people[':id']).root}
                />
              </TableCell>
              <TableCell>
                {row.roles?.map((role, index) => (
                  <Chip key={index} label={role?.name} size="small" />
                ))}
              </TableCell>
              <TableCell>{row.projectCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
