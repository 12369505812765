import Switch from '@root/components/RouterDom/Switch';
import RoutePaths from '@root/pages/routes';
import { Route } from 'react-router-dom';
import UpsertContract from './Upsert';
import ContractsList from './List';
import ContractDetailView from './View';

const ContractsHome = () => {
  return (
    <Switch>
      <Route path={RoutePaths.contracts.home.list} component={ContractsList} exact />
      <Route path={RoutePaths.contracts.home.create} component={UpsertContract} />
      <Route path={RoutePaths.contracts.home[':id'].root} component={ContractDetailView} exact />
      <Route path={RoutePaths.contracts.home[':id'].edit} component={UpsertContract} />
    </Switch>
  );
};

export default ContractsHome;
