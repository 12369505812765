import { Button, FormControl, Box, Grid } from '@mui/material';
import { InnerLoading } from '@root/components/Loading/InnerLoading';
import { usePagination } from '@root/hooks/use-pagination';
import { ComponentNode, useListComponentsQuery } from '@root/typings/generated';
import { useInputStyles } from '@root/styles/form/input';
import { useHistory } from 'react-router-dom';
import DetailLink from '@root/components/Detail/Link';
import TagsInput from '@root/components/Form/Base/TagsInput';
import RoutePaths from '@root/pages/routes';
import { Header } from '@root/components';
import { Table } from '@root/components/Table/Table';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from '@root/components/Link';
import { routesWithValue } from '@root/utils/route';

const componentsListTableColumns: GridColDef<ComponentNode>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Link
        label={params.value}
        route={routesWithValue(':id', params.row.id, RoutePaths.products.components[':id']).root}
      />
    ),
  },
  {
    field: 'repository',
    headerName: 'Repository',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <DetailLink target="_blank" href={params.value.url} />
    ),
  },
  {
    field: 'products',
    headerName: 'Products',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) =>
      params.value.map((product: { name: string }) => product?.name).join(', '),
  },
];

const ComponentsList = () => {
  const classes = useInputStyles();
  const history = useHistory();
  const { limit, page, search, setSearch, setPage, orderBy, setOrder, setLimit } =
    usePagination('name');
  const [result] = useListComponentsQuery({
    variables: {
      offset: limit * (page - 1),
      first: limit,
      search,
      orderBy,
    },
  });

  const { fetching, data, error } = result;
  const rows = data?.components?.edges?.map((data) => data?.node) ?? [];

  return (
    <Box>
      <Grid container alignItems="flex-end" spacing={2} direction="column">
        <Grid item width="100%">
          <Header
            left={
              <FormControl variant="standard" fullWidth>
                <TagsInput
                  fullWidth
                  placeholder="Search"
                  className={classes.searchInput}
                  name="search-rate-cards"
                  value={search}
                  onChange={setSearch}
                />
              </FormControl>
            }
            right={
              <Button
                variant="outlined"
                fullWidth
                onClick={() => history.push(RoutePaths.products.components.create)}
                data-testid="add-component-button"
              >
                Add Component
              </Button>
            }
            title="Components"
          />
        </Grid>
        <Grid item width="100%">
          {!error && rows.length == 0 ? (
            <Box position="relative" marginTop={1}>
              {fetching ? (
                <InnerLoading loading={true} opacity={0} />
              ) : (
                <div>No components found</div>
              )}
            </Box>
          ) : (
            <Box position="relative">
              {error ? (
                <div>{JSON.stringify(error)}</div>
              ) : (
                <Table
                  rows={rows}
                  isLoading={fetching}
                  columns={componentsListTableColumns}
                  paginationControls={{
                    count: data?.components?.pageCursors?.totalPages ?? 1,
                    page: page - 1,
                    onPageChange: (val: number) => setPage(val + 1),
                    onRowsPerPageChange: (val: number) => setLimit(val),
                    rowsPerPage: limit,
                  }}
                  searchControls={{ onSortChange: setOrder }}
                />
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ComponentsList;
