/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Chip, Grid, Paper, Stack, Typography } from '@mui/material';
import { InnerLoading } from '@root/components/Loading/InnerLoading';
import { usePath } from '@root/providers/AuthProvider/modules/path';
import { Node } from '@root/typings/generated';
import { useParams } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import { routesWithValue } from '@root/utils/route';
import { Redirect } from 'react-router-dom';
import format from 'date-fns/format';

export default function generateDetailViewWidget<
  NodeSchema extends Node & { deleted?: IgnoredAny },
>(arg: {
  name: string;
  redirect: string;
  paper?: boolean;
  editUrl: EditRouteType;
  getDataQuery: any;
  component: React.ComponentType<{ data: NodeSchema }>;
}) {
  const DetailViewWidget = () => {
    const { id } = useParams<IdParam>();
    const { name, redirect, getDataQuery, component: Comp, editUrl, paper } = arg;
    const [result] = getDataQuery({
      variables: {
        gId: id,
      },
      pause: !id,
    });
    const { fetching, data, error } = result;

    const { redirectPath } = usePath();
    if (fetching) return <InnerLoading loading={fetching} />;
    if (!data) return <Redirect to={redirect} />;
    const node: NodeSchema = (data ? Object.values(data)?.[0] : null) as NodeSchema;
    if (error || !node) return <Redirect to={redirect} />;
    const viewName = `${name} Detail`;
    const routes = routesWithValue(':id', node.id, editUrl);
    const pageDetail = (
      <Grid container justifyContent="center" spacing={1} direction="column">
        <Stack alignItems="flex-end">
          {!node?.deleted ? (
            <Button
              onClick={() => redirectPath(routes.edit)}
              variant="contained"
              size="small"
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          ) : (
            <Chip color="error" label={`Inactived at ${format(new Date(node?.deleted), 'PPP')}`} />
          )}
        </Stack>
        {!fetching && !error && <Comp data={node} />}
      </Grid>
    );
    return (
      <Grid position="relative" justifyContent="center" maxWidth={1200} mx="auto" mt={0} pt={0}>
        <Grid item width="100%" textAlign="center">
          <Typography variant="h5">{viewName}</Typography>
        </Grid>
        {paper !== false ? (
          <Paper sx={{ padding: 5, mt: 2 }} elevation={3}>
            {pageDetail}
          </Paper>
        ) : (
          pageDetail
        )}
      </Grid>
    );
  };
  return DetailViewWidget;
}
