import { Grid, Stack } from '@mui/material';
import DetailText from '@root/components/Detail/Text';
import { IdentityNode, UserNode } from '@root/typings/generated';
import { useFormikContext } from 'formik';
import UpsertIdentity from './UpsertIdentity';

export const ProviderIdentities = () => {
  const { values: user } = useFormikContext<UserNode>();

  return (
    <>
      {user.identities.map((identity: IdentityNode, index: number) => (
        <Grid item container key={index} spacing={1}>
          <Grid item xs={5}>
            <DetailText>{identity.context?.name}</DetailText>
          </Grid>
          <Grid item xs={5}>
            <DetailText>{identity.primaryIdentifier}</DetailText>
          </Grid>
          <Grid item xs={2}>
            <UpsertIdentity data={identity} />
          </Grid>
        </Grid>
      ))}
      <Stack mt={2} justifyContent="flex-end" direction="row" spacing={2}>
        <UpsertIdentity />
      </Stack>
    </>
  );
};
