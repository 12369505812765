import { ListComponentsQueryVariables, useListComponentsQuery } from '@root/typings/generated';
import { AsyncAutocomplete } from '../Base';
import { AsyncSelectWithVar } from './_type';

export const ComponentSelect = (props: AsyncSelectWithVar<ListComponentsQueryVariables>) => {
  return (
    <AsyncAutocomplete
      queryKey="components"
      getOptionLabel={(option) => option?.name ?? ''}
      useQuery={useListComponentsQuery}
      size={'small'}
      orderBy={'name'}
      {...props}
    />
  );
};
