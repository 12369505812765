import FormSectionAccordion from '@root/components/Accordion/FormSectionAccordion';
import generateUpsertFormWidget from '@root/components/Form/Widget/upsert-form';
import RoutePaths from '@root/pages/routes';
import {
  UpsertUserDocument,
  UpsertUserMutation,
  UpsertUserMutationVariables,
  useGetUserQuery,
  UserNode,
} from '@root/typings/generated';
import { BasicDetails } from './BasicDetails';
import Projects from './Projects';
import { ProviderIdentities } from './ProviderIdentities';
import { initialValues, validationSchema } from './_type';

const UpsertUserFormWidget = generateUpsertFormWidget<
  UserNode,
  UpsertUserMutationVariables,
  UpsertUserMutation
>({
  name: 'Person',
  redirect: RoutePaths.people.list,
  viewUrl: RoutePaths.people[':id'],
  initialValues,
  validationSchema,
  mutationDoc: UpsertUserDocument,
  getDataQuery: useGetUserQuery,
});

const UpsertPerson = () => {
  return (
    <UpsertUserFormWidget
      mapping={({ id, name, username, hub, email, salaryHoursPerFortnight }) => ({
        data: {
          instance: id,
          name: name ?? '',
          username: username ?? '',
          email: email ?? '',
          hub: hub?.id ?? '',
          salaryHoursPerFortnight: salaryHoursPerFortnight === '' ? null : salaryHoursPerFortnight,
        },
      })}
      basicDetail={<BasicDetails />}
    >
      <FormSectionAccordion title="Provider Identities">
        <ProviderIdentities />
      </FormSectionAccordion>
      <FormSectionAccordion title="Projects">
        <Projects />
      </FormSectionAccordion>
    </UpsertUserFormWidget>
  );
};

export default UpsertPerson;
