import { ProjectRoleAssignmentNode, RoleNode } from '@root/typings/generated';
import { NullID } from '@root/yup/initial-value';
import * as Yup from 'yup';

export const peopleAssignmentInitialValue: ProjectRoleAssignmentNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  person: null,
  project: null,
  role: null,
};
export const peopleAssignmentYup = Yup.object({
  person: Yup.object().nullable().required('Person is required'),
  project: Yup.object().nullable().required('Project is required'),
});

export const initialValues: RoleNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  name: '',
  description: '',
  people: NullID,
  projects: [],
  peopleAssignments: [],
};

export const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
});
