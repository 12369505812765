import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import DetailText from '@root/components/Detail/Text';
import BillingCodesForm from '@root/components/FormSections/BillingCodesForm';
import { simpleAccordionStyles } from '@root/styles/accordion/simple-accordion';
import { InternalProjectNode } from '@root/typings/generated';

interface Props {
  project: InternalProjectNode;
  index: number;
}

const ProductObjectiveAccordion = (props: Props) => {
  const { project, index } = props;
  const classes = simpleAccordionStyles();
  const formikName = `projects.${index}.billingCodes`;
  return (
    <Accordion className={classes.accordion} disableGutters>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <DetailText>[{project?.billingCodes?.length ?? 0}] Objectives</DetailText>
      </AccordionSummary>
      <AccordionDetails className={classes.accordinDetail}>
        <BillingCodesForm project={project} formikName={formikName} />
      </AccordionDetails>
    </Accordion>
  );
};

export default ProductObjectiveAccordion;
