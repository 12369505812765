import { Box, Grid } from '@mui/material';
import { FormikDatePicker } from '@root/components/Form/Formik/FormikDatePicker';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import React from 'react';

const ChartFilter = () => {
  return (
    <Grid
      width="100%"
      container
      alignItems="center"
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      paddingY={2}
      mx="auto"
    >
      <Grid item xs={12} md={8}>
        <FormFieldGrid label="Date:" alignItems="center">
          <FormikDatePicker name="startDate" size="small" />
          <Box sx={{ mx: 1 }}> to </Box>
          <FormikDatePicker name="endDate" size="small" />
        </FormFieldGrid>
      </Grid>
    </Grid>
  );
};

export default ChartFilter;
