import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  FormLabelProps,
} from '@mui/material';
import { FC } from 'react';
import { useField } from './hooks';

export interface BaseProps extends FormControlProps {
  name: string;
  label?: string;
  labelProps?: FormLabelProps;
  helperText?: string;
}

export const FormikFormControl: FC<BaseProps> = (props: BaseProps) => {
  const { children, name, label, helperText, labelProps, ...rest } = props;
  const {
    field: { error, helperText: helperTxt },
  } = useField(name, helperText);

  return (
    <FormControl error={error} {...rest}>
      {label && (
        <FormLabel {...labelProps} htmlFor={name}>
          {label}
        </FormLabel>
      )}
      {children}
      {error && <FormHelperText>{helperTxt}</FormHelperText>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default FormikFormControl;
