import { Table, TableBody, TableCell, TableContainer } from '@mui/material';
import DetailText from '@root/components/Detail/Text';
import { Link } from '@root/components/Link';
import { TableHeader } from '@root/components/Table/TableHeader';
import { TableRow } from '@root/components/Table/TableRow';
import RoutePaths from '@root/pages/routes';
import { ProjectRoleAssignmentNode } from '@root/typings/generated';
import { routesWithValue } from '@root/utils/route';

export const Projects = ({
  projectAssignments,
}: {
  projectAssignments: Array<ProjectRoleAssignmentNode>;
}) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHeader
          columns={[
            {
              label: 'Contract',
              sortable: false,
            },
            {
              label: 'Project',
              sortable: false,
            },
            {
              label: 'Role',
              sortable: false,
            },
          ]}
        />
        <TableBody>
          {projectAssignments.map((projectAssignment) => [
            <TableRow key={projectAssignment.id}>
              <TableCell>
                {projectAssignment.project?.contract ? (
                  <Link
                    label={projectAssignment.project?.contract.name}
                    route={
                      routesWithValue(
                        ':id',
                        projectAssignment.project?.contract.id,
                        RoutePaths.contracts.home[':id'],
                      ).root
                    }
                  />
                ) : (
                  <DetailText>None</DetailText>
                )}
              </TableCell>
              <TableCell>
                {projectAssignment.project ? (
                  <Link
                    label={projectAssignment.project.name}
                    route={{
                      id: projectAssignment.project.id,
                      basePath: RoutePaths.projects.home[':id'].root,
                    }}
                  />
                ) : (
                  <DetailText>None</DetailText>
                )}
              </TableCell>
              <TableCell>
                {projectAssignment.role ? (
                  <Link
                    label={projectAssignment.role.name}
                    route={
                      routesWithValue(':id', projectAssignment.role.id, RoutePaths.people[':id'])
                        .root
                    }
                  />
                ) : (
                  <DetailText>None</DetailText>
                )}
              </TableCell>
            </TableRow>,
          ])}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
