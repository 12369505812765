import FormSectionAccordion from '@root/components/Accordion/FormSectionAccordion';
import generateUpsertFormWidget from '@root/components/Form/Widget/upsert-form';
import transferDate from '@root/graphql/input-transfer/transfer-date';
import transferMoney from '@root/graphql/input-transfer/transfer-money';
import RoutePaths from '@root/pages/routes';
import {
  InternalProjectNode,
  UpsertProjectDocument,
  UpsertProjectMutation,
  UpsertProjectMutationVariables,
  useGetProjectQuery,
} from '@root/typings/generated';
import BasicDetails from './BasicDetails';
import BillingCodesAndComponents from './BillingCodesAndComponents';
import PeopleForm from './People';
import { initialValues, validationSchema } from './_type';

const UpsertProjectFormWidget = generateUpsertFormWidget<
  InternalProjectNode,
  UpsertProjectMutationVariables,
  UpsertProjectMutation
>({
  name: 'Project',
  redirect: RoutePaths.projects.home.list,
  viewUrl: RoutePaths.projects.home[':id'],
  initialValues,
  validationSchema,
  mutationDoc: UpsertProjectDocument,
  getDataQuery: useGetProjectQuery,
});

const UpsertProject = () => {
  return (
    <UpsertProjectFormWidget
      mapping={({
        id,
        name,
        description,
        contract,
        product,
        manager,
        budget,
        startDate,
        endDate,
        fixedPrice,
        billingCodes,
        peopleAssignments,
        accountManagerApproved,
        completed,
        workAtRisk,
        overburn,
      }) => ({
        data: {
          instance: id,
          name: name ?? '',
          description,
          contract: contract?.id ?? '',
          product: product?.id ?? '',
          manager: manager?.id ?? '',
          budget: transferMoney(budget),
          startDate: transferDate(startDate),
          endDate: transferDate(endDate),
          fixedPrice,
          billingCodes: billingCodes.map(({ id, productObjective, components }) => ({
            instance: id,
            productObjective: productObjective?.id ?? '',
            components: components.map(({ id }) => id),
          })),
          peopleAssignments: peopleAssignments.map(({ id, person, role }) => ({
            instance: id,
            person: person?.id ?? '',
            role: role?.id ?? '',
          })),
          accountManagerApproved,
          completed,
          workAtRisk,
          overburn,
        },
      })}
      basicDetail={<BasicDetails />}
    >
      <>
        <FormSectionAccordion title="Billing Codes and Components">
          <BillingCodesAndComponents />
        </FormSectionAccordion>
        <FormSectionAccordion title="People">
          <PeopleForm />
        </FormSectionAccordion>
      </>
    </UpsertProjectFormWidget>
  );
};

export default UpsertProject;
