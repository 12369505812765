import { Grid, Typography } from '@mui/material';
import React from 'react';

interface HeaderProps {
  title: string | React.ReactNode | React.ReactNode[];
  left?: React.ReactNode | React.ReactNode[];
  right?: React.ReactNode | React.ReactNode[];
  testid?: string;
}

/**
 * Reusable header component
 * @param {HeaderProps} props
 * @property {string | React.ReactNode | React.ReactNode[]} props.title title or central element to be render
 * @property {React.ReactNode | React.ReactNode[]} [props.left] element to be rendered left of the title
 * @property {React.ReactNode | React.ReactNode[]} [props.right] element to be rendered right of the title
 * @property {string} [props.testid] test id pass though
 * @returns
 */
export const Header = ({ testid, title, right, left }: HeaderProps) => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
      data-testid={testid || 'header-container'}
    >
      <Grid item md={4} sm={12} xs={12} sx={{ textAlign: 'center' }}>
        {left}
      </Grid>
      <Grid item md={4} sm={12} xs={12} sx={{ textAlign: 'center' }}>
        {typeof title === 'string' ? (
          <Typography data-testid={'header-title'} variant="h4">
            {title}
          </Typography>
        ) : (
          { title }
        )}
      </Grid>
      <Grid item md={4} sm={12} xs={12} sx={{ textAlign: 'center' }}>
        {right}
      </Grid>
    </Grid>
  );
};
