import { ListClientsQueryVariables, useListClientsQuery } from '@root/typings/generated';
import { AsyncAutocomplete } from '../Base';
import { AsyncSelectWithVar } from './_type';

export const ClientSelect = (props: AsyncSelectWithVar<ListClientsQueryVariables>) => {
  return (
    <AsyncAutocomplete
      queryKey="clients"
      getOptionLabel={(option) => option?.name ?? ''}
      useQuery={useListClientsQuery}
      size={'small'}
      orderBy={'name'}
      {...props}
    />
  );
};
