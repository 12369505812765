import DetailDate from '@root/components/Detail/Date';
import DetailLink from '@root/components/Detail/Link';
import DetailMoney from '@root/components/Detail/Money';
import DetailText from '@root/components/Detail/Text';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { Link } from '@root/components/Link';
import { REACT_APP_API_URL } from '@root/constants/env';
import RoutePaths from '@root/pages/routes';
import { ContractNode } from '@root/typings/generated';
import { routesWithValue } from '@root/utils/route';

interface DetailProps {
  contract: ContractNode;
}
export const BasicDetails: React.FC<DetailProps> = ({ contract }: DetailProps) => {
  return (
    <>
      <FormFieldGrid label="Compact ID:">
        <DetailText>
          {contract.compactId}{' '}
          <a
            href={`${REACT_APP_API_URL}/admin/corporate_catalogue/contract/${contract.realId}/change/`}
          >
            admin
          </a>
        </DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Client:">
        <Link
          label={contract.client.name}
          route={
            routesWithValue(':id', contract.client.id, RoutePaths.contracts.clients[':id']).root
          }
        />
      </FormFieldGrid>
      <FormFieldGrid label="Entity:">
        <Link
          label={contract.entity.name}
          route={
            routesWithValue(':id', contract.entity.id, RoutePaths.contracts.profit_centres[':id'])
              .root
          }
        />
      </FormFieldGrid>
      <FormFieldGrid label="Name:">
        <DetailText>{contract.name}</DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Description:">
        <DetailText>{contract.description ?? '-'}</DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Account Manger:">
        <DetailText>{contract.accountManager?.name ?? '-'}</DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Document URL:">
        {contract.documentUrl ? (
          <DetailLink href={contract.documentUrl} />
        ) : (
          <DetailText>-</DetailText>
        )}
      </FormFieldGrid>
      <FormFieldGrid label="Budget (inc gst):">
        <DetailMoney data={contract.budget} />
      </FormFieldGrid>
      <FormFieldGrid label="Created:">
        <DetailDate date={contract.created} />
      </FormFieldGrid>
      <FormFieldGrid label="Submitted:">
        <DetailDate date={contract.submitted} />
      </FormFieldGrid>
      <FormFieldGrid label="Signed:">
        <DetailDate date={contract.signed} />
      </FormFieldGrid>
      <FormFieldGrid label="Valid Until:">
        <DetailDate date={contract.validUntil} />
      </FormFieldGrid>
      <FormFieldGrid label="Completed:">
        <DetailDate date={contract.completed} />
      </FormFieldGrid>
      <FormFieldGrid label="Status:">
        <DetailText>{contract.status ?? '-'}</DetailText>
      </FormFieldGrid>
    </>
  );
};
