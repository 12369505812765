import React, { cloneElement, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { Box, Collapse, List, ListItem, Theme } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { SidebarMenuItem } from '@root/typings/sidebar';
import NavMenuItem from './MenuItem';
import NavSection from './Section';
import { makeStyles } from '@mui/styles';
import { NavLink } from 'react-router-dom';
import { useStyles as itemUseStyles } from './MenuItem';

const useStyles = makeStyles((theme: Theme) => ({
  navCollapse: {
    position: 'relative',
    '&.open': {
      '& $navCollapseBtn': {
        color: theme.sidebarPalette.textDarkColor,
      },
      '& $iconRoot': {
        color: theme.palette.primary.main,
      },
    },
    '& .navHeader': {
      paddingLeft: 36,
    },
  },
  navCollapseBtn: {
    position: 'relative',
    padding: '0 16px 0 0',
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
    '.authLayout .sidebar-content:not(:hover) &': {
      paddingLeft: 16,
    },
  },
  navCollapseBtnInner: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '9px 16px 9px 32px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    '&:hover, &:focus': {
      color: theme.sidebarPalette.textDarkColor,
      backgroundColor: theme.sidebarPalette.navHoverBgColor,
    },
    '.authLayout &': {
      paddingRight: 13,
      paddingLeft: 13,
    },
    '.authLayout .sidebar-content:hover &': {
      paddingRight: 16,
      paddingLeft: 32,
    },
  },
  navText: {
    flex: 1,
    whiteSpace: 'nowrap',
    '.authLayout .sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
  iconRoot: {
    marginRight: 16,
    fontSize: 20,
  },
  navArrow: {
    position: 'absolute',
    left: 8,
    top: '50%',
    zIndex: 1,
    transform: 'translateY(-50%)',
    fontSize: 16,
    '.authLayout .sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
  navCollapseItem: {
    position: 'relative',
    borderLeft: '5px solid',
    marginLeft: '2px',
    borderColor: theme.sidebarPalette.navHoverBgColor,
    '& .icon-root': {
      transition: 'all 0.3s ease',
      transform: 'translateX(-100%)',
      opacity: 0,
      marginRight: 0,
    },
    '& .nav-text': {
      transition: 'all 0.35s ease',
      marginLeft: -20,
    },
    '& .nav-menu-link': {
      padding: '9px 16px 9px 50px',
      transition: 'all 0.3s ease',
      '&:hover, &:focus': {
        '& .icon-root': {
          transform: 'translateX(0)',
          marginRight: 10,
          opacity: 1,
        },
        '& .nav-text': {
          marginLeft: 0,
        },
      },
      '&.active': {
        '& .icon-root': {
          transform: 'translateX(0)',
          marginRight: 10,
          opacity: 1,
        },
        '& .nav-text': {
          marginLeft: 0,
        },
      },
      '.authLayout &': {
        paddingRight: 13,
        paddingLeft: 13,
      },
      '.authLayout .sidebar-content:hover &': {
        paddingRight: 16,
        paddingLeft: 70,
      },
    },
    '.authLayout .sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
}));

const NavCollapse = (props: SidebarMenuItem) => {
  const history = useHistory();
  const classes = useStyles();
  const itemClasses = itemUseStyles(props);

  const { name, icon, childrens = [], link } = props;
  const isExpandable = useMemo(() => childrens.length, [childrens]);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (isUrlInChildren(props, history.location.pathname)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);

  function handleClick() {
    setOpen(!open);
  }

  function isUrlInChildren(node: SidebarMenuItem, url: string) {
    if (!node.childrens) {
      return false;
    }

    for (let i = 0; i < node.childrens.length; i++) {
      if (node.childrens[i].childrens) {
        if (isUrlInChildren(node.childrens[i], url)) {
          return true;
        }
      }

      if (node.childrens[i].link) {
        if (node.childrens[i].link === url || url.includes(node.childrens[i]?.link ?? '')) {
          return true;
        }
      }
    }

    return false;
  }

  const CollapseContent = (
    <>
      {isExpandable && !open && <ExpandMore className={classes.navArrow} />}
      {isExpandable && open && <ExpandLess className={classes.navArrow} />}
      {icon
        ? cloneElement(icon, {
            className: classes.iconRoot,
          })
        : ''}
      <Box component="span" className={classes.navText}>
        {name}
      </Box>
    </>
  );

  const MenuCollapse = (
    <ListItem button onClick={handleClick} className={classes.navCollapseBtn}>
      {link ? (
        <NavLink
          to={link ?? '#'}
          className={clsx(itemClasses.navMenuLink, classes.navCollapseBtnInner)}
          exact
        >
          {CollapseContent}
        </NavLink>
      ) : (
        <Box component="span" className={classes.navCollapseBtnInner}>
          {CollapseContent}
        </Box>
      )}
    </ListItem>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" className={classes.navCollapseItem}>
      <List component="div" disablePadding>
        {childrens.map((item, index) => {
          switch (item.type) {
            case 'section':
              return <NavSection {...item} key={index} />;
            case 'collapse':
              return <NavCollapse {...item} key={index} />;
            case 'item':
              return <NavMenuItem {...item} key={index} />;
            default:
              return null;
          }
        })}
      </List>
    </Collapse>
  ) : null;

  return (
    <Box className={clsx(classes.navCollapse, `${open ? 'open' : ''}`)}>
      {MenuCollapse}
      {MenuItemChildren}
    </Box>
  );
};

export default NavCollapse;
