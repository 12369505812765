import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar,
  WithSnackbarProps,
} from 'notistack';
import React from 'react';

let useSnackbarRef: WithSnackbarProps;

export function SnackbarCloseButton({ snackbarKey }: { snackbarKey: SnackbarKey | undefined }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <Close />
    </IconButton>
  );
}
export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

const SnackbarUtils = {
  success(msg: SnackbarMessage, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'success' });
  },
  warning(msg: SnackbarMessage, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'warning' });
  },
  info(msg: SnackbarMessage, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'info' });
  },
  error(msg: SnackbarMessage, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'error' });
  },
  toast(msg: SnackbarMessage, options: OptionsObject = {}) {
    useSnackbarRef.enqueueSnackbar(msg, options);
  },
};
export default SnackbarUtils;
