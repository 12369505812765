import { CompactCheckbox } from '@root/components/Checkbox/CompactCheckbox';
import DetailText from '@root/components/Detail/Text';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { Link } from '@root/components/Link';
import { REACT_APP_API_URL } from '@root/constants/env';
import RoutePaths from '@root/pages/routes';
import { ProductNode } from '@root/typings/generated';
import { routesWithValue } from '@root/utils/route';

interface DetailProps {
  product: ProductNode;
}

const BasicDetails: React.FC<DetailProps> = ({ product }: DetailProps) => {
  return (
    <>
      <FormFieldGrid label="Compact ID:">
        <DetailText>
          {product.compactId}{' '}
          <a href={`${REACT_APP_API_URL}/admin/architecture/product/${product.realId}/change/`}>
            admin
          </a>
        </DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Name:">
        <DetailText>{product.name}</DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Description:">
        <DetailText>{product.description}</DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Client:">
        <Link
          label={product.owner.name}
          route={routesWithValue(':id', product.owner.id, RoutePaths.contracts.clients[':id']).root}
        />
      </FormFieldGrid>
      <FormFieldGrid label="Inactive:">
        <CompactCheckbox checked={product.inactive} disabled />
      </FormFieldGrid>
    </>
  );
};

export default BasicDetails;
