import Switch from '@root/components/RouterDom/Switch';
import RoutePaths from '@root/pages/routes';
import { Route } from 'react-router-dom';
import UpsertRateCard from './Upsert';
import ContractsRateCardsList from './List';
import ViewRateCardsList from './View';

const ContractsRateCards = () => {
  return (
    <Switch>
      <Route path={RoutePaths.contracts.rate_cards.list} component={ContractsRateCardsList} exact />
      <Route path={RoutePaths.contracts.rate_cards.create} component={UpsertRateCard} />
      <Route
        path={RoutePaths.contracts.rate_cards[':id'].root}
        component={ViewRateCardsList}
        exact
      />
      <Route path={RoutePaths.contracts.rate_cards[':id'].edit} component={UpsertRateCard} />
    </Switch>
  );
};

export default ContractsRateCards;
