import { ProductNode, ProductObjectiveNode } from '@root/typings/generated';
import { NullID, NullInstance } from '@root/yup/initial-value';
import * as Yup from 'yup';

export const productObjectiveInitialValue: ProductObjectiveNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  shortName: '',
  longName: '',
  inactive: false,
};

export const initialValues: ProductNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  name: '',
  description: '',
  owner: NullInstance,
  productObjectives: [],
  components: [],
  inactive: false,
};

export const validationSchema = Yup.object({
  name: Yup.string().defined('Name is required'),
  owner: Yup.object().nullable().required('Client is required'),
  productObjectives: Yup.array(),
  components: Yup.array(),
});
