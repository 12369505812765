import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DetailText from '@root/components/Detail/Text';
import { PropsWithChild } from '@root/typings/child-type';

const useStyles = makeStyles(() => ({
  accordion: {
    '&:before': {
      display: 'none',
    },

    background: 'none',
    padding: 30,
    paddingTop: 20,
    paddingBottom: 20,
    margin: '20px 0px 20px 0px !important',
  },
  accordinDetail: {},
}));

interface Props extends PropsWithChild {
  title: string;
  expanded?: boolean;
}

const FormSectionAccordion = (props: Props) => {
  const { title, children } = props;
  const classes = useStyles();
  return (
    <Accordion className={classes.accordion} disableGutters defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <DetailText variant="h5">{title}</DetailText>
      </AccordionSummary>
      <AccordionDetails className={classes.accordinDetail}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default FormSectionAccordion;
