import { InternalProjectNode } from '@root/typings/generated';

export function sortInternalProjectsByStatus(a: InternalProjectNode, b: InternalProjectNode) {
  // Bug: this seems to list values in alphabetical order, instead of the order they are defined in the enum
  // const enumValues = Object.values(ProjectStatusEnum);
  // return (enumValues.indexOf(a.status)-enumValues.indexOf(b.status)) || (a.name && b.name ? a.name.localeCompare(b.name) : 0);
  // Hack: write my own ordered list (don't try this at home, it's bad)
  const enumValues = { UPCOMING: 1, IN_PROGRESS: 2, OVERDUE: 3, COMPLETED: 4 };
  return (
    enumValues[a.status] - enumValues[b.status] ||
    (a.name && b.name ? a.name.localeCompare(b.name) : 0)
  );
}
