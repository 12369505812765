import { CurrencyCodes } from '@root/constants/currencies';
import {
  BillingCodeNode,
  InternalProjectNode,
  ProjectRoleAssignmentNode,
} from '@root/typings/generated';
import { moneyInputSchema } from '@root/yup/money-input';
import * as Yup from 'yup';
import { NullID, NullInstance } from '../../../../../yup/initial-value';

export const billingCodeInitialValue: BillingCodeNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  productObjective: null,
  inactive: false,
  components: [],
};

const billingCodeYup = Yup.object({
  productObjective: Yup.object().nullable().required('Product Objective is required'),
  components: Yup.array(),
});

export const peopleAssignmentInitialValue: ProjectRoleAssignmentNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  person: null,
  role: null,
};
export const peopleAssignmentYup = Yup.object({
  person: Yup.object().nullable().required('Person is required'),
  role: Yup.object().nullable().required('Role is required'),
});

export const initialValues: InternalProjectNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  name: '',
  description: '',
  contract: NullInstance,
  product: NullInstance,
  manager: NullInstance,
  startDate: null,
  endDate: null,
  status: NullInstance,
  budget: {
    amount: 0,
    currency: CurrencyCodes.AUD,
  },
  fixedPrice: false,
  billingCodes: [],
  peopleAssignments: [],
  accountManagerApproved: false,
  completed: false,
  workAtRisk: false,
  overburn: false,
};

export const validationSchema = Yup.object({
  name: Yup.string().nullable().required('Name is required'),
  contract: Yup.object().nullable().required('Contract is required'),
  product: Yup.object().nullable().required('Product is required'),
  manager: Yup.object().nullable(),
  budget: moneyInputSchema,
  startDate: Yup.date().nullable().required('Start Date is required'),
  endDate: Yup.date().nullable().required('End Date is required'),
  fixedPrice: Yup.boolean(),
  billingCodes: Yup.array().of(billingCodeYup),
  peopleAssignments: Yup.array().of(peopleAssignmentYup),
  accountManagerApproved: Yup.boolean(),
  completed: Yup.boolean(),
  workAtRisk: Yup.boolean(),
  overburn: Yup.boolean(),
});
