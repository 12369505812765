import { Paper, Table, TableBody, TableCell, TableContainer } from '@mui/material';
import DetailDate from '@root/components/Detail/Date';
import DetailMoney from '@root/components/Detail/Money';
import { Link } from '@root/components/Link';
import { TableHeader } from '@root/components/Table/TableHeader';
import { TableRow } from '@root/components/Table/TableRow';
import RoutePaths from '@root/pages/routes';
import { ContractNode } from '@root/typings/generated';
import { routesWithValue } from '@root/utils/route';

interface PropsType {
  contracts: Array<ContractNode>;
}
const ClientContractsTable = (props: PropsType) => {
  const { contracts } = props;
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }} variant="outlined">
      <TableContainer>
        <Table size="small">
          <TableHeader
            columns={[
              { id: 'name', label: 'Contract Name', sortable: false },
              { id: 'budget', label: 'Budget', sortable: false },
              { id: 'signed', label: 'Signed', sortable: false },
              { id: 'validUntil', label: 'Valid Until', sortable: false },
              { id: 'projects', label: 'Projects', sortable: false },
            ]}
          />
          <TableBody>
            {contracts.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Link
                    label={row.name}
                    route={routesWithValue(':id', row.id, RoutePaths.contracts.home[':id']).root}
                  />
                </TableCell>
                <TableCell>
                  <DetailMoney data={row.budget} />
                </TableCell>
                <TableCell>
                  <DetailDate date={row.signed} />
                </TableCell>
                <TableCell>
                  <DetailDate date={row.validUntil} />
                </TableCell>
                <TableCell>
                  {row.projects.map((project, index) => (
                    <Link
                      key={`${index}-${project.id}`}
                      label={project.name}
                      route={{
                        id: project.id,
                        basePath: RoutePaths.projects.home[':id'].root,
                      }}
                    />
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ClientContractsTable;
