import { ClientNode } from '@root/typings/generated';
import { NullID } from '@root/yup/initial-value';
import * as Yup from 'yup';

export const initialValues: ClientNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  name: '',
  inactive: false,
  contracts: [],
};

export const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
});
