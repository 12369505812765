import { RemoveCircleOutline } from '@mui/icons-material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Box, Button, Grid, IconButton, Stack } from '@mui/material';
import { ProductObjectiveAsyncSelect } from '@root/components/Form/FormikSelect';
import { BillingCodeNode, InternalProjectNode } from '@root/typings/generated';
import { NullID } from '@root/yup/initial-value';
import { FieldArray, useFormikContext } from 'formik';

export const billingCodeInitialValue: BillingCodeNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  productObjective: null,
  inactive: false,
  components: [],
};

interface Props {
  project: InternalProjectNode;
  formikName: string;
}

const BillingCodesForm = (props: Props) => {
  const { submitForm } = useFormikContext();
  const { project, formikName } = props;

  return (
    <FieldArray
      name={formikName}
      render={(helpers) => (
        <>
          {project?.billingCodes?.map((billingCode: BillingCodeNode, idx: number) => (
            <Stack key={idx} direction="row" spacing={2} alignItems="center" my={1}>
              <Box width="100%">
                <ProductObjectiveAsyncSelect
                  variables={{ productId: project.product?.id }}
                  name={`${formikName}.${idx}.productObjective`}
                  getOptionDisabled={(option) => {
                    return project?.billingCodes?.some(
                      (v) => v?.productObjective?.id === option.id,
                    );
                  }}
                  onChange={() => setTimeout(() => submitForm(), 100)}
                />
              </Box>
              <Box>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={() => {
                    helpers.remove(idx);
                    if (billingCode.id) submitForm();
                  }}
                >
                  <RemoveCircleOutline fontSize="medium" />
                </IconButton>
              </Box>
            </Stack>
          ))}
          <Grid item container justifyContent="right" mt={2}>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                startIcon={<InsertDriveFileOutlinedIcon />}
                onClick={() => helpers.push(billingCodeInitialValue)}
              >
                New Objective
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    />
  );
};

export default BillingCodesForm;
