import generateUpsertFormWidget from '@root/components/Form/Widget/upsert-form';
import RoutePaths from '@root/pages/routes';
import {
  ClientNode,
  UpsertClientDocument,
  UpsertClientMutation,
  UpsertClientMutationVariables,
  useGetClientQuery,
} from '@root/typings/generated';
import ClientForm from './form';
import { initialValues, validationSchema } from './_type';

const UpsertClientFormWidget = generateUpsertFormWidget<
  ClientNode,
  UpsertClientMutationVariables,
  UpsertClientMutation
>({
  name: 'Client',
  redirect: RoutePaths.contracts.clients.list,
  viewUrl: RoutePaths.contracts.clients[':id'],
  initialValues,
  validationSchema,
  mutationDoc: UpsertClientDocument,
  getDataQuery: useGetClientQuery,
});

const UpsertClient = () => {
  return (
    <UpsertClientFormWidget
      mapping={({ id, name, inactive }) => ({
        data: {
          instance: id,
          name,
          inactive,
        },
      })}
      basicDetail={<ClientForm />}
    >
      <></>
    </UpsertClientFormWidget>
  );
};

export default UpsertClient;
