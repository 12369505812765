import Switch from '@root/components/RouterDom/Switch';
import RoutePaths from '@root/pages/routes';
import { Route } from 'react-router-dom';
import UpsertProfitCentre from './Upsert';
import ContractsProfitCentresList from './List';
import ViewProfitCentre from './View';

const ContractsProfitCentres = () => {
  return (
    <Switch>
      <Route
        path={RoutePaths.contracts.profit_centres.list}
        component={ContractsProfitCentresList}
        exact
      />
      <Route path={RoutePaths.contracts.profit_centres.create} component={UpsertProfitCentre} />
      <Route
        path={RoutePaths.contracts.profit_centres[':id'].root}
        component={ViewProfitCentre}
        exact
      />
      <Route
        path={RoutePaths.contracts.profit_centres[':id'].edit}
        component={UpsertProfitCentre}
      />
    </Switch>
  );
};

export default ContractsProfitCentres;
