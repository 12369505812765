import { blue, cyan, green } from '@mui/material/colors';

export const getContractStatusColor = (status?: string, defVal = '') => {
  if (!status) return defVal;
  switch (status) {
    case 'Not Started':
      return cyan[300];
    case 'Completed':
      return green.A400;
    case 'Active':
      return blue[500];

    default:
      return defVal;
  }
};
