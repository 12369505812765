import { ExpandMore, OpenInBrowser } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Stack,
  Tab,
} from '@mui/material';
import { cyan, grey, blue } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { Link } from '@root/components/Link';
import { ProfitCentreNode } from '@root/typings/generated';
import { SyntheticEvent, useState } from 'react';
import { PeopleTable } from './PeopleTable';
import RoutePaths from '@root/pages/routes';
import { routesWithValue } from '@root/utils/route';
const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
    backgroundColor: grey[800],
    '&:before': {
      display: 'none',
    },
  },
  accordinDetail: {
    backgroundColor: grey[900],
    paddingTop: '20px',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

interface HubItemProps {
  hub: ProfitCentreNode;
}

const HubItem = (props: HubItemProps) => {
  const { hub } = props;
  const classes = useStyles();
  const [value, setValue] = useState('people_tab');
  const [expanded, setExpanded] = useState(false);

  const handleChange = (event: SyntheticEvent<Element, Event>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Accordion className={classes.accordion} disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: 'white' }} />}
        onClick={() => setExpanded(true)}
      >
        <Stack
          textAlign="center"
          direction="row"
          spacing={2}
          alignItems="center"
          color="white"
          justifyContent="center"
        >
          <Link
            labelProps={{
              variant: 'h5',
              color: 'white',
            }}
            label={hub.name}
            isExternal
            leftIcon={<OpenInBrowser />}
            route={routesWithValue(':id', hub.id, RoutePaths.contracts.profit_centres[':id']).root}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails className={classes.accordinDetail} sx={{ padding: '0px !important' }}>
        {expanded && (
          <Stack spacing="2">
            <Stack
              textAlign="center"
              direction="row"
              spacing={2}
              alignItems="center"
              color="white"
              justifyContent="center"
            >
              {hub?.manager && (
                <Box>
                  <Link
                    labelProps={{
                      color: 'white',
                    }}
                    isExternal
                    label={hub.manager.name ?? hub.manager.firstName}
                    route={routesWithValue(':id', hub.manager.id, RoutePaths.people[':id']).root}
                  />{' '}
                  <Chip label={hub?.managerRoleName} color="info" size="small" />
                </Box>
              )}
            </Stack>

            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  backgroundColor: cyan[900],
                  color: 'white',
                }}
              >
                <TabList textColor="inherit" onChange={handleChange}>
                  <Tab label="People" value="people_tab" />
                </TabList>
              </Box>
              <TabPanel value="people_tab" sx={{ backgroundColor: blue[50] }}>
                {value == 'people_tab' && <PeopleTable hub={hub} />}
              </TabPanel>
            </TabContext>
          </Stack>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default HubItem;
