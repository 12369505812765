import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import RoutePaths from '@root/pages/routes';
import { ChildType } from '@root/typings/child-type';
import { useAuth } from '../../providers/AuthProvider/modules/auth';
import { useLayoutContext } from '../Context/Context';
import AuthDrawer from './AuthDrawer';

interface AuthLayoutProps {
  children: ChildType;
}

const useStyle = makeStyles(() => ({
  mainWin: {
    width: '100%',
    position: 'relative',
  },
}));

const AuthLayout = (props: AuthLayoutProps) => {
  const { children } = props;
  const { toggleDrawer } = useLayoutContext();
  const { logout } = useAuth();
  const classes = useStyle();

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            <Link href={RoutePaths.home} underline="none" color="white">
              Supman
            </Link>
          </Typography>
          <IconButton color="inherit" onClick={() => logout()}>
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <AuthDrawer />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Box className={classes.mainWin} paddingBottom={3}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default AuthLayout;
