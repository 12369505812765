import { useField as useFormikField, useFormikContext } from 'formik';
import { useContext } from 'react';
import { CustomFormContext } from './CustomFormProvider';

export function useField(name: string, helperTxt: string | React.ReactNode = '') {
  const [field, { touched, error }, { setTouched, setValue, setError }] = useFormikField(name);
  const { isSubmitting } = useFormikContext();
  const { isDisabled } = useContext(CustomFormContext);

  const showError = touched && !!error;
  const helperText = showError ? error : helperTxt;
  return {
    field: {
      ...field,
      error: showError,
      helperText,
    },
    isDisabled,
    setTouched,
    touched,
    setValue,
    setError,
    readonly: isSubmitting || isDisabled,
  };
}
