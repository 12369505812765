import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
} from '@mui/material';
import {
  CustomFormProvider,
  useFormContext,
} from '@root/components/Form/Formik/CustomFormProvider';
import { FormikTextField } from '@root/components/Form/Formik/FormikTextField';
import { ProviderAsyncSelect } from '@root/components/Form/FormikSelect';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import {
  ContractRateCardNode,
  IdentityNode,
  UserNode,
  useUpsertIdentityMutation,
} from '@root/typings/generated';
import { FormikProps, useFormikContext } from 'formik';
import { useState } from 'react';
import { identitiesYup, identityInitValue } from './_type';
interface Props {
  data?: IdentityNode;
}
const UpsertIdentity = ({ data }: Props) => {
  const { setUpdating } = useFormContext();
  const { values } = useFormikContext<UserNode>();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
  };
  const [, upsertFn] = useUpsertIdentityMutation();
  const onSubmit = async ({ id, primaryIdentifier, context }: IdentityNode) => {
    setUpdating(true);
    await upsertFn({
      data: {
        instance: id,
        entity: values.id,
        primaryIdentifier: primaryIdentifier || '',
        context: context?.id,
      },
    });
    setUpdating(false);
    setOpen(false);
  };

  return (
    <>
      {data ? (
        <Button variant="text" onClick={handleOpen} color="primary">
          Edit
        </Button>
      ) : (
        <Button
          variant="contained"
          startIcon={<InsertDriveFileOutlinedIcon />}
          onClick={handleOpen}
        >
          New Provider Identity
        </Button>
      )}

      {open && (
        <CustomFormProvider
          initialValues={{ ...identityInitValue, ...data }}
          validationSchema={identitiesYup}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, resetForm, isSubmitting }: FormikProps<ContractRateCardNode>) => (
            <Dialog open={open} fullWidth maxWidth="md">
              <Box p={2}>
                <DialogTitle>
                  {data ? 'Update Provider Identity' : 'Add Provider Identity'}
                </DialogTitle>
                <DialogContent sx={{ pb: 0 }}>
                  <Divider />
                  <Grid pb={2}>
                    <FormFieldGrid label="Provider:">
                      <ProviderAsyncSelect
                        name="context"
                        size="small"
                        getOptionDisabled={(option) =>
                          values?.identities?.some((v) => v?.context?.id === option.id)
                        }
                      />
                    </FormFieldGrid>
                    <FormFieldGrid label="Identity:">
                      <FormikTextField name="primaryIdentifier" size="small" />
                    </FormFieldGrid>
                  </Grid>
                  <Divider />
                </DialogContent>
                <DialogActions>
                  <Grid item width="100%" textAlign="center" mt={2}>
                    <Stack width="100%" spacing={3} direction="row" justifyContent="flex-end">
                      <Button
                        color="error"
                        variant="contained"
                        disabled={isSubmitting}
                        onClick={() => {
                          resetForm();
                          handleClose();
                        }}
                        startIcon={<DeleteIcon />}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        sx={{ px: 4 }}
                        startIcon={<SaveIcon />}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Save
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </DialogActions>
              </Box>
            </Dialog>
          )}
        </CustomFormProvider>
      )}
    </>
  );
};

export default UpsertIdentity;
