import FormSectionAccordion from '@root/components/Accordion/FormSectionAccordion';
import generateUpsertFormWidget from '@root/components/Form/Widget/upsert-form';
import RoutePaths from '@root/pages/routes';
import {
  RoleNode,
  UpsertRoleDocument,
  UpsertRoleMutation,
  UpsertRoleMutationVariables,
  useGetRoleQuery,
} from '@root/typings/generated';
import BasicDetails from './BasicDetails';
import People from './People';
import Projects from './Projects';
import { initialValues, validationSchema } from './_type';

const UpsertRoleFormWidget = generateUpsertFormWidget<
  RoleNode,
  UpsertRoleMutationVariables,
  UpsertRoleMutation
>({
  name: 'Role',
  redirect: RoutePaths.contracts.roles.list,
  viewUrl: RoutePaths.contracts.roles[':id'],
  initialValues,
  validationSchema,
  mutationDoc: UpsertRoleDocument,
  getDataQuery: useGetRoleQuery,
});

const UpsertRole = () => {
  return (
    <UpsertRoleFormWidget
      mapping={({ name, id }) => ({
        data: {
          instance: id,
          name,
        },
      })}
      basicDetail={<BasicDetails />}
    >
      <FormSectionAccordion title="People">
        <People />
      </FormSectionAccordion>
      <FormSectionAccordion title="Projects">
        <Projects />
      </FormSectionAccordion>
    </UpsertRoleFormWidget>
  );
};

export default UpsertRole;
