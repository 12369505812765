import { ExpandMore } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import DetailLink from '@root/components/Detail/Link';
import DetailText from '@root/components/Detail/Text';
import { Link } from '@root/components/Link';
import { TableHeader } from '@root/components/Table/TableHeader';
import RoutePaths from '@root/pages/routes';
import { simpleAccordionStyles } from '@root/styles/accordion/simple-accordion';
import {
  ComponentNode,
  InternalProjectNode,
  Maybe,
  Node,
  RepositoryNode,
} from '@root/typings/generated';
import { routesWithValue } from '@root/utils/route';
import { FieldArray, useFormikContext } from 'formik';
import _ from 'lodash';

interface Props {
  project: InternalProjectNode;
  index: number;
}

const BillingCodeAccordion = (props: Props) => {
  const { project, index } = props;
  const { submitForm } = useFormikContext();

  const formikName = `projects.${index}.billingCodes`;

  const billingCodes = project.billingCodes;
  const classes = simpleAccordionStyles();
  const components: Array<ComponentNode> = project.product?.components ?? [];
  const getKey = (node: ComponentNode) => node.repository?.id ?? 'None';
  const repos: Array<RepositoryNode> = components.reduce(
    (pre, cur) => _.unionBy([...pre, ...(cur.repository ? [cur.repository] : [])], 'id'),
    [] as Array<RepositoryNode>,
  );
  const componentsByRepo: { [key: string]: Array<ComponentNode> } = components.reduce(
    (pre, cur) => {
      const curKey = getKey(cur);
      const preSm: Array<ComponentNode> = pre?.[curKey] ?? [];
      return {
        ...pre,
        [curKey]: _.uniqBy([...preSm, cur], 'id'),
      };
    },
    {} as { [key: string]: Array<ComponentNode> },
  );
  const hasItem = (codes: Maybe<Maybe<Node>[]> | undefined, code: Node) => {
    if (!codes || !code) return -1;
    return codes.findIndex((e) => e?.id === code.id);
  };

  return (
    <Accordion className={classes.accordion} disableGutters>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <DetailText>
          [{billingCodes?.length ?? 0}] billing codes and [{components.length}] components
        </DetailText>
      </AccordionSummary>
      <AccordionDetails className={classes.accordinDetail}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }} variant="outlined">
          <TableContainer>
            <Table size="small">
              <TableHeader
                columns={[
                  { id: 'Repo/Component', label: 'Repo/Component', sortable: false },
                  ...(billingCodes?.map((code) => ({
                    id: code.id,
                    label: (
                      <Link
                        isExternal
                        label={code.tagLabel ?? ''}
                        route={
                          routesWithValue(':id', code.id, RoutePaths.projects.billing_codes[':id'])
                            .root
                        }
                      />
                    ),
                    sortable: false,
                  })) ?? []),
                ]}
              />
              <TableBody>
                {repos.map((repo) => {
                  let hasChecked: { [key: string]: boolean } = {};
                  const components = componentsByRepo[repo.id].map((component) => (
                    <TableRow key={`${repo.id}-${component.id}`}>
                      <TableCell>
                        <Link
                          labelProps={{ pl: 3 }}
                          label={component.name}
                          route={
                            routesWithValue(
                              ':id',
                              component.id,
                              RoutePaths.products.components[':id'],
                            ).root
                          }
                        />
                      </TableCell>
                      {billingCodes.map((billingCode, billingCodeIdx) => {
                        const componentIdx = hasItem(billingCode.components, component);
                        const checked = componentIdx !== -1;
                        hasChecked = {
                          ...hasChecked,
                          [billingCode.id]: hasChecked[billingCode.id] || checked,
                        };

                        return (
                          <TableCell key={billingCode.id}>
                            <FieldArray
                              name={`${formikName}.${billingCodeIdx}.components`}
                              render={(helpers) => (
                                <>
                                  <Checkbox
                                    checked={checked}
                                    onClick={() => {
                                      checked
                                        ? helpers.remove(componentIdx)
                                        : helpers.push(component);
                                      submitForm();
                                    }}
                                  />
                                </>
                              )}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ));
                  return [
                    <TableRow key={repo.id}>
                      <TableCell>
                        <DetailLink target="_blank" href={repo.url} />
                      </TableCell>
                      {billingCodes.map((billingCode) => (
                        <TableCell key={billingCode.id}>
                          {hasChecked[billingCode.id] && (
                            <DoneIcon color="primary" fontSize="large" />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>,
                    ...components,
                  ];
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

export default BillingCodeAccordion;
