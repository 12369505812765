import FormSectionAccordion from '@root/components/Accordion/FormSectionAccordion';
import generateUpsertFormWidget from '@root/components/Form/Widget/upsert-form';
import { CurrencyCodes } from '@root/constants/currencies';
import RoutePaths from '@root/pages/routes';
import {
  ContractNode,
  UpsertContractDocument,
  UpsertContractMutation,
  UpsertContractMutationVariables,
  useGetContractQuery,
} from '@root/typings/generated';
import { makeDate } from '@root/utils/date';
import { format } from 'date-fns';
import BasicDetails from './BasicDetails';
import ContractProjects from './ContractProjects';
import ContractRateCards from './ContractRateCards';
import { initialValues, validationSchema } from './_type';

const UpsertContractFormWidget = generateUpsertFormWidget<
  ContractNode,
  UpsertContractMutationVariables,
  UpsertContractMutation
>({
  name: 'Contract',
  viewUrl: RoutePaths.contracts.home[':id'],
  redirect: RoutePaths.contracts.home.list,
  initialValues,
  validationSchema,
  mutationDoc: UpsertContractDocument,
  getDataQuery: useGetContractQuery,
});

const UpsertContract = () => {
  return (
    <UpsertContractFormWidget
      mapping={({
        client,
        entity,
        name,
        description,
        accountManager,
        documentUrl,
        budget,
        submitted,
        signed,
        validUntil,
        completed,
        projects,
        id,
      }) => ({
        data: {
          instance: id,
          client: client.id,
          entity: entity.id,
          name,
          description,
          accountManager: accountManager.id,
          documentUrl,
          budget: { amount: budget?.amount, currency: budget?.currency ?? CurrencyCodes.AUD },
          submitted: submitted ? format(makeDate(submitted), 'yyyy-MM-dd') : null,
          signed: signed ? format(makeDate(signed), 'yyyy-MM-dd') : null,
          validUntil: validUntil ? format(makeDate(validUntil), 'yyyy-MM-dd') : null,
          completed: completed ? format(makeDate(completed), 'yyyy-MM-dd') : null,
          projects: projects.map((project) => ({
            instance: project.id,
            billingCodes: project.billingCodes.map((billingCode) => ({
              instance: billingCode.id,
              productObjective: billingCode.productObjective?.id ?? '',
              components: billingCode.components.map(({ id }) => id),
            })),
          })),
        },
      })}
      basicDetail={<BasicDetails />}
    >
      <FormSectionAccordion title="Contract Projects">
        <ContractProjects />
      </FormSectionAccordion>
      <FormSectionAccordion title="Contract Rate Cards">
        <ContractRateCards />
      </FormSectionAccordion>
    </UpsertContractFormWidget>
  );
};

export default UpsertContract;
