import TreeItem from '@mui/lab/TreeItem';
import { Grid } from '@mui/material';
import { Maybe, ProfitCentreLayer, RegionNode } from '@root/typings/generated';
import ActionItem from './ActionItem';
import HubTree from './HubTree';

interface PropsType {
  region?: Maybe<RegionNode>;
}

const RegionTree = (props: PropsType) => {
  const { region } = props;
  return (
    <>
      <TreeItem
        nodeId={region?.id ?? 'region'}
        label={
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item>{region?.name}</Grid>
            <Grid item>
              <ActionItem id={region?.id ?? ''} layer={ProfitCentreLayer.Region} />
            </Grid>
          </Grid>
        }
      >
        {region?.hubs?.map((hub) => (
          <HubTree key={hub?.id} hub={hub} />
        ))}
      </TreeItem>
    </>
  );
};

export default RegionTree;
