import Switch from '@root/components/RouterDom/Switch';
import RoutePaths from '@root/pages/routes';
import { Route } from 'react-router-dom';
import ProjectsList from './List';
import UpsertProject from './Upsert';
import ProjectView from './View';

const ProjectsHome = () => {
  return (
    <Switch>
      <Route path={RoutePaths.projects.home.list} component={ProjectsList} exact />
      <Route path={RoutePaths.projects.home.create} component={UpsertProject} />
      <Route path={RoutePaths.projects.home[':id'].root} component={ProjectView} exact />
      <Route path={RoutePaths.projects.home[':id'].edit} component={UpsertProject} />
    </Switch>
  );
};

export default ProjectsHome;
