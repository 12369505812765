import { Box, Stack } from '@mui/material';
import DetailText from '@root/components/Detail/Text';
import { Link } from '@root/components/Link';
import { InternalProjectNode } from '@root/typings/generated';
import BillingCodeAccordion from './BillingCodeAccordion';
import ProductObjectiveAccordion from './ProductObjective';
import RoutePaths from '@root/pages/routes';
import { routesWithValue } from '@root/utils/route';

export const ContractProject = (props: { project: InternalProjectNode; index: number }) => {
  const { project, index } = props;

  return (
    <Stack padding={2} spacing={2}>
      <DetailText fontWeight="bold">
        <Link
          label={project.name}
          route={{
            id: project.id,
            basePath: RoutePaths.projects.home[':id'].root,
          }}
        />{' '}
        - Product:
        <Link
          label={project.product.name}
          route={routesWithValue(':id', project.product.id, RoutePaths.products.home[':id']).root}
        />
      </DetailText>
      <Box>
        <ProductObjectiveAccordion project={project} index={index} />
      </Box>
      <Box>
        <BillingCodeAccordion project={project} index={index} />
      </Box>
    </Stack>
  );
};
