import { Grid, GridProps, Typography } from '@mui/material';

interface Props extends GridProps {
  label: React.ReactNode | string;
  children: React.ReactNode;
  alignItems?: string;
}
const FormFieldGrid = (props: Props) => {
  const { label, children, alignItems, ...rest } = props;
  return (
    <Grid
      item
      container
      alignItems={alignItems || 'flex-start'}
      spacing={2}
      direction="row"
      mt={0}
      {...rest}
    >
      <Grid item xs={12} sm={4}>
        {typeof label === 'string' ? <Typography>{label}</Typography> : label}
      </Grid>
      <Grid item xs={12} sm={8}>
        {children}
      </Grid>
    </Grid>
  );
};

export default FormFieldGrid;
