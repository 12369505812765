import FormSectionAccordion from '@root/components/Accordion/FormSectionAccordion';
import generateDetailViewWidget from '@root/components/Form/Widget/detail-view';
import BillingCodesAndComponents from '@root/components/View/BillingCodesAndComponents';
import RoutePaths from '@root/pages/routes';
import { InternalProjectNode, useGetProjectQuery } from '@root/typings/generated';
import { BasicDetail } from './BasicDetails';
import { People } from './People';

interface DetailProps {
  data: InternalProjectNode;
}
const Detail: React.FC<DetailProps> = ({ data: project }: DetailProps) => {
  return (
    <>
      <FormSectionAccordion title="Basic Details">
        <BasicDetail project={project} />
      </FormSectionAccordion>
      <FormSectionAccordion title="Billing Codes And Components">
        <BillingCodesAndComponents
          billingCodes={project.billingCodes}
          components={project.product?.components ?? []}
        />
      </FormSectionAccordion>
      <FormSectionAccordion title="People">
        <People data={project} />
      </FormSectionAccordion>
    </>
  );
};

export default generateDetailViewWidget<InternalProjectNode>({
  name: 'Project',
  redirect: RoutePaths.projects.home.list,
  editUrl: RoutePaths.projects.home[':id'],
  getDataQuery: useGetProjectQuery,
  component: Detail,
  paper: false,
});
