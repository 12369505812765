import { CurrencyCodes } from '@root/constants/currencies';
import { BillingCodeNode, ContractNode, ContractRateCardNode } from '@root/typings/generated';
import { NullID, NullInstance } from '@root/yup/initial-value';
import { moneyInputSchema } from '@root/yup/money-input';
import * as Yup from 'yup';

export const contractRateCardInitValue: ContractRateCardNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  rateCard: NullInstance,
  dateFrom: null,
};
export const contractProjectInitValue: BillingCodeNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  productObjective: null,
  inactive: false,
  components: [],
};

export const rateCardCodeYup = Yup.object({
  rateCard: Yup.object().nullable().required('Rate Card is required'),
  dateFrom: Yup.date().nullable().required('From is required'),
});

export const initialValues: ContractNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  client: NullInstance,
  entity: NullInstance,
  name: '',
  description: '',
  accountManager: NullInstance,
  documentUrl: '',
  budget: {
    amount: 0,
    currency: CurrencyCodes.AUD,
  },
  created: null,
  submitted: null,
  signed: null,
  validUntil: null,
  completed: null,
  contractRateCards: [],
  projects: [],
};

export const validationSchema = Yup.object({
  client: Yup.object().nullable().required('Client is required'),
  entity: Yup.object().nullable().required('Entity is required'),
  name: Yup.string().required('Name is required'),
  accountManager: Yup.object().nullable().required('Account manager is required'),
  documentUrl: Yup.string().nullable().url(),
  budget: moneyInputSchema,
  created: Yup.date().nullable(),
  submitted: Yup.date().nullable(),
  signed: Yup.date().nullable(),
  validUntil: Yup.date().nullable(),
  completed: Yup.date().nullable(),
  contractRateCards: Yup.array().of(rateCardCodeYup),
});
