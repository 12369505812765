import FormSectionAccordion from '@root/components/Accordion/FormSectionAccordion';
import generateDetailViewWidget from '@root/components/Form/Widget/detail-view';
import RoutePaths from '@root/pages/routes';
import { ProductNode, useGetProductQuery } from '@root/typings/generated';
import BasicDetails from './BasicDetails';
import ListComponents from './ListComponents';
import ListObjectives from './ListObjectives';

interface DetailProps {
  data: ProductNode;
}

const Detail: React.FC<DetailProps> = ({ data: product }: DetailProps) => {
  return (
    <>
      <FormSectionAccordion title="Basic Details">
        <BasicDetails product={product} />
      </FormSectionAccordion>
      <FormSectionAccordion title="Components">
        <ListComponents components={product.components} />
      </FormSectionAccordion>
      <FormSectionAccordion title="Objectives">
        <ListObjectives objectives={product.productObjectives} />
      </FormSectionAccordion>
    </>
  );
};

export default generateDetailViewWidget<ProductNode>({
  name: 'Product',
  redirect: RoutePaths.products.home.list,
  editUrl: RoutePaths.products.home[':id'],
  getDataQuery: useGetProductQuery,
  component: Detail,
  paper: false,
});
