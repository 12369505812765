import FormikAsyncAutocomplete from '@root/components/Form/Formik/FormikAsyncAutocomplete';
import RoutePaths from '@root/pages/routes';
import { useListProjectsQuery } from '@root/typings/generated';
import { BaseAsyncSelect } from './Base';
import { FormicAsyncSelect } from './_type';

export const ProjectAsyncSelect = (props: FormicAsyncSelect) => {
  const { name, size, orderBy, goToId, hideAddBtn, ...rest } = props;

  return (
    <BaseAsyncSelect
      hideAddBtn={hideAddBtn}
      createUrl={RoutePaths.projects.home.create}
      goToUrl={goToId && `${RoutePaths.projects.home.root}/${goToId}`}
    >
      <FormikAsyncAutocomplete
        queryKey="projects"
        getOptionLabel={(option) => option?.name ?? ''}
        useQuery={useListProjectsQuery}
        {...rest}
        name={name}
        size={size || 'small'}
        orderBy={orderBy || 'name'}
      />
    </BaseAsyncSelect>
  );
};
