import { Table, TableBody, TableCell, TableContainer } from '@mui/material';
import DetailText from '@root/components/Detail/Text';
import { Link } from '@root/components/Link';
import { TableHeader } from '@root/components/Table/TableHeader';
import { TableRow } from '@root/components/Table/TableRow';
import RoutePaths from '@root/pages/routes';
import { RoleNode } from '@root/typings/generated';
import { routesWithValue } from '@root/utils/route';

const Projects = ({ data }: { data: RoleNode }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHeader
          columns={[
            {
              label: 'Project',
              sortable: false,
            },
            {
              label: 'Contract',
              sortable: false,
            },
            {
              label: 'People',
              sortable: false,
            },
          ]}
        />
        <TableBody>
          {data.peopleAssignments.map((peopleAssignment) => [
            <TableRow key={peopleAssignment.id}>
              <TableCell>
                {peopleAssignment.project ? (
                  <Link
                    label={peopleAssignment.project.name}
                    route={{
                      id: peopleAssignment.project.id,
                      basePath: RoutePaths.projects.home[':id'].root,
                    }}
                  />
                ) : (
                  <DetailText>None</DetailText>
                )}
              </TableCell>
              <TableCell>
                {peopleAssignment.project ? (
                  <Link
                    label={peopleAssignment.project.contract.name}
                    route={
                      routesWithValue(
                        ':id',
                        peopleAssignment.project.contract.id,
                        RoutePaths.contracts.home[':id'],
                      ).root
                    }
                  />
                ) : (
                  <DetailText>None</DetailText>
                )}
              </TableCell>
              <TableCell>
                {peopleAssignment.person ? (
                  <Link
                    label={peopleAssignment.person.name ?? peopleAssignment.person.firstName}
                    route={
                      routesWithValue(':id', peopleAssignment.person.id, RoutePaths.people[':id'])
                        .root
                    }
                  />
                ) : (
                  <DetailText>None</DetailText>
                )}
              </TableCell>
            </TableRow>,
          ])}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Projects;
