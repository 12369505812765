import { Route } from 'react-router-dom';
import RoutePaths from '@root/pages/routes';
import BillingCodes from './BillingCodes';
import Switch from '@root/components/RouterDom/Switch';
import ProjectsHome from './Home';
import ChartPage from './Chart';

export const Projects = () => {
  return (
    <Switch>
      <Route path={RoutePaths.projects.home.root} component={ProjectsHome} />
      <Route path={RoutePaths.projects.billing_codes.root} component={BillingCodes} />
      <Route path={RoutePaths.projects.chart.root}>
        <ChartPage />
      </Route>
    </Switch>
  );
};
