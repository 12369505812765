import DetailText from '@root/components/Detail/Text';
import generateDetailViewWidget from '@root/components/Form/Widget/detail-view';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { Link } from '@root/components/Link';
import RoutePaths from '@root/pages/routes';
import { ProfitCentreNode, useGetProfitCentreQuery } from '@root/typings/generated';
import ActionItem from '../List/ActionItem';
import { routesWithValue } from '@root/utils/route';

interface DetailProps {
  data: ProfitCentreNode;
}
const Detail: React.FC<DetailProps> = ({ data }: DetailProps) => {
  return (
    <>
      <FormFieldGrid label="Layer:">
        <ActionItem layer={data.layer} showViewBtn={false} />
      </FormFieldGrid>
      <FormFieldGrid label="Name:">
        <DetailText>{data.name}</DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Manager:">
        <DetailText>
          {data.manager?.name} ({data.managerRoleName})
        </DetailText>
      </FormFieldGrid>
      <FormFieldGrid label="Parent:">
        {data.parent ? (
          <Link
            label={data.parent.name ?? ''}
            route={
              routesWithValue(':id', data.parent.id, RoutePaths.contracts.profit_centres[':id'])
                .root
            }
          />
        ) : (
          <DetailText>None</DetailText>
        )}
      </FormFieldGrid>
    </>
  );
};

export default generateDetailViewWidget<ProfitCentreNode>({
  name: 'Profit Centre',
  redirect: RoutePaths.contracts.profit_centres.list,
  editUrl: RoutePaths.contracts.profit_centres[':id'],
  getDataQuery: useGetProfitCentreQuery,
  component: Detail,
});
