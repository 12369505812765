import { FormikTextField } from '@root/components/Form/Formik/FormikTextField';
import { ProfitCentreAsyncSelect, UserAsyncSelect } from '@root/components/Form/FormikSelect';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { ProfitCentreLayer, ProfitCentreNode } from '@root/typings/generated';
import { useFormikContext } from 'formik';

const Form = () => {
  const { values } = useFormikContext<ProfitCentreNode>();
  return (
    <>
      <FormFieldGrid label="Name:">
        <FormikTextField name="name" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Parent:">
        <ProfitCentreAsyncSelect
          name="parent"
          variables={{ types: [ProfitCentreLayer.Entity, ProfitCentreLayer.Region] }}
        />
      </FormFieldGrid>
      <FormFieldGrid label="Manager:">
        <UserAsyncSelect name="manager" hideAddBtn goToId={values?.manager?.id} />
      </FormFieldGrid>
    </>
  );
};

export default Form;
