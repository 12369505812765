import { CurrencyCodes } from '@root/constants/currencies';
import { BillingCodeNode } from '@root/typings/generated';
import { moneyInputSchema } from '@root/yup/money-input';
import * as Yup from 'yup';
import { NullID } from '../../../../../yup/initial-value';

export const initialValues: BillingCodeNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  internalProject: null,
  productObjective: null,
  inactive: false,
  components: [],
  totalSpent: {
    amount: 0,
    currency: CurrencyCodes.AUD,
  },
};

export const componentInitValue = {
  component: null,
};
export const componentInputYup = Yup.object({
  component: Yup.object().nullable().required('Component is required'),
});
export const validationSchema = Yup.object({
  productObjective: Yup.object().nullable().required('Product Objective is required'),
  internalProject: Yup.object().nullable().required('Project is required'),
  components: Yup.array(),
  totalSpent: moneyInputSchema,
});
