import {
  BillingCodeNode,
  ComponentNode,
  ProductNode,
  ProductObjectiveNode,
} from '@root/typings/generated';
import * as Yup from 'yup';
import { NullID } from '@root/yup/initial-value';

export const productObjectiveInitialValue: ProductObjectiveNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  inactive: false,
  shortName: '',
  longName: '',
};

export const billingCodeInitialValue: BillingCodeNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  inactive: false,
  internalProject: NullID,
  productObjective: productObjectiveInitialValue,
  components: [],
};

export const billingCodeYup = Yup.object({
  internalProject: Yup.object().nullable().required('Project is required'),
  productObjective: Yup.object().nullable().required('Product Objective is required'),
  components: Yup.array(),
});

export type ExtendComponentNode = ComponentNode & {
  product?: ProductNode;
};

export const initialValues: ExtendComponentNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  name: '',
  description: '',
  inactive: false,
  repository: {
    id: NullID,
    realId: NullID,
    compactId: NullID,
    url: '',
  },
};

export const validationSchema = Yup.object({
  name: Yup.string().defined('Name is required'),
  repository: Yup.object({
    url: Yup.string().url('Repository URL is incorrect').defined('Repository URL is required'),
  }),
});
