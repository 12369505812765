import FormikAsyncAutocomplete from '@root/components/Form/Formik/FormikAsyncAutocomplete';
import RoutePaths from '@root/pages/routes';
import { InternalProjectNode, useListProductsQuery } from '@root/typings/generated';
import { BaseAsyncSelect } from './Base';
import { FormicAsyncSelect } from './_type';
import { useFormikContext } from 'formik';

export const ProductAsyncSelect = (props: FormicAsyncSelect) => {
  const { name, size, orderBy, hideAddBtn, ...rest } = props;
  const { values } = useFormikContext<InternalProjectNode>();

  return (
    <BaseAsyncSelect
      hideAddBtn={hideAddBtn}
      createUrl={RoutePaths.products.home.create}
      goToUrl={values?.product?.id && `${RoutePaths.products.home.root}/${values.product.id}`}
    >
      <FormikAsyncAutocomplete
        queryKey="products"
        getOptionLabel={(option) => option?.name ?? ''}
        useQuery={useListProductsQuery}
        {...rest}
        name={name}
        size={size || 'small'}
        orderBy={orderBy || 'name'}
      />
    </BaseAsyncSelect>
  );
};
