import { Stack } from '@mui/material';
import DetailText from '@root/components/Detail/Text';
import { Link } from '@root/components/Link';
import { InternalProjectNode } from '@root/typings/generated';
import RoutePaths from '@root/pages/routes';
import { routesWithValue } from '@root/utils/route';

export const ContractProjectDetail = (props: { project: InternalProjectNode }) => {
  const { project } = props;

  return (
    <Stack padding={2} spacing={2}>
      <DetailText fontWeight="bold">
        <Link
          label={project.name}
          route={{
            id: project.id,
            basePath: RoutePaths.projects.home[':id'].root,
          }}
        />{' '}
        ({project.status}) - Product:{' '}
        <Link
          label={project.product.name}
          route={routesWithValue(':id', project.product.id, RoutePaths.products.home[':id']).root}
        />
      </DetailText>
    </Stack>
  );
};
