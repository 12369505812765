import { Maybe, Money } from '@root/typings/generated';
import DetailText, { DetailTextProps } from './Text';

interface DetailMoneyProps extends DetailTextProps {
  data?: Maybe<Money>;
}
const DetailMoney = (props: DetailMoneyProps) => {
  const { data, ...rest } = props;
  return (
    <DetailText {...rest}>
      {data ? (data?.asString ? data.asString : `${data?.amount} ${data?.currency}`) : '-'}
    </DetailText>
  );
};

export default DetailMoney;
