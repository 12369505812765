import Box from '@mui/material/Box';
import { ChildType } from '@root/typings/child-type';

interface DefaultLayoutProps {
  children: ChildType;
}

const DefaultLayout = (props: DefaultLayoutProps) => {
  const { children } = props;

  return (
    <Box sx={{ display: 'flex' }}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {children}
      </Box>
    </Box>
  );
};

export default DefaultLayout;
