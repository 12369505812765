import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox } from '@mui/material';
import FormikAsyncAutocomplete from '@root/components/Form/Formik/FormikAsyncAutocomplete';
import RoutePaths from '@root/pages/routes';
import { useListComponentsQuery } from '@root/typings/generated';
import { BaseAsyncSelect } from './Base';
import { FormicAsyncSelect } from './_type';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const ComponentAsyncSelect = (props: FormicAsyncSelect) => {
  const { name, size, orderBy, hideAddBtn, multiple, ...rest } = props;

  return (
    <BaseAsyncSelect hideAddBtn={hideAddBtn} createUrl={RoutePaths.products.components.create}>
      <FormikAsyncAutocomplete
        queryKey="components"
        getOptionLabel={(option) => option?.name ?? ''}
        useQuery={useListComponentsQuery}
        multiple={multiple}
        limitTags={1}
        disableCloseOnSelect={multiple ? true : false}
        renderOption={
          multiple
            ? (props, option, { selected }) => (
                <li {...props} key={option.id}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )
            : undefined
        }
        {...rest}
        name={name}
        size={size || 'small'}
        orderBy={orderBy || 'name'}
      />
    </BaseAsyncSelect>
  );
};
