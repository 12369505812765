import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
} from '@mui/material';
import {
  RateCardNode,
  RoleNode,
  useAddRoleToRateCardMutation,
  useRemoveRoleFromRateCardMutation,
} from '@root/typings/generated';
import { useFormikContext } from 'formik';
import { TableHeader } from '@root/components/Table/TableHeader';
import DetailText from '@root/components/Detail/Text';
import { useState } from 'react';
import FormSectionAccordion from '@root/components/Accordion/FormSectionAccordion';
import { RoleAsyncSelect } from '@root/components/Form/FormikSelect';

const RateCardRoleSelection = () => {
  const { values: rateCards, setFieldValue } = useFormikContext<RateCardNode>();

  const [roleToAdd, setRoleToAdd] = useState<RoleNode>();
  const [, addRoleMutation] = useAddRoleToRateCardMutation();
  const [, removeRoleMutation] = useRemoveRoleFromRateCardMutation();

  const handleRemoveRole = async (id: string) => {
    const { error: err, data } = await removeRoleMutation({
      data: { instance: rateCards.id, role: id },
    });
    if (!err) {
      setFieldValue('rateCardRoles', data?.removeRoleFromRateCard?.rateCardRoles);
    }
  };
  const handleAddRole = async () => {
    if (!roleToAdd || rateCards.rateCardRoles.some((role) => role?.role.id === roleToAdd?.id))
      return;
    const { error: err, data } = await addRoleMutation({
      data: { instance: rateCards.id, role: roleToAdd.id },
    });
    if (!err) {
      setFieldValue('rateCardRoles', data?.addRoleToRateCard?.rateCardRoles);
      setRoleToAdd(undefined);
    }
  };
  return (
    <FormSectionAccordion title="Roles">
      <Stack marginY="1rem">
        <RoleAsyncSelect
          value={roleToAdd}
          onChange={(_, role) => setRoleToAdd({ ...role })}
          getOptionDisabled={(option) =>
            rateCards.rateCardRoles.some((role) => role.role.id === option?.id)
          }
          name="Role Select"
        />
      </Stack>
      <Stack marginY="1rem">
        <Button
          aria-label="add"
          color="primary"
          onClick={handleAddRole}
          sx={{ alignSelf: 'flex-end' }}
          variant="contained"
        >
          Add Role <AddCircleOutlineIcon sx={{ marginLeft: '0.3rem' }} />
        </Button>
      </Stack>
      <TableContainer>
        <Table size="small">
          <TableHeader
            columns={[
              { id: 'name', label: 'Name' },
              { id: 'remove', label: 'Remove' },
            ]}
          />
          <TableBody>
            {rateCards.rateCardRoles.map((role, index) => (
              <TableRow key={`${role.id}-${index}`}>
                <TableCell>
                  <DetailText>{role?.role?.name}</DetailText>
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="remove"
                    color="primary"
                    onClick={() => handleRemoveRole(role.role.id)}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormSectionAccordion>
  );
};

export default RateCardRoleSelection;
