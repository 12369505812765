import Switch from '@root/components/RouterDom/Switch';
import RoutePaths from '@root/pages/routes';
import { Route } from 'react-router-dom';
import ListClients from './List';
import UpsertClient from './Upsert';
import ViewClient from './View';

const ContractsClients = () => {
  return (
    <Switch>
      <Route path={RoutePaths.contracts.clients.list} component={ListClients} exact />
      <Route path={RoutePaths.contracts.clients.create} component={UpsertClient} />
      <Route path={RoutePaths.contracts.clients[':id'].root} component={ViewClient} exact />
      <Route path={RoutePaths.contracts.clients[':id'].edit} component={UpsertClient} />
    </Switch>
  );
};

export default ContractsClients;
