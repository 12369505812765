import { Stack, Typography, TypographyProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MUILink } from '@mui/material';

interface LinkProps {
  label: string;
  route: string | { basePath: string; id: string };
  isExternal?: boolean;
  rightIcon?: JSX.Element;
  leftIcon?: JSX.Element;
  labelProps?: TypographyProps;
  _target?: string;
}

/**
 * Reusable link component
 * @param {LinkProps} props
 * @property {string} route display label of the link
 * @property {boolean} [isExternal] is the link to an external site or open in new tab
 * @property {JSX.Element} [rightIcon] icon displayed on the right
 * @property {JSX.Element} [leftIcon] icon displayed to the left
 * @property {TypographyProps} [labelProps] props for the typography or label text
 * @property {string} [_target] link target
 * @returns
 */
export const Link = ({
  label,
  route,
  leftIcon,
  rightIcon,
  labelProps,
  isExternal,
  _target = '_blank',
  ...rest
}: LinkProps) => {
  //This assumes only 1 id per url, can support multiple if inputs can be array and then iterate over replacing each instance 1 by one
  const assembledRoute =
    typeof route === 'string' ? route : route.basePath.replace(':id', route.id);
  const link = isExternal
    ? { href: assembledRoute, _target }
    : { component: RouterLink, to: assembledRoute };

  return (
    <MUILink {...link} {...rest} data-testid={`link-${label}`} onClick={(e) => e.stopPropagation()}>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        {leftIcon}
        {label && (
          <Typography
            variant="body1"
            component={'span'}
            data-testid={`link-${label}-label`}
            {...labelProps}
          >
            {label}
          </Typography>
        )}
        {rightIcon}
      </Stack>
    </MUILink>
  );
};
