import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import DetailDate from '@root/components/Detail/Date';
import DetailMoney from '@root/components/Detail/Money';
import DetailText from '@root/components/Detail/Text';
import { Link } from '@root/components/Link';
import { TableHeader } from '@root/components/Table/TableHeader';
import RoutePaths from '@root/pages/routes';
import { ChildType } from '@root/typings/child-type';
import { ContractRateCardNode } from '@root/typings/generated';
import { routesWithValue } from '@root/utils/route';

export const ContractRateCard = (props: {
  contractRateCard: ContractRateCardNode;
  editComponent?: ChildType;
}) => {
  const { contractRateCard, editComponent } = props;

  return (
    <Stack padding={2} spacing={2}>
      <DetailText fontWeight="bold">
        <Link
          label={contractRateCard.rateCard.title}
          route={
            routesWithValue(
              ':id',
              contractRateCard.rateCard.id,
              RoutePaths.contracts.rate_cards[':id'],
            ).root
          }
        />
        - from <DetailDate date={contractRateCard.dateFrom} />
        {editComponent && editComponent}
      </DetailText>
      <Paper sx={{ width: '100%', overflow: 'hidden' }} variant="outlined">
        <TableContainer>
          <Table size="small">
            <TableHeader
              columns={[
                { id: 'Role', label: 'Role', sortable: false },
                { id: 'Unit', label: 'Unit', sortable: false },
                { id: 'Price', label: 'Price', sortable: false },
              ]}
            />
            <TableBody>
              {contractRateCard.rateCard?.rateCardRoles?.map((rateCardRole) => (
                <TableRow key={rateCardRole.id}>
                  <TableCell>
                    <Link
                      label={rateCardRole.role.name}
                      route={
                        routesWithValue(':id', rateCardRole.role.id, RoutePaths.people[':id']).root
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <DetailText>{rateCardRole.unit}</DetailText>
                  </TableCell>
                  <TableCell>
                    <DetailMoney data={rateCardRole.price} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Stack>
  );
};
