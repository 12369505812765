/* eslint-disable @typescript-eslint/no-explicit-any */
import { InnerLoading } from '@root/components/Loading/InnerLoading';
import { usePath } from '@root/providers/AuthProvider/modules/path';
import { Maybe } from '@root/typings/generated';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import * as Urql from 'urql';
import { OperationContext } from 'urql';
interface ParamTypes {
  id: string;
}
interface UseGetDataType<T> {
  isUpdating: boolean;
  fetchingData: boolean;
  data?: Maybe<T>;
  fetchingDataError?: Urql.CombinedError;
  runGetData?: (opts?: Partial<OperationContext> | undefined) => void;
}

function useGetData<T>(gId?: string, getDataQuery?: any): UseGetDataType<T> {
  if (!gId || !getDataQuery)
    return {
      fetchingData: false,
      isUpdating: false,
    };
  const [result, runGetData] = getDataQuery({
    variables: {
      gId,
    },
    requestPolicy: 'network-only',
    pause: !gId,
  });
  const { fetching, data, error } = result;
  return {
    isUpdating: true,
    fetchingData: fetching,
    data: !error && data ? (Object.values(data)?.[0] as T) : null,
    fetchingDataError: error,
    runGetData,
  };
}

interface Props {
  redirect: string;
  toggle?: boolean;
  getDataQuery?: any;
}

export const LoadingObject = (props: Props) => {
  const { id } = useParams<ParamTypes>();
  const { setValues, values } = useFormikContext<any>();
  const { redirect, getDataQuery, toggle } = props;
  const { fetchingData, isUpdating, data, fetchingDataError, runGetData } = useGetData(
    id,
    getDataQuery,
  );
  useEffect(() => {
    if (!toggle && runGetData) runGetData();
  }, [toggle]);
  const { getContext, redirectPath } = usePath();
  if (!isUpdating) {
    return <></>;
  }

  if (fetchingDataError) redirectPath(redirect);
  useEffect(() => {
    if (!fetchingData) setValues(getContext({ ...values, ...(data as any) }, false));
  }, [fetchingData]);

  return <InnerLoading loading={fetchingData} />;
};
