import { TableRow as MuiTableRow, TableRowProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    '&:last-child td, &:last-child th': { border: 0 },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

interface RowProps extends TableRowProps {
  name?: string;
}

export const TableRow = (props: RowProps) => {
  const { name, children, className, ...rest } = props;
  const classes = useStyles();

  return (
    <MuiTableRow className={clsx(classes.row, className)} aria-label={name} {...rest}>
      {children}
    </MuiTableRow>
  );
};
