import FormikBudgetInput from '@root/components/Form/Formik/FormikBudgetInput';
import { FormikCheckbox } from '@root/components/Form/Formik/FormikCheckBox';
import {
  ProductObjectiveAsyncSelect,
  ProjectAsyncSelect,
} from '@root/components/Form/FormikSelect';
import FormFieldGrid from '@root/components/Grid/FormFieldGrid';
import { BillingCodeNode } from '@root/typings/generated';
import { useFormikContext } from 'formik';

export const BasicDetails = () => {
  const { values } = useFormikContext<BillingCodeNode>();

  return (
    <>
      <FormFieldGrid label="Project:">
        <ProjectAsyncSelect name="internalProject" goToId={values.internalProject?.id} />
      </FormFieldGrid>
      <FormFieldGrid label="Product Objective:">
        <ProductObjectiveAsyncSelect name="productObjective" />
      </FormFieldGrid>
      <FormFieldGrid label="Total Spent:">
        <FormikBudgetInput name="totalSpent" size="small" />
      </FormFieldGrid>
      <FormFieldGrid label="Inactive:">
        <FormikCheckbox name="inactive" />
      </FormFieldGrid>
    </>
  );
};
