import DoneIcon from '@mui/icons-material/Done';
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import {
  BillingCodeNode,
  ComponentNode,
  Maybe,
  Node,
  RepositoryNode,
  useAddComponentToBillingCodeMutation,
  useRemoveComponentFromBillingCodeMutation,
} from '@root/typings/generated';
import { NullID } from '@root/yup/initial-value';
import { FieldArray } from 'formik';
import _ from 'lodash';
import DetailLink from '../Detail/Link';
import { Link } from '../Link';
import { TableHeader } from '../Table/TableHeader';
import { useFormContext } from '../Form/Formik/CustomFormProvider';
import { routesWithValue } from '@root/utils/route';
import RoutePaths from '@root/pages/routes';

export const billingCodeInitialValue: BillingCodeNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  productObjective: null,
  inactive: false,
  components: [],
};

interface Props {
  billingCodes: BillingCodeNode[];
  components: ComponentNode[];
  formikName: string;
}

const BillingCodeComponentsForm = (props: Props) => {
  const { billingCodes, components, formikName } = props;
  const { setUpdating } = useFormContext();
  const [, addComponentToBillingCodeMutation] = useAddComponentToBillingCodeMutation();
  const [, removeComponentFromBillingCode] = useRemoveComponentFromBillingCodeMutation();

  const getKey = (node: ComponentNode) => node.repository?.id ?? 'None';
  const repos: Array<RepositoryNode> = components.reduce(
    (pre, cur) => _.unionBy([...pre, ...(cur.repository ? [cur.repository] : [])], 'id'),
    [] as Array<RepositoryNode>,
  );
  const componentsByRepo: { [key: string]: Array<ComponentNode> } = components.reduce(
    (pre, cur) => {
      const curKey = getKey(cur);
      const preSm: Array<ComponentNode> = pre?.[curKey] ?? [];
      return {
        ...pre,
        [curKey]: _.uniqBy([...preSm, cur], 'id'),
      };
    },
    {} as { [key: string]: Array<ComponentNode> },
  );
  const hasItem = (codes: Maybe<Maybe<Node>[]> | undefined, code: Node) => {
    if (!codes || !code) return -1;
    return codes.findIndex((e) => e?.id === code.id);
  };

  const handleAddComponentToBillingCode = async (
    billingCode: BillingCodeNode,
    component: ComponentNode,
  ) => {
    setUpdating(true);
    await addComponentToBillingCodeMutation({
      instance: billingCode.id,
      component: component.id,
    });
    setUpdating(false);
  };

  const handleRemoveComponentFromBillingCode = async (
    billingCode: BillingCodeNode,
    componentToRemove: ComponentNode,
  ) => {
    setUpdating(true);
    await removeComponentFromBillingCode({
      instance: billingCode.id,
      component: componentToRemove.id,
    });
    setUpdating(false);
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHeader
          columns={[
            { id: 'Repo/Component', label: 'Repo/Component', sortable: false },
            ...(billingCodes?.map((code) => ({
              id: code.id,
              label: (
                <Link
                  isExternal
                  label={code.tagLabel ?? ''}
                  route={
                    routesWithValue(':id', code.id, RoutePaths.projects.billing_codes[':id']).root
                  }
                />
              ),
              sortable: false,
            })) ?? []),
          ]}
        />
        <TableBody>
          {repos.map((repo) => {
            let hasChecked: { [key: string]: boolean } = {};
            const components = componentsByRepo[repo.id].map((component) => (
              <TableRow key={`${repo.id}-${component.id}`}>
                <TableCell>
                  <Link
                    labelProps={{ pl: 3 }}
                    label={component.name}
                    route={
                      routesWithValue(':id', component.id, RoutePaths.products.components[':id'])
                        .root
                    }
                  />
                </TableCell>
                {billingCodes.map((billingCode, billingCodeIdx) => {
                  const componentIdx = hasItem(billingCode?.components, component);
                  const checked = componentIdx !== -1;
                  hasChecked = {
                    ...hasChecked,
                    [billingCode.id]: hasChecked[billingCode.id] || checked,
                  };

                  return (
                    <TableCell key={billingCode.id}>
                      <FieldArray
                        name={`${formikName}.${billingCodeIdx}.components`}
                        render={() => (
                          <>
                            <Checkbox
                              checked={checked}
                              onClick={() => {
                                checked
                                  ? handleRemoveComponentFromBillingCode(billingCode, component)
                                  : handleAddComponentToBillingCode(billingCode, component);
                              }}
                            />
                          </>
                        )}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ));
            return [
              <TableRow key={repo.id}>
                <TableCell>
                  <DetailLink target="_blank" href={repo.url} />
                </TableCell>
                {billingCodes.map((billingCode) => (
                  <TableCell key={billingCode.id}>
                    {hasChecked[billingCode.id] && <DoneIcon color="primary" fontSize="large" />}
                  </TableCell>
                ))}
              </TableRow>,
              ...components,
            ];
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BillingCodeComponentsForm;
