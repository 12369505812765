import { ListItem, ListItemText } from '@mui/material';
import FormikAsyncAutocomplete from '@root/components/Form/Formik/FormikAsyncAutocomplete';
import RoutePaths from '@root/pages/routes';
import { useListRateCardsQuery } from '@root/typings/generated';
import { BaseAsyncSelect } from './Base';
import { FormicAsyncSelect } from './_type';

export const RateCardAsyncSelect = (props: FormicAsyncSelect) => {
  const { name, size, orderBy, hideAddBtn, ...rest } = props;

  return (
    <BaseAsyncSelect hideAddBtn={hideAddBtn} createUrl={RoutePaths.contracts.rate_cards.create}>
      <FormikAsyncAutocomplete
        getOptionLabel={(option) => option?.title || ''}
        renderOption={(props, option) => (
          <ListItem {...props} key={option.id}>
            <ListItemText primary={option?.title || ''} />
          </ListItem>
        )}
        useQuery={useListRateCardsQuery}
        queryKey="rateCards"
        {...rest}
        name={name}
        size={size || 'small'}
        orderBy={orderBy || 'title'}
      />
    </BaseAsyncSelect>
  );
};
