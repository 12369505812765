import {
  ContractNode,
  RateCardNode,
  RateCardRoleNode,
  RateCardRoleUnit,
} from '@root/typings/generated';
import * as Yup from 'yup';
import { EmptyMoney, NullID, NullInstance } from '../../../../../yup/initial-value';
import { moneyInputSchema } from '../../../../../yup/money-input';

export const rateCardRoleInitialValue: RateCardRoleNode = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  role: NullInstance,
  price: EmptyMoney,
  unit: RateCardRoleUnit.Hourly,
};
export type RateCardInputType = RateCardNode & { contract?: ContractNode };
export const initialValues: RateCardInputType = {
  id: NullID,
  realId: NullID,
  compactId: NullID,
  title: '',
  rateCardRoles: [rateCardRoleInitialValue],
};

export const validationSchema = Yup.object({
  title: Yup.string().defined('Name is required'),
  rateCardRoles: Yup.array().of(
    Yup.object({
      price: moneyInputSchema,
      role: Yup.object().nullable().required('Role is required'),
      unit: Yup.string().oneOf(Object.values(RateCardRoleUnit)),
    }),
  ),
});
