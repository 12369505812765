import { createRoutes } from '../utils/route';
const NAMESPACE = 'home';
const RouteJson = {
  login: '/login',
  signup: '/signup',
  home: '',
  contracts: {
    home: {
      list: '',
      create: '/create',
      ':id': {
        edit: '/edit',
      },
    },
    profit_centres: {
      list: '',
      create: '/create',
      ':id': {
        edit: '/edit',
      },
    },
    clients: {
      list: '',
      create: '/create',
      ':id': {
        edit: '/edit',
      },
    },
    rate_cards: {
      list: '',
      create: '/create',
      ':id': {
        edit: '/edit',
      },
    },
    roles: {
      list: '',
      create: '/create',
      ':id': {
        edit: '/edit',
      },
    },
  },
  products: {
    home: {
      list: '',
      create: '/create',
      ':id': {
        edit: '/edit',
      },
    },
    components: {
      list: '',
      create: '/create',
      ':id': {
        edit: '/edit',
      },
    },
  },
  projects: {
    chart: {},
    home: {
      list: '',
      create: '/create',
      ':id': {
        edit: '/edit',
      },
    },
    billing_codes: {
      list: '',
      create: '/create',
      ':id': {
        edit: '/edit',
      },
    },
  },
  people: {
    list: '',
    create: '/create',
    ':id': {
      edit: '/edit',
    },
  },
};

const RoutePaths = createRoutes(RouteJson, NAMESPACE);
export default RoutePaths;
