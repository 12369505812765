import { SyntheticEvent, useState } from 'react';
import { ExpandMore, OpenInBrowser } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Stack,
  Tab,
} from '@mui/material';
import { cyan, grey, blue } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { Link } from '@root/components/Link';
import { getContractStatusColor } from '@root/constants/contract-status-to-color';
import { ContractNode } from '@root/typings/generated';
import { ProjectTable } from './ProjectTable';
import RoutePaths from '@root/pages/routes';
import { routesWithValue } from '@root/utils/route';

const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
    backgroundColor: grey[800],
    '&:before': {
      display: 'none',
    },
  },
  accordionDetail: {
    backgroundColor: grey[900],
    paddingTop: '20px',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

interface ContractItemProps {
  contract: ContractNode;
}

const ContractItem = (props: ContractItemProps) => {
  const { contract } = props;
  const classes = useStyles();
  const [value, setValue] = useState('project_tab');
  const [expanded, setExpanded] = useState(false);

  const tabChange = (event: SyntheticEvent<Element, Event>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Accordion className={classes.accordion} disableGutters defaultExpanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: 'white' }} />}
        onClick={() => setExpanded(!expanded)}
      >
        <Stack>
          <Stack
            textAlign="center"
            direction="row"
            spacing={2}
            alignItems="center"
            color="white"
            justifyContent="center"
          >
            <Link
              label={contract.name}
              leftIcon={<OpenInBrowser />}
              isExternal
              route={routesWithValue(':id', contract.id, RoutePaths.contracts.home[':id']).root}
              labelProps={{
                variant: 'h5',
                color: 'white',
              }}
            />
            <Chip
              label={contract.status}
              color="primary"
              size="small"
              sx={{ backgroundColor: getContractStatusColor(contract.status ?? '') }}
            />
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetail} sx={{ padding: '0px !important' }}>
        <Stack
          textAlign="center"
          direction="row"
          spacing={2}
          alignItems="center"
          color="white"
          justifyContent="center"
        >
          {contract.accountManager && (
            <Box>
              <Link
                labelProps={{
                  color: 'white',
                }}
                isExternal
                label={contract.accountManager.name ?? contract.accountManager.firstName}
                route={
                  routesWithValue(':id', contract.accountManager.id, RoutePaths.people[':id']).root
                }
              />{' '}
              <Chip label="Account Manager" color="info" size="small" />
            </Box>
          )}
        </Stack>
        {expanded && (
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                backgroundColor: cyan[900],
                color: 'white',
              }}
            >
              <TabList textColor="inherit" onChange={tabChange}>
                <Tab label="Projects" value="project_tab" />
              </TabList>
            </Box>
            <TabPanel value="project_tab" sx={{ backgroundColor: blue[50] }}>
              {value == 'project_tab' && <ProjectTable contract={contract} />}
            </TabPanel>
          </TabContext>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ContractItem;
